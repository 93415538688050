import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../../store/zustand/authStore";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />; // Redirige al login si no está autenticado
  }

  return <>{children}</>; // Renderiza el contenido si está autenticado
};

export default ProtectedRoute;
