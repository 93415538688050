import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import CardWorkshopDate from "../../components/CardWorkshopDate/CardWorkshopDate";
import CardWorkshopDetails from "../../components/CardWorkshopDetails/CardWorkshopDetails";
import CardWorkshopInvite from "../../components/CardWorkshopInvite/CardWorkshopInvite";
import { useState, useEffect, useContext } from "react";
import { CartContext } from "../../store/context/CartContext";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import SnackBar from "../../common/SnackBar/SnackBar";
import { SnackbarProvider, useSnackbar } from "notistack";
import ApiService from "../../modules/APIService.interceptors";
import SelectSmall from "../../components/SelectSmall/SelectSmall";
import { CloudUpload, Image } from "@mui/icons-material";
import { apiUrl_workshop } from "../../models/api-urls";
import axios from "axios";
import { APIURL } from "../../models/api-base";
import WorkshopService from "../../services/workshop.service";

const CreateWorkshop = () => {
  // Datos del taller
  const [workshopNum, setWorkshopNum] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [observacion, setObservacion] = useState("");
  const [instructor, setInstructor] = useState("Agostina");
  const [statusWorkshop, setStatusWorkshop] = useState("onHold");
  const [guests, setGuests] = useState([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState("no message");
  const [severity, setSeverity] = useState("info");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [arg, setArg] = useState(0);
  const [usd, setUsd] = useState(0);
  const { setTitleSeccion } = useContext(CartContext);
  const { enqueueSnackbar } = useSnackbar();
  const [thumbnail, setThumbnail] = useState(null);
  const [professionalsIDs, setProfessionalsIDs] = useState([]);
  const [lastWorkshopCreated, setLastWorkshopCreated] = useState();
  const [statusLabel, setStatusLabel] = useState("");
  const [previewImage, setPreviewImage] = useState();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setStatusWorkshop(event.target.value);
    if (event.target.value === "onHold") {
      setStatusLabel("Taller pronto a salir");
    } else if (event.target.value === "inProgress") {
      setStatusLabel("Taller en progreso");
    } else if (event.target.value === "onSale") {
      setStatusLabel("Taller en venta");
    } else if (event.target.value === "finalized") {
      setStatusLabel("Taller finalizado");
    }
  };

  const formatDateString = (dateString) => {
    return dateString.split('-').reverse().join('/');
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    const getAllWorkshops = async () => {
      setLoader(true);
      try {
        const workshopsRequest = await ApiService.getAllWorkshops();
        if (workshopsRequest) {
          setLastWorkshopCreated(
            workshopsRequest.data[workshopsRequest.data.length - 1]
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
      }
    };

    getAllWorkshops();
    setTitleSeccion("Crear un nuevo taller");
  }, {});

  useEffect(() => {
    if (lastWorkshopCreated) {
      if (workshopNum <= lastWorkshopCreated.workshopNumber) {
        setWorkshopNum("");
      }
    }
  }, [open]);

  console.log({ statusWorkshop })

  const workshopStatus = () => {
    let status = null;
    if (statusWorkshop == 'onHold') {
      status = "Proximamente"
    } else if (statusWorkshop == 'onSale') {
      status = "En Venta"
    } else if (statusWorkshop == 'inProgress') {
      status = "En Progreso"
    } else {
      status = 'finalized'
    }
    return status;
  }


  const addWorkshop = async () => {
    try {
      const body = new FormData();
      body.append("workshopNumber", workshopNum);
      body.append("dateStart", startDate);
      body.append("dateEnd", endDate);
      body.append("instructor", instructor);
      body.append("observation", observacion);
      body.append("status", statusWorkshop);
      body.append("priceARS", parseFloat(arg.replace(/,/g, '')));
      body.append("priceUSD", parseFloat(usd.replace(/,/g, '')));
      body.append("image", thumbnail);
      body.append(
        "professionals",
        professionalsIDs.length > 0 ? professionalsIDs : null
      );

      if (!workshopNum) {
        setSeverity("error");
        setMessageModal("El número de taller no es válido, debe ingresar un numero de taller.");
        setOpenModal(true);
        setDisabledBtn(false);
      }

      const newWorkshop = await WorkshopService.createWorkshop(body);
      if (!newWorkshop.error) {
        setOpenModal(true);
        setSeverity("success");
        setMessageModal("El taller fue creado correctamente.");
        setLoader(false);
      }
      navigate("/talleres");

    } catch (error) {
      setOpenModal(true);
      setSeverity("error");
      setMessageModal("El taller no pudo ser creado correctamente.");
      console.log(error);
    } finally {
      setLoader(false)
      setDisabledBtn(false);
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setThumbnail(files[0]);
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackbarProvider maxSnack={3}>
        <SnackBar
          message={messageModal}
          setOpen={setOpenModal}
          open={openModal}
          severity={severity}
        />
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <Typography textAlign={"left"}>Ingresá número de taller</Typography>
            <TextField
              label="Número"
              value={workshopNum}
              onChange={(e) => setWorkshopNum(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              type="number"
              inputProps={{
                min: lastWorkshopCreated
                  ? lastWorkshopCreated.workshopNumber + 1
                  : 0,
              }}
            />
          </DialogContent>
          <DialogActions justifyContent={"space-around"}>
            <Button onClick={handleClose} color="error">
              Cancelar
            </Button>
            <Button onClick={handleClose} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Box sx={{ maxWidth: "100vw", width: "100%" }}>
            <Card sx={{ p: "1rem 2rem", display: "flex", alignItems: "center" }}>
              <Typography variant="h5" color="#767676">
                Proyecto Sirenas N°:
              </Typography>
              {workshopNum === "" ? (
                <Button
                  sx={{
                    m: "0 2%",
                    display: "flex",
                    justifyContent: "center",
                    width: "30%",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                >
                  <Typography variant="body1" color="white">
                    Agregar número
                  </Typography>
                </Button>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h5" color="#767676" marginLeft={1}>
                    {workshopNum}
                  </Typography>
                  <Tooltip title="Editar">
                    <IconButton onClick={handleClick} color="primary">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              <Box style={{ margin: "0 0 0 auto" }}>
                <SelectSmall
                  isEditing={true}
                  status={statusWorkshop}
                  setStatusWorkshop={setStatusWorkshop}
                  setStatusLabel={setStatusLabel}
                  handleChange={handleChange}
                />
              </Box>
            </Card>
            <CardWorkshopDate
              dateStart={startDate}
              dateEnd={endDate}
              isEditing={true}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              lastWorkshopCreated={lastWorkshopCreated}
            />
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: "2%" }}>
              <CardWorkshopDetails
                observacion={observacion}
                instructor={instructor}
                isEditing={true}
                setObservacion={setObservacion}
                setInstructor={setInstructor}
                setArgPrice={setArg}
                setUsdPrice={setUsd}
                argPrice={arg}
                usdPrice={usd}
              />
              {/* <CardWorkshopInvite
                guests={guests}
                setGuests={setGuests}
                create={true}
                setProfessionalsIDs={setProfessionalsIDs}
                professionalsIDs={professionalsIDs}
              /> */}
            </Box>

            <Box
              sx={{
                mt: 1,
                width: "100%",
              }}
            >
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="thumbnail-upload"
                type="file"
                name="thumbnail"
                onChange={handleFileChange}
              />
              <label htmlFor="thumbnail-upload">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<CloudUpload />}
                >
                  Subir Portada
                </Button>
              </label>
              <Box
                mt={2}
                sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}
              >
                <Box>
                  <img
                    src={
                      thumbnail
                        ? previewImage
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROJGo_BDmE1BQXej-UemTXxZG6RkDsA95ZnA&usqp=CAU"
                    }
                    alt="Portada Taller"
                    style={{ maxWidth: "100%", height: "auto", display: "block" }}
                  />
                </Box>
                <Box>
                  <Typography>TALLER NÚMERO: {workshopNum}</Typography>
                  <Typography>
                    Estado: {workshopStatus()}
                  </Typography>
                  <Typography>Fecha de Inicio: {startDate}</Typography>
                  <Typography>Fecha de Culminación: {endDate}</Typography>
                  <Typography>Instructor: {instructor}</Typography>
                  <Typography>
                    Cantidad Invitados: {professionalsIDs.length}
                  </Typography>
                  <Typography>Observación: {observacion}</Typography>
                  <Typography>Precio ARG: {arg}</Typography>
                  <Typography>Precio USD: {usd}</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                position: "fixed",
                right: 40,
                bottom: 40,
              }}
            >
              <Button
                variant="contained"
                disabled={disabledBtn}
                onClick={addWorkshop}
                color="secondary"
                style={{ color: "white", fontSize: 18 }}
              >
                Crear Taller
              </Button>
            </Box>
          </Box>
        </Box>
      </SnackbarProvider>
    </>
  );
};

export default CreateWorkshop;
