import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import BankService from "../../services/bank.service";

const FormularioBancario = () => {
  const [name, setName] = useState("");
  const [bank, setBank] = useState("");
  const [typeCount, setTypeCount] = useState("");
  const [alias, setAlias] = useState("");
  const [cbu, setCbu] = useState("");
  const [cuit, setCuit] = useState("");
  const [id, setId] = useState(null);
  const [typeCountNumber, setTypeCountNumber] = useState(undefined);

  const updateBank = async (form) => {
    try {
      const response = await BankService.updateBank(form, 1);
      if (!response.error) {
        console.log("Datos Actualizados");
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error)
    }
  };



  const getBank = async () => {
    try {
      const { data, error, message } = await BankService.getBank();

      if (!error) {
        setName(data?.name);
        setBank(data?.bank);
        setTypeCount(data?.typeCount);
        setTypeCountNumber(data?.typeCountNumber);
        setAlias(data?.alias);
        setCbu(data?.cbu);
        setCuit(data?.cuit);
        setId(data?.id);
      } else {
        console.error(message);
      }
    } catch (error) {
      console.error(error);
    }

  };
  const handleSubmit = (event) => {
    event.preventDefault();
    updateBank({
      name,
      bank,
      typeCount,
      typeCountNumber,
      alias,
      cbu,
      cuit,
    });
  };

  useEffect(() => {
    getBank();
  }, []);

  return (
    <Box sx={{ width: 500, margin: "50px auto", borderWidth: 5, backgroundColor: "white" }} padding={5} boxShadow={3} borderRadius={8}>
      <Typography variant="h4" color={"grey"} gutterBottom align="center">
        Datos Bancarios
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          id="titular"
          label="Titular de la CTA"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />

        <TextField
          id="entidadBancaria"
          label="Entidad Bancaria"
          variant="outlined"
          fullWidth
          margin="normal"
          value={bank}
          onChange={(event) => setBank(event.target.value)}
        />

        <InputLabel id="demo-simple-select-label">Tipo de cuenta</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={typeCount}
          label="Tipo de cuenta"
          onChange={(event) => setTypeCount(event.target.value)}
          fullWidth
        >
          <MenuItem value={"Savings bank"}>Caja de Ahorro</MenuItem>
          <MenuItem value={"current account"}>Cuenta Corriente</MenuItem>
        </Select>

        <TextField
          id="typeCountNumber"
          label="Numero de cuenta"
          variant="outlined"
          fullWidth
          margin="normal"
          value={typeCountNumber}
          onChange={(event) => setTypeCountNumber(event.target.value)}
        />

        <TextField
          id="alias"
          label="Alias"
          variant="outlined"
          fullWidth
          margin="normal"
          value={alias}
          onChange={(event) => setAlias(event.target.value)}
        />

        <TextField
          id="cbu"
          label="CBU"
          variant="outlined"
          fullWidth
          margin="normal"
          value={cbu}
          onChange={(event) => setCbu(event.target.value)}
        />

        <TextField
          id="cuit"
          label="CUIT"
          variant="outlined"
          fullWidth
          margin="normal"
          value={cuit}
          onChange={(event) => setCuit(event.target.value)}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Enviar
        </Button>
      </form>
    </Box>
  );
};

export default FormularioBancario;
