import React, { useContext, useEffect, useState } from "react";
import CardProfesional from "../../components/ProfesionalCard/CardProfesional";
import { Box, Button, Container, Backdrop, Typography } from "@mui/material";
import ProfesionalForm from "../../components/ProfesionalForm/ProfesionalForm";
import ProfileService from "../../services/profile.service";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuthStore } from "../../store/zustand/authStore";
import NotAllowed from "../NotAllowed/NotAllowed";
import { CartContext } from "../../store/context/CartContext";

const Profesionals = () => {
  const [showForm, setShowForm] = useState(false);
  const [image, setImage] = useState({});
  const [formValues, setFormValues] = useState({
    name: "",
    lastname: "",
    occupation: "",
    typeProfile: "",
    thumbnail: "",
    description: "",
    insta: "",
    image: null,
  });
  const [profesionals, setProfesionals] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [loader, setLoader] = useState(false);
  const user = useAuthStore((state) => state.user);
  const { setTitleSeccion } = useContext(CartContext);
  //////////Obtengo profesionales /////////
  useEffect(() => {
    const getProfiles = async () => {
      try {
        const response = await ProfileService.getProfiles();
        if (!response.error) {
          setProfesionals(response.data);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.log(error)
      }
    }
    getProfiles();
  }, []);


  useEffect(() => {
    setTitleSeccion("Profesionales");
  }, []);

  //////////Crea profesional /////////
  const createdProfile = async (profile) => {
    try {
      setLoader(true);
      const response = await ProfileService.createProfile(profile);
      if (!response.error) {
        formValues.thumbnail = response.thumbnail;
        const newProfesional = { ...formValues, id: profesionals.length + 1 };
        setProfesionals([...profesionals, newProfesional]);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    } finally {
      setLoader(false);
    }
  };

  //////////Actualizo profesional /////////
  const updateProfile = async (profile, editId) => {
    try {
      setLoader(true);
      const response = await ProfileService.updateProfile(profile, editId);
      if (!response.error) {
        setEditMode(false)
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setLoader(false);
    }
  };

  //////////Borro profesional /////////
  const handleDelete = async (id) => {
    try {
      const response = await ProfileService.deleteProfile(id);
      if (!response.error) {
        const updatedProfesionals = profesionals.filter(
          (profesional) => profesional.id !== id
        );
        setProfesionals(updatedProfesionals);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const profile = new FormData();
    profile.append("image", image);
    profile.append("thumbnail", "");
    profile.append("name", formValues.name);
    profile.append("lastname", formValues.lastname);
    profile.append("occupation", formValues.occupation);
    profile.append("description", formValues.description);
    profile.append("insta", formValues.insta);
    if (editMode && editId !== null) {
      updateProfile(profile, editId);
      const updatedRecipes = profesionals?.map((profesional) =>
        profesional.id === editId ? { ...formValues, id: editId } : profesional
      );
      setProfesionals(updatedRecipes);
    } else {
      createdProfile(profile);
    }
    handleCloseForm();
  };

  //Boton
  const handleButtonClick = () => {
    editMode == true ? setShowForm(false) : setShowForm(!showForm);
    setFormValues({
      name: "",
      lastname: "",
      occupation: "",
      typeProfile: "",
      thumbnail: "",
      description: "",
      insta: "",
    });
    setEditMode(false); // Close edit mode if open
    setEditId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleEdit = (id) => {
    const profesionalToEdit = profesionals.find(
      (profesional) => profesional.id === id
    );
    if (profesionalToEdit) {
      setFormValues(profesionalToEdit);
      setEditMode(true);
      setEditId(id);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <>
      <Box sx={{ height: "auto", width: "100%", paddingBottom: "5%" }}>
        <Container>
          {/* Verifica si el usuario tiene permisos */}
          {user?.admin_users?.PROFESSIONALS?.READ[0] ? (
            <>
              {/* Backdrop con CircularProgress */}
              <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
              >
                {loader && <CircularProgress color="inherit" />}
              </Backdrop>

              <Typography variant="h4" color={"grey"} gutterBottom align="center">
                Equipo de profesionales
              </Typography>

              <Box
                sx={{
                  position: "relative",
                  textAlign: "center",
                  right: "2%",
                  bottom: "2%",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleButtonClick}
                  color="primary"
                  style={{ color: "white" }}
                >
                  Agregar Profesional
                </Button>
              </Box>

              <Box sx={{ height: "auto", width: "100%" }}>
                <Container>
                  <Backdrop sx={{ color: "#fff", zIndex: 10000 }} open={showForm}>
                    <ProfesionalForm
                      onClose={handleCloseForm}
                      handleInputChange={handleInputChange}
                      submit={handleSubmit}
                      formValues={formValues}
                      handleFileChange={handleFileChange}
                      image={image}
                    />
                  </Backdrop>

                  <CardProfesional
                    profesionals={profesionals}
                    handleDelete={handleDelete}
                    editId={editId}
                    editMode={editMode}
                    handleSubmit={handleSubmit}
                    handleInputChange={handleInputChange}
                    formValues={formValues}
                    handleEdit={handleEdit}
                    onClose={() => setEditMode(false)}
                    handleFileChange={handleFileChange}
                    permission={user}
                  />
                </Container>
              </Box>
            </>
          ) : (
            // Renderiza NotAllowed si no tiene permisos
            <NotAllowed user={user} page={"PROFESSIONALS"} />
          )}
        </Container>
      </Box>
    </>
  );
}

export default Profesionals;
