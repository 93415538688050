import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Fragment} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

const CardPaymentsUser = ({ userPaymemts }) => {
  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleViewVoucher = (voucherLink) => {
    window.open(voucherLink, "_blank");
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" color={"#767676"}>Pagos</Typography>
        <Divider sx={{ mb: 1 }} />
        {userPaymemts.length == 0 && (<Typography>Sin pagos registrados</Typography>)}
        <List
          sx={{
            width: "100%",
            height: 350,
            overflow: "auto",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)", // Agregar efecto de sombra
            mt: 2,
          }}
        >
          {userPaymemts?.map((payment, index) => (
            <Fragment key={index}>
              <ListItem>
                <ListItemText
                  primary={"Fecha"}
                  secondary={formatDate(payment.createdAt)}
                />
                <ListItemText
                  primary={"Taller Número"}
                  secondary={payment.workshopId}
                />
                <ListItemText
                  primary={payment.paymentMethod}
                  secondary={
                    <>
                      {payment.paymentStatus == "approved" ? (
                        <CheckIcon />
                      ) : (
                        <CancelIcon />
                      )}
                    </>
                  }
                />
                <ListItemText
                  primary={payment.currency}
                  secondary={payment.amountPaid}
                />
                {payment.voucherLink && (
                  <IconButton
                    color="info"
                    aria-label="Ver comprobante"
                    onClick={() => handleViewVoucher(payment.voucherLink)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                )}
              </ListItem>
              <Divider component="li" />
            </Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default CardPaymentsUser;
