import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,

} from "@mui/material";
import CommentsModal from '../../components/CommentsModal/CommentsModal';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import ChatService from '../../services/chat.service';


const LiveComments = () => {
  const [open, setOpen] = useState(false)
  const [searchDate, setSearchDate] = useState('');
  const [filteredComments, setFilteredComments] = useState();
  const [commentsArray, setCommentsArray] = useState([]);
  const [commentsByDate, setcommentsByDate] = useState([])

  useEffect(() => {
    const allComments = async () => {
      try {
        const response = await ChatService.getAllComments();
        if (response) {
          setCommentsArray(response[0])
          setFilteredComments(response[0])
        }
      } catch (error) {
        console.log(error)
      }
    }
    allComments()
  }, [])

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchDate(value);

    if (value) {
      const filtered = commentsArray.filter(comment => {
        const commentDate = formattedDate(comment.createdAt);
        return commentDate.includes(value);
      });
      setFilteredComments(filtered);
    } else {
      setFilteredComments(commentsArray);
    }
  };

  // Suponiendo que comment?.createdAt es el valor de la fecha
  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son base 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


  const rutaDate = async (date) => {
    try {
      const response = await ChatService.getDateComments(date);
      if (response) {
        setcommentsByDate(response)
      }

    } catch (error) {
      console.log(error)
    }
  }



  return (
    <>

      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Buscar por fecha (dd/mm/aaaa)"
          inputProps={{ 'aria-label': 'search google maps' }}
          value={searchDate}
          onChange={handleSearch}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>


      <Grid container spacing={2} marginTop={2}>
        {filteredComments?.map((comment, index) => (
          <>
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                key={index}
                sx={{ cursor: "pointer", borderRadius: 5 }}
                onClick={() => {
                  setOpen(true);
                  // setComments(comment?.message);
                  rutaDate(comment?.createdAt);
                }}            >
                <CardContent>
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <Typography
                      variant="h5"
                      component="div"
                      style={{ fontWeight: "bold", marginBottom: "10px", color: "#767676" }}
                    >
                      Fecha: {formattedDate(comment?.createdAt)}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </>
        ))}
      </Grid>


      <CommentsModal open={open} setOpen={setOpen} comments={commentsByDate} />
    </>
  )
}

export default LiveComments;

