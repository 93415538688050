import { APIURL } from "../models/api-base";
import {
  apiURL_live_start_notification,
  apiUrl_workshop,
} from "../models/api-urls";
import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  upload = async (data) => {
    try {
      const endpoint = apiUrl_workshop + "/audiolive/addlive";
      const response = await this.post(endpoint, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Envio Notificacion push
   * @returns
   */
  sendStartLiveNotification = async () => {
    try {
      const response = await this.post(apiURL_live_start_notification);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

const LiveService = new Service();

export default LiveService;
