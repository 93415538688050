import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../store/context/CartContext";
import SnackBar from "../../common/SnackBar/SnackBar";
import { Box, Button, Card } from "@mui/material";
import { HeadersUsers } from "../../components/HeaderUsers/HeaderUsers";
import Search from "../../common/Search/Search";
import { VoucherTable } from "../../components/UserTable/VoucherTable/VoucherTable";
import UserTable from "../../components/UserTable/UserTable/UserTable";
import { useNavigate } from "react-router-dom";

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const Users = () => {
  const navigate = useNavigate();

  const { setTitleSeccion } = useContext(CartContext);
  const [messageModal, setMessageModal] = useState("");
  const [openModal, setOpenModal] = useState("");
  const [severity, setSeverity] = useState("");
  const [tab, setTab] = useState(0);
  const [searchText, setSearchText] = useState("");

  const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    setTitleSeccion("Sirenas");
  }, {});

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleUpdateKey = () => {
    setUpdateKey((prevKey) => prevKey + 1);
    setTab(1);
  };

  return (
    <>
      <SnackBar
        message={messageModal}
        setOpen={setOpenModal}
        open={openModal}
        severity={severity}
      />
      <Card>
        <HeadersUsers tab={tab} setTab={setTab} />

        <Box p={2} width="70%">
          <Search
            searchText={searchText}
            setSearchText={setSearchText}
            color={"#767676"}
            handleSearchChange={handleSearchChange}
          />
        </Box>
        <TabPanel value={tab} index={0}>
          <UserTable searchText={searchText} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <VoucherTable
            key={updateKey}
            searchText={searchText}
            handleUpdateKey={handleUpdateKey}
          />
        </TabPanel>
      </Card>
    </>
  );
};

export default Users;
