import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Chip,
  OutlinedInput,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import WorkshopService from "../../services/workshop.service";
import ProfileService from "../../services/profile.service";
import { APIURL } from "../../models/api-base";
import { apiUrl_workshop } from "../../models/api-urls";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const LivesCategories = {
  NUTRICION: "Nutrición",
  ESPECIAL_NUTRICION: "Especial de Nutrición",
  DUPLEX_SIRENAS: "Duplex sirena",
  DERMATOLOGIA: "Dermatología",
  MOTIVACIONAL_PAREJAS: "Motivacional Parejas",
  ENTRENAMIENTO: "Entrenamiento",
  ENDOCRINOLOGIA: "Endocrinología",
  NEUROLOGIA: "Nuerología",
  PSICOLOGIA: "Psicología",
  GINECOLOGIA: "Ginecología",
  MEDICINA_FUNCIONAL: "Medicina Funcional",
  CARDIOLOGIA: "Cardiología",
  PSIQUIATRIA: "Psiquiatría",
  MOTIVACIONALES: "Motivacionales",
  COCINA: "Vivos cocina",
};

const Lives = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    title: "",
    thumbnail: null,
    video: null,
    category: "",
    workshop: "",
    date: "",
    profesionals: [],
  });
  const [workshopsList, setWorkshopsList] = useState([]);
  const [profesionals, setProfesionals] = useState([]);

  useEffect(() => {
    const getWorkshops = async () => {
      try {
        const response = await WorkshopService.getAllWorkshops();
        if (!response.error) {
          setWorkshopsList(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getProfesionals = async () => {
      try {
        const response = await ProfileService.getProfiles();
        if (!response.error) {
          setProfesionals(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getWorkshops();
    getProfesionals();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleProfesionalChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      profesionals: value === "none" ? [] : value,
    });
  };

  const uploadLive = async () => {
    try {
      const workshop = workshopsList.find(
        (workshop) => workshop.id === formData.workshop
      );
      const data = new FormData();
      data.append("image", formData.thumbnail);
      data.append("video", formData.video);
      data.append("date", formData.date);
      data.append("workshopId", formData.workshop);
      data.append("workshopNumber", workshop ? workshop.workshopNumber : null);
      data.append("title", formData.title);
      data.append("category", formData.category);
      data.append(
        "profesionals",
        formData.profesionals.length > 0 ? formData.profesionals : null
      );
      console.log(data);
      await axios.post(APIURL + apiUrl_workshop + "/audiolive/addlive", data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    uploadLive();
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={12} sm={8} md={6}>
        <Paper elevation={3} style={{ padding: "3% 3%", borderRadius: 12 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4" component="h1" gutterBottom>
              Nuevo Vivo
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Título"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="thumbnail-upload"
                  type="file"
                  name="thumbnail"
                  onChange={handleFileChange}
                />
                <label htmlFor="thumbnail-upload">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUpload />}
                  >
                    Subir Miniatura
                  </Button>
                </label>
                {formData.thumbnail && (
                  <Typography variant="body2">
                    {formData.thumbnail.name}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="video/*"
                  style={{ display: "none" }}
                  id="video-upload"
                  type="file"
                  name="video"
                  onChange={handleFileChange}
                />
                <label htmlFor="video-upload">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUpload />}
                  >
                    Subir Video
                  </Button>
                </label>
                {formData.video && (
                  <Typography variant="body2">{formData.video.name}</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>Categoría</InputLabel>
                  <Select
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                  >
                    {Object.entries(LivesCategories).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>Profesionales</InputLabel>
                  <Select
                    name="profesionals"
                    multiple
                    value={formData.profesionals}
                    onChange={handleProfesionalChange}
                    input={<OutlinedInput label="Profesionales" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.length === 0 ? (
                          <Typography variant="body2">Ninguno</Typography>
                        ) : (
                          selected.map((id) => (
                            <Chip
                              key={id}
                              label={
                                profesionals.find((p) => p.id === id)?.name ||
                                "Desconocido"
                              }
                            />
                          ))
                        )}
                      </Box>
                    )}
                  >
                    <MenuItem value="none">Ninguno</MenuItem>
                    {profesionals.map((profesional) => (
                      <MenuItem key={profesional.id} value={profesional.id}>
                        {profesional.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Taller</InputLabel>
                  <Select
                    name="workshop"
                    value={formData.workshop}
                    onChange={handleChange}
                  >
                    <MenuItem value="">Ninguno</MenuItem>
                    {workshopsList.map((workshop) => (
                      <MenuItem key={workshop.id} value={workshop.id}>
                        Taller número {workshop.workshopNumber}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Fecha"
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Lives;
