import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../../store/zustand/authStore";

const PublicRoute = ({ children }) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  if (isAuthenticated) {
    return <Navigate to="/" replace />; // Redirige a /home si está autenticado
  }

  return <>{children}</>; // Renderiza la página pública si no está autenticado
};

export default PublicRoute;
