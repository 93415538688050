import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

export default function CuponForm({
  formDataCupon,
  handleChangeCupon,
  handleSubmitCupon,
  handleClose,
}) {
  return (
    <Box
      component="form"
      onSubmit={(e) => {
        handleSubmitCupon(e);
        handleClose();
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <FormLabel sx={{ color: "GrayText", marginBottom: "20px" }}>
        Completá los siguientes datos:
      </FormLabel>

      {/* Fecha de Expiración */}
      <TextField
        label="Fecha de Expiración"
        type="date"
        name="expirationDate"
        value={formDataCupon.expirationDate}
        onChange={handleChangeCupon}
        InputLabelProps={{ shrink: true }}
        required
      />

      {/* Tipo de Descuento */}
      <FormControl required>
        <InputLabel>Tipo de Descuento</InputLabel>
        <Select
          name="discountType"
          label="Tipo de Descuento"
          value={formDataCupon.discountType}
          onChange={handleChangeCupon}
        >
          <MenuItem value="percentage">Porcentaje</MenuItem>
          <MenuItem value="fixed">Fijo</MenuItem>
        </Select>
      </FormControl>

      {/* Valor del Descuento */}
      <TextField
        label="Valor del Descuento"
        type="number"
        name="discountValue"
        value={formDataCupon.discountValue}
        onChange={handleChangeCupon}
        inputProps={{ min: 0 }}
        required
      />

      {/* Código */}
      <TextField
        label="Código"
        type="text"
        name="code"
        value={formDataCupon.code}
        onChange={handleChangeCupon}
        required
      />

      {/* Moneda */}
      <FormControl required>
        <InputLabel>Moneda</InputLabel>
        <Select
          label="Moneda"
          name="currency"
          value={formDataCupon.currency}
          onChange={handleChangeCupon}
        >
          <MenuItem value="ARS">ARS</MenuItem>
          <MenuItem value="USD">USD</MenuItem>
        </Select>
      </FormControl>

      {/* Máximo de Usos */}
      <TextField
        label="Máximo de Usos"
        type="number"
        name="maxUses"
        value={formDataCupon.maxUses}
        onChange={handleChangeCupon}
        inputProps={{ min: 1 }}
        required
      />

      {/* Botón de envío */}
      <Button variant="contained" color="primary" type="submit">
        Crear Cupón
      </Button>
    </Box>
  );
}
