import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";

const ProductForm = ({
  workshops,
  Currency,
  TypeProduct,
  DiscountType,
  formData,
  handleSubmit,
  handleChange,
  handleSwitchChange,
  handleClose,
}) => {
  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e, formData);
        handleClose();
      }}
    >
      {/* Sección del título del formulario */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <FormLabel sx={{ color: "GrayText" }}>
            Completá los siguientes datos:
          </FormLabel>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={formData.membership}
                onChange={handleSwitchChange}
                color="primary"
                name="membership"
              />
            }
            label="Membresía"
            labelPlacement="start"
          />
        </FormControl>
      </Box>

      {/* Switch para la membresía */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {/* Campos adicionales si Membresía está activado */}
        {formData.membership && (
          <>
            {/* Fecha inicial */}
            <TextField
              label="Fecha Inicial"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />

            {/* Fecha de finalización */}
            <TextField
              label="Fecha de Finalización"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />

            {/* Selección de taller */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Taller</InputLabel>
              <Select
                name="workshopId"
                value={formData.workshopId}
                onChange={handleChange}
                label="Taller"
              >
                {workshops.map((workshop) => (
                  <MenuItem key={workshop.id} value={workshop.id}>
                    {workshop.workshopNumber}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </Box>

      {/* Campos del formulario */}
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          label="Nombre"
          name="nombre"
          value={formData.nombre}
          onChange={handleChange}
          fullWidth
          margin="normal"
          variant="outlined"
          type="text"
        />

        <TextField
          label="Descripción"
          name="description"
          value={formData.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
          variant="outlined"
          type="text"
          multiline
          rows={4}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Moneda</InputLabel>
          <Select
            label="Moneda"
            name="currency"
            value={formData.currency}
            onChange={handleChange}
          >
            {Currency.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Precio"
          name="price"
          value={formData.price}
          onChange={handleChange}
          fullWidth
          margin="normal"
          variant="outlined"
          type="number"
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Tipo</InputLabel>
          <Select
            label="Tipo"
            name="type"
            value={formData.type}
            onChange={handleChange}
          >
            {TypeProduct.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <FormControl fullWidth margin="normal">
            <InputLabel>Tipo de Descuento</InputLabel>
            <Select
              name="discountType"
              label="Tipo de Descuento"
              value={formData.discountType}
              onChange={handleChange}
            >
              {DiscountType.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Valor del Descuento"
            name="discountValue"
            value={formData.discountValue}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            type="number"
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: "25px" }}
        >
          Crear Producto
        </Button>
      </FormControl>
    </form>
  );
};

export default ProductForm;
