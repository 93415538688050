import { API_URL_SOCKET } from "../models/api-base";
import {
  apiUrl_channel,
  apiUrl_channel_history,
  apiUrl_channel_moderator,
} from "../models/api-urls";
import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(API_URL_SOCKET);
  }

  getChannels = async () => {
    try {
      console.log("get Channels");

      const response = await this.get(apiUrl_channel);
      console.log("get Channels response --->", response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  getModerators = async (uid) => {
    try {
      console.log("get Moderators");

      const response = await this.get(`${apiUrl_channel_moderator}/${uid}`);
      console.log("get Moderators response --->", response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  getChatHistory = async (channelName) => {
    try {
      console.log("get chat history", channelName);
      const response = await this.get(
        `${apiUrl_channel_history}/${channelName}/0`
      );
      const sortedData = response
        .map((item) => JSON.parse(item)) // Convertimos cada string en un objeto
        .sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      return sortedData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

const ChannelService = new Service();

export default ChannelService;
