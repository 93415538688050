import axios from "axios";
import { TOKENSTORAGE } from "../models/constants";

class AxiosService {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL: baseURL,
    });

    this.client.interceptors.request.use((config) => {
      const storedData = localStorage.getItem(TOKENSTORAGE);
      if (storedData) {
        const data = JSON.parse(storedData);
        const token = data?.state?.token; // Usar optional chaining por seguridad
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        console.warn(
          `No token found in localStorage under key ${TOKENSTORAGE}`
        );
      }

      return config;
    });
  }

  async get(endpoint) {
    try {
      const response = await this.client.get(endpoint);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching data from ${endpoint}: ${error.message}`);
    }
  }

  async post(endpoint, body) {
    try {
      const response = await this.client.post(endpoint, body);
      return response.data;
    } catch (error) {
      console.error("axios service", error);
      throw new Error(`Error posting data to ${endpoint}: ${error.message}`);
    }
  }

  async postFile(endpoint, body) {
    try {
      const token = localStorage.getItem(TOKENSTORAGE);
      // const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const response = await this.client.post(endpoint, body);
      return response.data;
    } catch (error) {
      throw new Error(`Error posting file to ${endpoint}: ${error.message}`);
    }
  }

  async put(endpoint, data) {
    try {
      const response = await this.client.put(endpoint, data);
      return response.data;
    } catch (error) {
      throw new Error(`Error putting data to ${endpoint}: ${error.message}`);
    }
  }

  async delete(endpoint, data) {
    try {
      const response = await this.client.delete(endpoint, { data });
      return response.data;
    } catch (error) {
      throw new Error(`Error deleting data from ${endpoint}: ${error.message}`);
    }
  }
}

// const apiService = new AxiosService(APIURL);

export default AxiosService;
