import React from "react";
import StarIcon from "@mui/icons-material/Star";

const StarBackground = ({ count, color }) => {
  const positions = [
    { top: "50%", left: "50%" },
    { top: "35%", left: "20%" },
    { top: "5%", left: "70%" },
    { top: "62%", left: "5%" },
  ];

  return (
    <>
      {[...Array(count)].map((_, i) => (
        <StarIcon
          key={i}
          sx={{
            position: "absolute",
            ...positions[i],
            fontSize: 100,
            color: color,
            opacity: 0.2,
          }}
        />
      ))}
    </>
  );
};

export default StarBackground;
