import {
  Box,
  Button,
  FormControl,
  Grid,
  Skeleton,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import { useState } from "react";
import PaymentsService from "../../services/payment.service";

function MercadoPagoApi() {
  const [loader, setLoader] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [result, setResult] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    try {
      setLoader(true);
      const res = await PaymentsService.getExternalInfoMpPayment(inputValue);
      console.log("Respuesta recibida:", res);

      // Nos aseguramos de que tiene la estructura esperada
      if (res?.mpRes && res?.storedPayment) {
        setResult(res);
      } else {
        setResult(null);
      }
    } catch (err) {
      console.error("Error al consultar el pago:", err);
      setResult(null);
    } finally {
      setLoader(false);
    }
  };

  return (
<Box
  sx={{
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",              // antes: 90%
    minWidth: 400,             // opcional, para que no se vea muy angosto
    maxHeight: "80vh",         // antes: 90vh
    overflow: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  }}
>

      <FormControl component="form" onSubmit={handleSubmit} fullWidth>
        <Box display="flex" alignItems="center" mb={3}>
          <TextField
            fullWidth
            label="ID de pago"
            variant="outlined"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <Button type="submit" variant="contained" style={{ marginLeft: 16 }}>
            Buscar
          </Button>
        </Box>
      </FormControl>

      {loader ? (
        <Skeleton variant="rectangular" width="100%" height={300} />
      ) : result ? (
        <>
          <Typography variant="h6" gutterBottom>
            🧾 Información de Mercado Pago
          </Typography>
          <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography><strong>ID:</strong> {result?.mpRes?.id}</Typography>
                <Typography><strong>Estado:</strong> {result?.mpRes?.status}</Typography>
                <Typography><strong>Detalle:</strong> {result?.mpRes?.status_detail}</Typography>
                <Typography><strong>Monto:</strong> ${result?.mpRes?.transaction_amount}</Typography>
                <Typography><strong>Método de pago:</strong> {result?.mpRes?.payment_method_id}</Typography>
                <Typography><strong>Email pagador:</strong> {result?.mpRes?.payer?.email}</Typography>
                <Typography><strong>Fecha:</strong> {new Date(result?.mpRes?.date_created).toLocaleString()}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {result?.mpRes?.additional_info?.items?.[0]?.picture_url && (
                  <img
                    src={result.mpRes.additional_info.items[0].picture_url}
                    alt="Imagen del producto"
                    style={{ width: "100%", borderRadius: 8 }}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>

          <Typography variant="h6" gutterBottom>
            📦 Información guardada localmente
          </Typography>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography><strong>Producto:</strong> {result?.storedPayment?.data?.payment?.orders?.orderProduct?.product?.name}</Typography>
            <Typography><strong>Descripción:</strong> {result?.storedPayment?.data?.payment?.orders?.orderProduct?.product?.description}</Typography>
            <Typography><strong>Precio:</strong> ${result?.storedPayment?.data?.payment?.orders?.orderProduct?.product?.price}</Typography>
            <Typography><strong>Estado:</strong> {result?.storedPayment?.data?.payment?.status}</Typography>
            <Typography><strong>Fecha:</strong> {new Date(result?.storedPayment?.data?.payment?.createdAt).toLocaleString()}</Typography>
          </Paper>
        </>
      ) : (
        <Typography variant="body2" color="text.secondary">
          Ingresá un ID de pago para obtener la información.
        </Typography>
      )}
    </Box>
  );
}

export { MercadoPagoApi };
