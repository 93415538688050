import { APIURL } from "../models/api-base";
import { apiUrl_product, apiUrl_profile } from "../models/api-urls";
import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  getPack = async () => {
    try {
      const response = await this.get(`${apiUrl_product}/packs`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  createPack = async (item) => {
    try {
      const response = await this.post(
        `${apiUrl_product}/createDefaultProducts`,
        item
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  updatePack = async (item) => {
    try {
      const response = await this.put(
        `${apiUrl_product}/update/defaultProducts`,
        item
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  toggleEnableProduct = async (idOne, idTwo, disabled) => {
    try {
      const response = await this.put(
        `${apiUrl_product}/disabled/${idOne}/${idTwo}`,
        disabled
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}

const ProductService = new Service();

export default ProductService;
