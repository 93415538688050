import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useContext, useState } from "react";
import { CartContext } from "../../store/context/CartContext";
import {
  Avatar,
  Box,
  Menu,
  MenuItem,
  Tooltip,
  Modal,
  Button,
} from "@mui/material";
import styled from "@emotion/styled";
import { useAuthStore } from "../../store/zustand/authStore";
import { useLogin } from "../../hooks/useLogin";
import EditProfileModal from "../../components/EditProfileModal/EditProfileModal";
import AdminService from "../../services/admin.service";

const drawerWidth = 240;

const settings = ["Editar perfil", "Cerrar sesión"];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const NavBar = React.memo(({ open, toggleDrawer, isMobile }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const user = useAuthStore((state) => state.user);
  const { handleLogout } = useLogin();
  const [openModal, setOpenModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setErrorModal(false);
  };

  const onSumbitEditProfile = async (updatedUser) => {
    // Verifica que todos los campos estén completos, excepto notification_token
    const isValid = Object.keys(updatedUser).every((key) => {
      if (key !== "notification_token") {
        return (
          updatedUser[key] !== "" &&
          updatedUser[key] !== null &&
          updatedUser[key] !== undefined
        );
      }
      return true; // No validamos notification_token
    });

    if (!isValid) {
      console.log("Por favor, complete todos los campos.");
      setErrorModal(true);
      return; // No ejecuta la función si hay algún campo vacío
    }

    try {
      await AdminService.updateUser(user.id, updatedUser);
    } catch (error) {
      console.log("ERROR", error);
    } finally {
      onCloseModal();
      setErrorModal(false);
    }
  };

  const handleCloseUserMenu = (event, setting) => {
    setAnchorElUser(null);
    switch (setting) {
      case "Cerrar sesión":
        // closeSesion();
        handleLogout();
        break;
      case "Editar perfil":
        setOpenModal(true);
        break;
      default:
        break;
    }
  };

  const { titleSeccion } = useContext(CartContext);
  return (
    <AppBar position="absolute" open={open} sx={{ position: "fixed" }}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        {!isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          {titleSeccion}
        </Typography>

        <Typography>Hola {user?.name}!</Typography>

        <Box sx={{ flexGrow: 0, marginLeft: "2%" }}>
          <Tooltip title="Abrir opciones">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt={user?.name || ""}
                src={user?.avatar || ""}
                sx={{
                  bgcolor: "primary.main",
                  color: "white",
                  fontWeight: "bold",
                  border: "1px solid white", // Borde blanco
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                }}
              >
                {!user?.avatar && user?.name
                  ? user.name.charAt(0).toUpperCase()
                  : ""}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px", backgroundColor: "rgba(118, 118, 118, 0.1)" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings?.map((setting) => (
              <MenuItem
                key={setting}
                onClick={(event) => {
                  handleCloseUserMenu(event, setting);
                }}
                sx={{
                  backgroundColor: "rgba(118, 118, 118, 0.1)",
                  margin: "8px 0",
                }}
              >
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      <EditProfileModal
        openModal={openModal}
        onCloseModal={onCloseModal}
        onSumbit={onSumbitEditProfile}
        errorModal={errorModal}
      />
    </AppBar>
  );
});

export default NavBar;
