import { CartProvider } from "./store/context/CartContext";
import Dashboard from "./layouts/Dashboard/Dashboard";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as Livepeer from "@livepeer/react"
import { socket } from './socket';
import { useEffect, useState } from "react";

const App = () => {
  const defaultTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#D0749B",
      },
      secondary: {
        main: "#F69195",
      },
    },
    typography: {
      fontFamily: '"Inter", Helvetica, sans-serif !important',
    },
  });
  // const [isConnected, setIsConnected] = useState(socket.connected);
  // const [fooEvents, setFooEvents] = useState([]);
  // useEffect(() => {
  //   function onConnect() {
  //     setIsConnected(true);
  //   }

  //   function onDisconnect() {
  //     setIsConnected(false);
  //   }

  //   function onFooEvent(value) {
  //     setFooEvents(previous => [...previous, value]);
  //   }

  //   socket.on('connect', onConnect);
  //   socket.on('disconnect', onDisconnect);
  //   socket.on('foo', onFooEvent);

  //   return () => {
  //     socket.off('connect', onConnect);
  //     socket.off('disconnect', onDisconnect);
  //     socket.off('foo', onFooEvent);
  //   }; 
  // }, []);


  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        {/* <Livepeer.LivepeerConfig client={client}> */}
        {/* <ConnectionState isConnected={ isConnected } /> */}
        <CartProvider>
          <Dashboard />
        </CartProvider>
        {/* </Livepeer.LivepeerConfig> */}
      </ThemeProvider>

    </>
  );
};

export default App;
