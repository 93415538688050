import { useState, useEffect } from "react";
import ApiService from "../modules/APIService.interceptors";

const useProducts = () => {
  const [productButtonSelected, setProductButtonSelected] = useState(true);
  const [cuponButtonSelected, setCuponButtonSelected] = useState(false);
  const [formDataProduct, setFormDataProduct] = useState({
    membership: false,
    nombre: "",
    description: "",
    currency: "",
    price: "",
    type: "",
    discountType: "",
    discountValue: "",
    startDate: "", // Fecha inicial
    endDate: "", // Fecha final
    workshopId: "", // Taller seleccionado
  });
  const [workshops, setWorkshops] = useState([]); // Datos de la API
  const [products, setProducts] = useState([]);
  const [cupons, setCupons] = useState([]);

  const [reload, setReload] = useState(false); // Estado para controlar la recarga
  const [loading, setLoading] = useState(false);

  const [formDataCupon, setFormDataCupon] = useState({
    expirationDate: "",
    discountType: "",
    discountValue: "",
    code: "",
    currency: "",
    maxUses: "",
  });

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");

  const showToast = (message, severity) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
  };

  const handleChangeCupon = (e) => {
    const { name, value } = e.target;

    setFormDataCupon((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitCupon = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await ApiService.createCupon(formDataCupon);
      console.log("Respuesta del servidor:", response);
      showToast("Cupón creado exitosamente", "success");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      showToast("Error al crear el cupón", "error");
    } finally {
      setFormDataCupon({
        expirationDate: "",
        discountType: "",
        discountValue: "",
        code: "",
        currency: "",
        maxUses: "",
      });
      reloadData();
      setLoading(false);
    }
  };

  // Manejar cambios en el formulario
  const handleChangeProduct = (e) => {
    const { name, value } = e.target;
    setFormDataProduct((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormDataProduct((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  // Formatear datos del producto
  const formatDataProduct = () => {
    const {
      nombre,
      description,
      currency,
      price,
      type,
      discountType,
      discountValue,
      startDate,
      endDate,
      workshopId,
      membership,
    } = formDataProduct;

    return {
      product: {
        name: nombre || null,
        description: description || null,
        currency: currency || null,
        price: price || null,
        type: type || null,
        discountType: discountType || null,
        discountValue: discountValue || null,
      },
      membership: membership
        ? {
            start: startDate || null,
            end: endDate || null,
          }
        : null,
      workshop: workshopId ? { id: workshopId } : null,
    };
  };

  // Enviar datos del producto
  const handleSubmitProduct = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formattedData = formatDataProduct();
    console.log("Datos formateados:", formattedData);

    try {
      const response = await ApiService.createProduct(formattedData);
      console.log("Respuesta del servidor:", response);
      showToast("Producto creado exitosamente", "success");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      showToast("Error al crear el producto", "error");
    } finally {
      setFormDataProduct({
        membership: false,
        nombre: "",
        description: "",
        currency: "",
        price: "",
        type: "",
        discountType: "",
        discountValue: "",
        startDate: "", // Fecha inicial
        endDate: "", // Fecha final
        workshopId: "", // Taller seleccionado
      });
      reloadData();
      setLoading(false);
    }
  };

  // Función para recargar los datos
  const reloadData = () => {
    setReload((prev) => !prev); // Cambia el valor de `reload`
  };

  // Cargar talleres desde la API
  useEffect(() => {
    const fetchWorkshops = async () => {
      setLoading(true);
      try {
        const workshopsRequest = await ApiService.getAllWorkshops();
        if (workshopsRequest) {
          const sortedWorkshops = workshopsRequest.data.sort(
            (a, b) => a.workshopNumber - b.workshopNumber
          );
          setWorkshops(sortedWorkshops);
        } else {
          console.error("Error al obtener los talleres");
        }
      } catch (error) {
        console.error("Error en la solicitud de talleres:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkshops();
  }, []);

  // Cargar productos desde la API
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await ApiService.getProducts();
        setProducts(response);
      } catch (error) {
        console.error("Error al obtener productos:", error);
      }
      setLoading(false);
    };
    const fetchCupons = async () => {
      setLoading(true);
      try {
        const response = await ApiService.getCupons();
        setCupons(response);
      } catch (error) {
        console.error("Error al obtener cupones:", error);
      }
      setLoading(false);
    };
    fetchProducts();
    fetchCupons();
  }, [reload]);

  return {
    productButtonSelected,
    setProductButtonSelected,
    cuponButtonSelected,
    setCuponButtonSelected,
    formDataProduct,
    handleChangeProduct,
    handleSwitchChange,
    handleSubmitProduct,
    workshops,
    products,
    formDataCupon,
    handleChangeCupon,
    handleSubmitCupon,
    cupons,
    loading,
    toastOpen,
    toastMessage,
    toastSeverity,
    showToast,
    setToastOpen,
    reloadData,
  };
};

export default useProducts;
