import { APIURL as apiURL } from "./api-base";

//const apiVersion = '/v1';

const apiUrl_payment = "/api/payments";

export const apiUrl_workshop = "/api/workshops";
export const apiUrl_workshop_lives = "/api/workshops/lives";
export const apiUrl_workshop_users = "/api/workshops/users/";
// export const apiUrl_workshop_update = '/api/workshops/edit_workshop';
export const apiUrl_user = "/api/users/all";
export const apiUrl_user_singup = "/api/users/singup";
export const apiUrl_user_signin = "/api/users/signin";
export const apiUrl_user_id = "/api/users/getbyid";
export const apiUrl_user_update = "/api/users/edit_user";
export const apiUrl_user_update_status = "/api/users/setAdmin";

// export const apiUrl_user_validate = "/api/users/validate"

export const apiUrl_payment_confirm_tranfer =
  apiUrl_payment + "/transfer/confirm/";
export const all_payments = apiUrl_payment + "/";
export const apiUrl_payment_cancel_tranfer =
  apiUrl_payment + "/transfer/cancel/";
export const apiUrl_payment_tranfer = apiUrl_payment + "/transfer/";
export const apiUrl_payment_workshop = apiUrl_payment + "/byworkshop/";
export const apiUrl_payment_user = apiUrl_payment + "/byuser/";

// Calendar
export const apiUrl_calendar = "/api/calendar";

// Recipes
export const apiUrl_recipes = "/api/recipes";

// Bank
export const apiUrl_bank = "/api/bank";

// Profile
export const apiUrl_profile = "/api/profile";

//Lives
export const apiUrl_lives = "/api/lives";
export const apiUrl_update_thumbnail_live = "/api/lives/thumbnail";

//Live
export const apiURL_live_start_notification = "/api/lives/liveStart";

export const apiUrl_user_validate = "/api/users/validate";

export const apiUrl_handleUserPremium = "/api/users/handleUserPremium";
