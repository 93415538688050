import { create } from "zustand";
import { persist } from "zustand/middleware";
import { TOKENSTORAGE } from "../../models/constants";

export const useAuthStore = create(
  persist(
    (set) => ({
      isAuthenticated: false,
      user: null,
      token: null,
      login: (user, token) =>
        set({
          isAuthenticated: true,
          user,
          token,
        }),
      logout: () =>
        set({
          isAuthenticated: false,
          user: null,
          token: null,
        }),
    }),
    {
      name: TOKENSTORAGE,
      partialize: (state) => ({
        isAuthenticated: true,
        user: state.user,
        token: state.token,
      }),
    }
  )
);
