import { Avatar, Card, CardContent, Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CardProfileImage = ({ user }) => {
  const { name, lastname, avatar, premium, idDuo, amountWorkshops, duousername, duouserlastname, duouserid } = user;
  const sizeAvatar = { width: 150, height: 150 };
  const navigate = useNavigate();

  const goToProfile = async (id) => {
    const rute = "/usuarios/"+id;
    navigate(rute);
  };

  return (
    <Card sx={{ minWidth: 275, borderRadius: 8 }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {avatar ? (
          <Avatar
            alt={name}
            src={avatar}
            sx={{
              width: sizeAvatar.width,
              height: sizeAvatar.height,
            }}
          />
        ) : (
          <Skeleton
            animation="wave"
            variant="circular"
            width={sizeAvatar.width}
            height={sizeAvatar.height}
          />
        )}
        {name ? (
          <Typography variant="h5" component="div" mt={3} color="text.secondary" textAlign={"center"}>
            {name} {lastname}
          </Typography>
        ) : (
          <Skeleton
            animation="wave"
            variant="text"
            sx={{ fontSize: "1.4rem", width: "10rem" }}
          />
        )}
        {premium ? (
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Sirena Premium
          </Typography>
        ) : (
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Cuenta Gratuita
          </Typography>
        )}
        {idDuo ? <><Typography color="green">Dulpa activa con:</Typography> <Typography sx={{
          mb: 1.5,
          cursor: 'pointer',
          textDecoration: 'underline'
        }} color="primary" onClick={() => goToProfile(duouserid)}
        >{duousername} {duouserlastname}</Typography></> : <Typography color="grey">Sin Dulpa</Typography>}
        <Typography color="text.secondary">{amountWorkshops} Talleres</Typography>

      </CardContent>
    </Card>
  );
};

export default CardProfileImage;
