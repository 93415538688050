import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { CalendarService } from "../../services/index.service";
import Alert from "@mui/material/Alert";

export const NewEventModal = ({
  open,
  handleClose,
  handleCreate,
  selectedSlot,
  permisos,
}) => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [quest, setQuest] = useState(false);
  const [startTime, setStartTime] = useState(
    moment(selectedSlot?.start || new Date())
  );
  const [endTime, setEndTime] = useState(
    moment(selectedSlot?.start || new Date()).add(1, "hour")
  );
  const [error, setError] = useState("");

  useEffect(() => {
    if (startTime.isSameOrAfter(endTime)) {
      setEndTime(moment(startTime).add(1, "hour"));
    }
  }, [startTime]);

  const handleTitleChange = (event) => setTitle(event.target.value);
  const handlesubTitleChange = (event) => setSubtitle(event.target.value);
  const handleChangeQuest = (event) => setQuest(!quest);

  const handleStartTimeChange = (time) => {
    const updatedStartTime = moment(startTime).set({
      hour: time.hour(),
      minute: time.minute(),
      second: 0,
      millisecond: 0,
    });
    setStartTime(updatedStartTime);
  };

  const handleEndTimeChange = (time) => {
    const updatedEndTime = moment(endTime).set({
      hour: time.hour(),
      minute: time.minute(),
      second: 0,
      millisecond: 0,
    });
    if (updatedEndTime.isSameOrAfter(startTime)) {
      setEndTime(updatedEndTime);
      setError("");
    } else {
      setError(
        "La hora de finalización debe ser posterior a la hora de inicio."
      );
    }
  };

  //////Crear un nuevo evento/////
  const handleCreateEvent = async () => {
    try {
      if (!title) {
        console.log("Debe agregar un título");
        return;
      }
      const data = {
        title,
        subtitle,
        quest,
        allDay: quest,
        start: quest ? startTime.startOf("day").toDate() : startTime.toDate(),
        end: quest ? startTime.endOf("day").toDate() : endTime.toDate(),
      };

      const response = await CalendarService.createCalendarEvent(data);
      if (!response.error) {
        handleCreate({
          title: data.title,
          start: data.start,
          end: data.end,
          subtitle: data.subtitle,
          quest: data.quest,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      justifyContent={"center"}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: 400,
          backgroundColor: "white",
          borderRadius: 2,
          p: 3,
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" component="h2" mb={2}>
          Nuevo Evento
        </Typography>
        <TextField
          label="Título"
          value={title}
          onChange={handleTitleChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Subtitulo"
          value={subtitle}
          onChange={handlesubTitleChange}
          margin="normal"
          fullWidth
        />

        {!permisos?.admin_users?.QUEST?.WRITE[0] && (
          <FormControlLabel
            control={
              <Checkbox
                checked={quest}
                onChange={handleChangeQuest}
                color="primary"
                style={{ padding: 15 }}
              />
            }
            label="Consigna Diaria"
          />
        )}

        {!quest && permisos?.admin_users?.EVENTS?.WRITE[0] && (
          <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Typography variant="subtitle1" marginY={2}>
                Fecha: {startTime.format("DD-MM-YYYY")}
              </Typography>
              <TimePicker
                label="Hora de inicio"
                value={startTime}
                onChange={handleStartTimeChange}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="normal" />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment} marginY={2}>
              <TimePicker
                label="Hora de finalización"
                value={endTime}
                onChange={handleEndTimeChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    error={!!error}
                    helperText={error}
                  />
                )}
              />
            </LocalizationProvider>
          </>
        )}

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={handleClose} sx={{ marginRight: 1 }}>
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={handleCreateEvent}
            disabled={!!error}
          >
            Crear evento
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
