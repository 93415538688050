import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Fragment, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

const CardPaymentsUser = ({ userPaymemts }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const handleViewVoucher = (voucherLink) => {
    setSelectedVoucher(voucherLink); // Guarda el enlace seleccionado
    setIsModalOpen(true); // Abre el modal
  };


  return (
    <Card>
      <CardContent>
        <Typography variant="h5" color={"#767676"}>Pagos</Typography>
        <Divider sx={{ mb: 1 }} />
        {!userPaymemts.length && (<Typography>Sin pagos registrados</Typography>)}
        <List
          sx={{
            width: "100%",
            height: 350,
            overflow: "auto",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)", // Agregar efecto de sombra
            mt: 2,
          }}
        >
          {userPaymemts?.map((payment, index) => (
            <Fragment key={index}>
              <ListItem>
                <ListItemText
                  primary={"Fecha"}
                  secondary={formatDate(payment.createdAt)}
                />
                <ListItemText
                  primary={"Observación"}
                  secondary={payment?.orders?.orderProduct?.product?.name}
                />
                <ListItemText
                  primary={payment?.method}
                  secondary={
                    <>
                      {payment?.status == "approved" ? (
                        <CheckIcon color="green" />
                      ) : (
                        <CancelIcon color="red" />
                      )}
                    </>
                  }
                />
                <ListItemText
                  primary={payment?.orders?.orderProduct?.product?.currency}
                  secondary={`$${payment?.orders?.totalPrice}`}
                />

                <>
                  {payment?.voucherLink && (
                    <IconButton
                      color="info"
                      aria-label="Ver comprobante"
                      onClick={() => handleViewVoucher(payment?.voucherLink)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  )}

                  {/* Modal para mostrar el voucher */}
                  <Dialog
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)} // Cierra el modal
                    fullWidth
                    maxWidth="md" // Ajusta el tamaño del modal
                  >
                    <DialogTitle>Comprobante</DialogTitle>
                    <DialogContent>
                      {selectedVoucher ? (
                        <img
                          src={selectedVoucher}
                          alt="Voucher"
                          style={{ width: "100%", height: "auto", objectFit: "contain" }}
                        />
                      ) : (
                        <Typography variant="body1">No se pudo cargar el comprobante.</Typography>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setIsModalOpen(false)} color="primary">
                        Cerrar
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>

              </ListItem>
              <Divider component="li" />
            </Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default CardPaymentsUser;
