const BUILD = "dev"; // <<<<-------- CAMBIAR POR "dev" | "prod"

const APIURL =
  BUILD === "dev"
    ? process.env.REACT_APP_API_BASE_DEV
    : process.env.REACT_APP_API_BASE_PRO;

const API_URL_SOCKET =
  BUILD === "dev"
    ? process.env.REACT_APP_API_BASE_SOCKET_DEV
    : process.env.REACT_APP_API_BASE_SOCKET_PRO;

console.log(`========>`, APIURL);
console.log(`=======>`, API_URL_SOCKET);

export { APIURL, API_URL_SOCKET };
