import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import LayersIcon from "@mui/icons-material/Layers";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { LiveTv } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import SettingsIcon from "@mui/icons-material/Settings";
import CategoryIcon from "@mui/icons-material/Category";
import ArchiveIcon from "@mui/icons-material/Archive";
import ChatIcon from "@mui/icons-material/Chat";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const CustomLink = ({ to, icon: Icon, primary, open }) => {
  const location = useLocation();
  const isSelected = location.pathname === to;

  const getStyles = (isSelected, open) => ({
    textDecoration: "none",
    color: isSelected ? "#D0749B" : "#767676",
    backgroundColor: open ? "rgba(0, 0, 0, 0.04)" : "transparent",
  });

  return (
    <Link to={to} style={getStyles(isSelected, open)}>
      <ListItemButton
        sx={{
          backgroundColor: isSelected & open ? "#D0749B" : "transparent",
          borderRadius: 15,
          margin: isSelected & open ? "5%" : "0 5%",
        }}
      >
        <ListItemIcon>
          <Icon
            sx={{
              color: isSelected ? (open ? "white" : "#D0749B") : "#767676",
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={primary}
          sx={{ color: isSelected ? (open ? "white" : "#D0749B") : "#767676" }}
        />
      </ListItemButton>
    </Link>
  );
};

export const mainListItems = (open, user) => {
  //  const { user } = React.useContext(CartContext);

  return (
    <React.Fragment>
      <>
        <CustomLink to="/" icon={HomeIcon} primary="Inicio" open={open} />
        <CustomLink
          to="/usuarios"
          icon={PeopleIcon}
          primary="Usuarias"
          open={open}
        />
        <CustomLink
          to="/admin"
          icon={AdminPanelSettingsIcon}
          primary="Administradores"
          open={open}
        />

        <CustomLink
          to="/talleres"
          icon={LayersIcon}
          primary="Talleres"
          open={open}
        />

        {/* <CustomLink to="/estadisticas" icon={AutoGraphIcon} primary="Estadísticas" open={open} /> */}
        <CustomLink
          to="/calendario"
          icon={CalendarMonthIcon}
          primary="Calendario"
          open={open}
        />
        <CustomLink
          to="/profesionales"
          icon={LocalHospitalIcon}
          primary="Profesionales"
          open={open}
        />
        {/* <CustomLink to="/recetas" icon={RestaurantIcon} primary="Recetas" open={open} /> */}
        <CustomLink
          to="/categories"
          icon={CategoryIcon}
          primary="Categorias"
          open={open}
        />
        <CustomLink
          to="/archives"
          icon={ArchiveIcon}
          primary="Vivos Archivados"
          open={open}
        />
        <CustomLink
          to="/comments"
          icon={ChatIcon}
          primary="Chat Lives"
          open={open}
        />
        <CustomLink
          to="/channels"
          icon={InterpreterModeIcon}
          primary="Channels"
          open={open}
        />
        <CustomLink to="/broadcast" icon={LiveTv} primary="Live" open={open} />
        <CustomLink
          to="/settings"
          icon={SettingsIcon}
          primary="CBU"
          open={open}
        />
        {/* <CustomLink to="/lives" icon={VideoCallIcon} primary="Lives" open={open} /> */}
      </>
    </React.Fragment>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    {/* <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader> */}
  </React.Fragment>
);
