import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Input, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledForm = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  minWidth: 60,
  maxWidth: 800,
  margin: "auto",
  padding: theme.spacing(6),
  backgroundColor: "#f9f9f9",
  borderRadius: theme.spacing(2),
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
}));

const NewUserModal = ({
  handleClose,
  open,
  data,
  handleChange,
  handleSubmit,
  isEditing,
}) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <StyledForm component="form" onSubmit={handleSubmit}>
          <Typography variant="h5" align="center" gutterBottom>
            {!isEditing ? "Registro de Administradora" : "Editar Administrador"}
          </Typography>

          <Box display="flex" gap={2}>
            <TextField
              id="name"
              label="Nombre"
              variant="outlined"
              fullWidth
              required
              value={data.name}
              onChange={handleChange}
            />
            <TextField
              id="lastname"
              label="Apellido"
              variant="outlined"
              fullWidth
              required
              value={data.lastname}
              onChange={handleChange}
            />
          </Box>

          <Box display="flex" gap={2}>
            <TextField
              id="address"
              label="Dirección"
              variant="outlined"
              fullWidth
              value={data.address}
              onChange={handleChange}
            />
            <TextField
              id="dni"
              label="DNI"
              variant="outlined"
              fullWidth
              required
              value={data.dni}
              onChange={handleChange}
            />
          </Box>

          <Box display="flex" gap={2}>
            <TextField
              id="phone"
              label="Teléfono"
              variant="outlined"
              fullWidth
              value={data.phone}
              onChange={handleChange}
            />
            <TextField
              autoComplete="new-password"
              id="email"
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              required
              value={data.email}
              onChange={handleChange}
            />
          </Box>

          <Box display="flex" gap={2}>
            <TextField
              autoComplete="new-password"
              id="password"
              label="Contraseña"
              type="password"
              variant="outlined"
              fullWidth
              required
              value={data.password}
              onChange={handleChange}
            />
            <TextField
              id="birthdate"
              label="Fecha de Nacimiento"
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            {!isEditing ? "Registrar" : "Editar"}
          </Button>
        </StyledForm>
      </Modal>
    </>
  );
};

export default NewUserModal;
