import { APIURL } from "../models/api-base";
import { apiUrl_calendar, apiUrl_recipes } from "../models/api-urls";
import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  /**
   * Traer todos los eventos
   */
  getRecipes = async () => {
    const response = await this.get(apiUrl_recipes);
    return response;
  };

  createRecipe = async (recipe) => {
    const response = await this.post(apiUrl_recipes, recipe);
    return response;
  };

  /**
   * Traer todos los eventos
   * @param {number} id
   */
  updateCalendarEvent = async (update, id) => {
    const endpoint = apiUrl_calendar + "/" + id;
    const response = await this.put(endpoint, update);
    return response;
  };
  /**
   * Traer todos los eventos
   * @param {number} id
   * @returns
   */
  deleteCalendarEvent = async (id) => {
    const endpoint = apiUrl_calendar + "/" + id;
    const response = await this.delete(endpoint);
    return response;
  };
}

const RecipesService = new Service();

export default RecipesService;
