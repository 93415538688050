import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import ApiService from "../../modules/APIService.interceptors";

const VoucherModal = ({
  open,
  onClose,
  imageSrc,
  paymentId,
  updatePaymentStatus,
}) => {
  const [loader, setLoader] = useState(false);
  const [accept, setAccept] = useState(false); // Estado para el botón Aceptar
  const [reject, setReject] = useState(false); // Estado para el botón Rechazar

  const handleAccept = async () => {
    setLoader(true);
    setAccept(true);
    try {
      const response = await ApiService.confirmPaymentToTransfer(paymentId);
      if (response) {
        updatePaymentStatus({ paymentId, newStatus: "approved" });
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
    onClose();
  };

  const handleReject = async () => {
    setLoader(true);
    setReject(true);
    try {
      const response = await ApiService.cancelPaymentToTransfer(paymentId);
      if (response) {
        updatePaymentStatus({ paymentId, newStatus: "rejected" });
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const modalContentStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px",
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent style={modalContentStyle}>
          <DialogContentText>
            <img
              src={imageSrc}
              alt="Imagen"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAccept}
            disabled={accept || reject}
            color="primary"
          >
            Aceptar
          </Button>
          <Button
            onClick={handleReject}
            disabled={accept || reject}
            color="error"
          >
            Rechazar
          </Button>
          <Button onClick={handleClose} xs={{ color: "#767676" }}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VoucherModal;
