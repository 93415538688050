import React, { useContext, useEffect, useState } from "react";
import { Table, TableContainer, Paper, CircularProgress } from "@mui/material";
import { TableHead } from "../../TableHead/TableHead";
import { VoucherTableBody } from "./VoucherTableBody";
import { VoucherTableFooter } from "./VoucherTableFooter";
import ApiService from "../../../modules/APIService.interceptors";
import SelectFilterDefault from "../../SelectFilterDefault/SelectFilterDefault";

const VoucherTable = ({ searchText, handleUpdateKey }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(0); // Estado para el total de filas
  const [paymentFilter, setPaymentFilter] = useState("all");
  const [stateFilter, setStateFilter] = useState("all");
  const [loader, setLoader] = useState(false);

  const headers = [
    "Nombre Apellido",
    "Email",
    "DNI",
    "Fecha",
    "Metodo de Pago",
    "Estado",
    "Comprobante",
  ];

  const StatePaymentList = [
    { value: "all", text: "Todos" },
    { value: "approved", text: "Aprobados" },
    { value: "pending", text: "Pendientes" },
    { value: "rejected", text: "Rechazados" },
  ];

  const PaymentMethodList = [
    { value: "all", text: "Todos" },
    { value: "MercadoPago", text: "Mercado Pago" },
    { value: "Paypal", text: "Paypal" },
    { value: "Transfer", text: "Transferencia" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const getAllUsersPayments = async () => {
      try {
        setLoader(true);
        const response = await ApiService.allPayments({
          amount: rowsPerPage,
          page: page + 1, // Ajusta el índice de página para la API
          paymentMethod: paymentFilter,
          paymentStatus: stateFilter,
          search: searchText,
        });
        if (response) {
          setUsers(response.data.payments);
          setTotalRows(response.data.total); // Actualiza el total de filas
        } else {
          console.log("Error");
        }
        setLoader(false);
      } catch (error) {
        console.error("ERROR TRAYENDO LOS PAGOS", error);
        setLoader(false);
      }
    };

    console.log();

    getAllUsersPayments();
  }, [paymentFilter, stateFilter, searchText, page, rowsPerPage]);

  const updatePaymentStatus = (updatedPayment) => {
    const updatedData = users?.map((user) =>
      user?.paymentId === updatedPayment.paymentId
        ? { ...user, paymentStatus: updatedPayment.newStatus }
        : user
    );
    setUsers(updatedData);
    handleUpdateKey();
  };

  return (
    <>
      <SelectFilterDefault
        disabled={false}
        filter={paymentFilter}
        setFilter={setPaymentFilter}
        title={"Método de pago"}
        list={PaymentMethodList}
      />
      <SelectFilterDefault
        disabled={false}
        filter={stateFilter}
        setFilter={setStateFilter}
        title={"Estado de pagos"}
        list={StatePaymentList}
      />

      <TableContainer component={Paper}>
        {loader ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <Table>
            {windowWidth < 900 ? (
              <div style={{ width: "700px", overflow: "auto" }}>
                <TableHead headers={headers} />
                <VoucherTableBody
                  data={users}
                  updatePaymentStatus={updatePaymentStatus}
                />
              </div>
            ) : (
              <>
                <TableHead headers={headers} />
                <VoucherTableBody
                  data={users}
                  updatePaymentStatus={updatePaymentStatus}
                />
              </>
            )}
            <VoucherTableFooter
              rowsPerPage={rowsPerPage}
              page={page}
              totalRows={totalRows} // Usa el total de filas desde el estado
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Table>
        )}
      </TableContainer>
    </>
  );
};

export { VoucherTable };
