// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user__box {
    display: flex;
    justify-content: center;
    /* width: 100%; */
    margin-bottom: 5px;
}

.user__box__container {
    /* max-width: 65vw; */
    /* width: 100%; */
    margin: 1rem;
}

.user__box__container__body {
    display: flex;
    gap: 2rem;
    align-items: flex-start;
}



@media (max-width: 1250px) {
    .user__box__container__body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/pages/ProfileUser/profileUser.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;AAC3B;;;;AAIA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,SAAS;QACT,mBAAmB;IACvB;;AAEJ","sourcesContent":[".user__box {\n    display: flex;\n    justify-content: center;\n    /* width: 100%; */\n    margin-bottom: 5px;\n}\n\n.user__box__container {\n    /* max-width: 65vw; */\n    /* width: 100%; */\n    margin: 1rem;\n}\n\n.user__box__container__body {\n    display: flex;\n    gap: 2rem;\n    align-items: flex-start;\n}\n\n\n\n@media (max-width: 1250px) {\n    .user__box__container__body {\n        display: flex;\n        flex-direction: column;\n        gap: 1rem;\n        align-items: center;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
