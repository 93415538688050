import { APIURL } from "../models/api-base";
import {
    apiURL_create_moderators,
    apiURL_getAll_moderators,
} from "../models/api-urls";

import fetchService from "../modules/APIService";

class Service extends fetchService {
    constructor() {
        super(APIURL);
    }

    /**
     * Recibo cantidad de usuarios a mostrar y la pagina a la que pertencen
     * @param {number} page
     * @param {number} rowsPerPage
     * @returns
     */
    getAllModerators = async () => {
        try {
            const response = await this.get(apiURL_getAll_moderators);
            return response.data;
        } catch (error) {
            console.log(error)
        }
    };

}

const ModeratorService = new Service();

export default ModeratorService;
