import React, { useContext, useEffect, useState } from "react";
import { VoucherTable } from "../../components/UserTable/VoucherTable/VoucherTable";
import { Box, Card } from "@mui/material";
import SnackBar from "../../common/SnackBar/SnackBar";
import NotAllowed from "../NotAllowed/NotAllowed";
import { useAuthStore } from "../../store/zustand/authStore";
import { CartContext } from "../../store/context/CartContext";

const Payments = () => {
  const [messageModal, setMessageModal] = useState("");
  const [openModal, setOpenModal] = useState("");
  const [tab, setTab] = useState(0);

  const [updateKey, setUpdateKey] = useState(0);
  const user = useAuthStore((state) => state.user);
  const { setTitleSeccion } = useContext(CartContext);

  useEffect(() => {
    setTitleSeccion("Pagos");
  }, []);

  const handleUpdateKey = () => {
    setUpdateKey((prevKey) => prevKey + 1);
    setTab(1);
  };

  return (
    <>
      <NotAllowed user={user} page={"PAYMENTS"} />
      <SnackBar
        message={messageModal}
        setOpen={setOpenModal}
        open={openModal}
        severity={""}
      />
      <Card sx={{ backgroundColor: "#D0749B", marginBottom: 2 }}>
        <VoucherTable key={updateKey} handleUpdateKey={handleUpdateKey} />
      </Card>
    </>
  );
};

export default Payments;
