import { APIURL } from "../models/api-base";
import { apiUrl_bank } from "../models/api-urls";
import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  getBank = async () => {
    const endpoint = apiUrl_bank;
    const response = await this.get(endpoint);
    return response;
  };

  updateBank = async (update, id) => {
    console.log("LO QUE ENVIO", update)
    const endpoint = apiUrl_bank + "/" + id;
    const response = await this.put(endpoint, update);
    return response;
  };
}

const BankService = new Service();

export default BankService;
