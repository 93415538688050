import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  AlertTitle,
  IconButton,
  CircularProgress,
  Switch,
  Divider,
} from "@mui/material";
import io from "socket.io-client";
import { TOKENSTORAGE } from "../../models/constants";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import LiveService from "../../services/live.service";
import { API_URL_SOCKET } from "../../models/api-base";
import CircleIcon from "@mui/icons-material/Circle";

const BroadcastLive = () => {
  const parentRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);
  const [comments, setComments] = useState([]);
  const [socket, setSocket] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [onlineUsers, setOnlineUser] = useState([]);
  const [hideComments, setHideComments] = useState();


  useEffect(() => {
    const options = {
      secure: true,
      reconnection: true,
      rejectUnauthorized: false,
      query: {
        token: localStorage.getItem(TOKENSTORAGE),
        lastMessageTimeStamp: new Date(Date.now()).getTime(),
      },
      transports: ["websocket","polling"],
    };
    // Conectar al socket
    console.log("SOCKET_URL", API_URL_SOCKET);
    const socketInstance = io(`${API_URL_SOCKET}/live`, options);
    setSocket(socketInstance);

    socketInstance.on("live", (data) => {
      console.log("mensajes ->", data);
      // Actualizar el estado comments con el nuevo mensaje
      setComments((prevComments) => [...prevComments, data.data]);
    });

    socketInstance.on("getOnlineUsers", (data) => {
      // Mapea cada usuario en el array de datos online
      const parsedUsers = data.onlineUsers.map((userString) => {
        const userObject = JSON.parse(userString); // Convierte el string en un objeto
        const { name, lastname } = userObject; // Extrae nombre y apellido
        return { name, lastname }; // Devuelve un objeto solo con esos datos
      });
      console.log("usuarios ->", parsedUsers);
      setOnlineUser(parsedUsers);
    });

    socketInstance.on("getLiveStatus", (data) => {
      console.log("status ->", data);
      // Actualizar el estado comments
      if (data.liveStatus == "ON") {
        setHideComments(false);
      } else {
        setHideComments(true);
      }
    });

    // Limpieza al desmontar el componente
    return () => {
      if (socketInstance) {
        socketInstance.off("live");
        socketInstance.close();
        socketInstance.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (parentRef.current) {
      setIsMounted(true);
    }
  }, []);

  useEffect(() => {
    if (isMounted && parentRef.current) {
      const interval = setInterval(() => {
        parentRef.current.scrollTop = parentRef.current.scrollHeight;
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isMounted, comments]);

  const sendNotification = async () => {
    setLoader(true);
    try {
      const response = await LiveService.sendStartLiveNotification();
      if (response) {
        setIsSend(true);
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const manageLiveChat = async () => {
    try {
      socket.emit("manageSocket", {
        action: !hideComments ? "PAUSE" : "RESUME",
      });
    } catch (e) {
      console.log(e);
    } finally {
      setHideComments(!hideComments);
    }
  };

  return (
    <>
      {/* Alert */}
      {isSend && (
        <Box
          position={"fixed"}
          top={0}
          left={0}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          zIndex={1100}
          padding={1}
        >
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsSend(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ width: "100%" }}
          >
            <AlertTitle>Éxito!</AlertTitle>
            Se ha enviado la notificación a todas las sirenas.
          </Alert>
        </Box>
      )}

      {/* Comentarios y resto del componente */}
      <Box
        width={"100%"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        padding={1}
        boxSizing={"border-box"}
        //backgroundColor={"red"}
      >
        {/* Titulo y boton*/}
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", sm: "row" }}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ backgroundColor: "transparent" }}
        >
          <Typography
            variant="h4"
            color={"grey"}
            textAlign={{ xs: "center", sm: "left" }}
          >
            Comentarios Live
          </Typography>

          <Button
            variant="contained"
            size="large"
            onClick={sendNotification}
            sx={{ mt: { xs: 2, sm: 0 } }}
          >
            {!loader ? `Notificar en Vivo!` : <CircularProgress size={24} />}
          </Button>
        </Box>

        <div>
          <label style={{ color: "grey" }}>
            {!hideComments
              ? "Deshabilitar Comentarios"
              : "Habilitar Comentarios"}
          </label>
          <Switch
            checked={hideComments}
            onChange={manageLiveChat}
            name="commentsSwitch"
            color="primary"
          />
        </div>

        <Box
          display={"flex"}
          width={"100%"}
          overflowY="auto"
          flexDirection={"row"}
        >
          {/* Comentarios */}
          <Box
            flex={3}
            overflowY="auto"
            ref={parentRef}
            marginTop={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 2,
            }}
          >
            <List sx={{ padding: 0, color: "grey" }}>
              {comments
                ?.slice()
                .reverse()
                .map((comment, index) => (
                  <ListItem key={index} divider>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" fontWeight="bold">
                          {comment?.name}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2">
                          {comment?.message}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderRightWidth: 3,
              borderColor: "grey",
              marginX: 2,
            }}
          />

          {/* Caja de usuarias */}
          <Box
            flex={1}
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="h5" color={"grey"}>
              Usuarias conectadas
            </Typography>

            <List backgroundColor={"pink"}>
              {onlineUsers
                ?.slice()
                .reverse()
                .map((item, index) => (
                  <ListItem key={index} divider>
                    <ListItemText
                      primary={
                        <Box display={"flex"} flexDirection={"row"}>
                          <Box
                            borderRadius={9}
                            width={"16%"}
                            height={"1%"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            alignSelf={"center"}
                            paddingRight={4}
                          >
                            <CircleIcon
                              fontSize="small"
                              sx={{ color: "rgba(121, 250, 58, 0.8)" }}
                            />
                          </Box>
                          <Typography variant="subtitle2" color={"grey"}>
                            {item?.name} {item?.lastname}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BroadcastLive;
