import { useCallback, useEffect, useState } from "react";
import { useSocketStore } from "../store/zustand/socketStore";
import ChannelService from "../services/channel.service";
import { createMutedMessage } from "../utils/createMutedMessage";

const useChat = ({ channelId, role }) => {
  const channelSocket = useSocketStore((state) => state.channelSocket);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Escuchar mensajes entrantes y limpiar al cambiar de canal
  useEffect(() => {
    if (!channelSocket) return;

    // Configurar escucha de mensajes para el canal actual
    const handleMessage = (data) => {
      const message = data.data;
      if (!message.deleted) {
        setMessages((prevMessages) => [...prevMessages, message]);
      } else {
        // Si el mensaje fue eliminado, actualizar el estado
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            message.createdAt === msg.createdAt &&
            message.message === msg.message &&
            message.userId === msg.userId
              ? { ...msg, message: "¡Mensaje eliminado por el administrador!" }
              : msg
          )
        );
      }
    };

    channelSocket.on(channelId, handleMessage);

    // Manejo de eventos adicionales
    handleSocketEventsChannels();

    // Cleanup al cambiar de canal
    return () => {
      channelSocket.off(channelId, handleMessage);
      setMessages([]); // Resetear los mensajes
    };
  }, [channelSocket, channelId]);

  const loadOldMessages = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const oldMessages = await ChannelService.getChatHistory(channelId);
      setMessages((prevMessages) => {
        const uniqueMessages = oldMessages.filter(
          (oldMsg) => !prevMessages.some((msg) => msg.id === oldMsg.id) // Evita duplicados
        );
        return [...uniqueMessages, ...prevMessages]; // Agrega mensajes antiguos únicos
      });
    } catch (err) {
      setError("Error al cargar mensajes antiguos.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [channelId]);

  const sendMessage = (message) => {
    if (channelSocket && message.message.trim() !== "" && message) {
      channelSocket.emit(channelId, message);
      setMessages((prevMessages) => [...prevMessages, message]);
    }
  };

  const mutedUser = (userId, mutedUntil) => {
    if (channelSocket && role) {
      const time = new Date().getTime() + mutedUntil;
      channelSocket.emit("handleUserChat", {
        userId,
        channel: channelId,
        muted: true,
        mutedUntil: time,
      });
    } else {
      console.warn("El socketChannels no se encuentra conectado");
    }
  };

  const deleteMessage = (data) => {
    if (channelSocket && role) {
      channelSocket.emit("deleteMessage", {
        ...data,
        channel: channelId,
      });
    } else {
      console.warn("El socketChannels no se encuentra conectado");
    }
  };

  const handleSocketEventsChannels = () => {
    if (channelSocket) {
      channelSocket.on("chatStatus", (data) => {
        if (data.message && data.until) {
          if (data.message && data.until) {
            const alertMessage = createMutedMessage(data.until);
            setMessages((prevMessages) => [...prevMessages, alertMessage]);
          }
        }
      });
    } else {
      console.warn("El socketChannels no se encuentra conectado");
    }
  };

  return {
    messages,
    sendMessage,
    mutedUser,
    deleteMessage,
    loadOldMessages,
    loading,
    error,
  };
};

export default useChat;
