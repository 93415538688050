import SnackBar from "../../common/SnackBar/SnackBar";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../store/context/CartContext";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  IconButton,
  CircularProgress,
  Backdrop
} from "@mui/material";
import pSirenas from "../../assets/pSirenas.png";
import ApiService from "../../modules/APIService.interceptors";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import formatDate from "../../common/Format/FormatDate";

const Workshops = () => {
  const { setTitleSeccion } = useContext(CartContext);
  const [messageModal, setMessageModal] = useState("");
  const [openModal, setOpenModal] = useState("");
  const [severity, setSeverity] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [loader, setLoader] = useState(false);
  const [talleres, setTalleres] = useState([]);
  const [statusLabel, setStatusLabel] = useState("");

  const numTalleresToShow = showMore ? talleres?.length : 5;

  const navigate = useNavigate();

  useEffect(() => {
    setTitleSeccion("Talleres");
    getAllWorkshops();

  }, []);

  const getAllWorkshops = async () => {
    setLoader(true);
    try {
      const workshopsRequest = await ApiService.getAllWorkshops();
      if (workshopsRequest) {
        const sortedWorkshops = workshopsRequest.data.sort((a, b) => a.workshopNumber - b.workshopNumber);
        setTalleres(sortedWorkshops);
      } else {
        console.error("Error al obtener los talleres");
      }
    } catch (error) {
      console.error("Error en la solicitud de talleres:", error);
    } finally {
      setLoader(false);
    }
  };

  const goToCreateWorkshop = () => {
    navigate("/taller");
  };
  const goToWorkshop = (id) => {
    navigate(`/talleres/${id}`);
  };

  const handleChange = (event) => {
    if (event === "onHold") {
      return ("Proximamente");
    } else if (event === "inProgress") {
      return ("En progreso");
    } else if (event === "onSale") {
      return ("En venta");
    } else if (event === "finalized") {
      return ("Finalizado");
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <SnackBar
        message={messageModal}
        setOpen={setOpenModal}
        open={openModal}
        severity={severity}
      />
      <Grid container spacing={2}>

        {/* CREATE NEW WORKSHOP CARD */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{ height: "270px", cursor: "pointer", borderRadius: 5, backgroundColor: "rgba(118, 118, 118, 0.05)" }}
            onClick={goToCreateWorkshop}
          >
            <CardContent>
              <div style={{ textAlign: "center", width: "100%" }}>
                <Typography
                  variant="h5"
                  component="div"
                  style={{ fontWeight: "bold", marginBottom: "10px", color: "#767676" }}
                >
                  Agregar Nuevo
                </Typography>
                <img
                  src={pSirenas} // Reemplaza con la URL o ruta de tu logo
                  alt="Logo del taller"
                />
                <div>
                  <IconButton>
                    <AddCircleIcon sx={{ fontSize: 65 }} color="secondary" />
                  </IconButton>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* WORKSHOPS CARDS */}
        {talleres?.slice(0, numTalleresToShow)?.map((taller, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{ cursor: "pointer", borderRadius: 5 }}
              onClick={() => goToWorkshop(taller.id)}
            >
              <CardContent>
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{ fontWeight: "bold", marginBottom: "10px", color: "#767676" }}
                  >
                    Taller Nº {taller.workshopNumber}
                  </Typography>
                  <img
                    src={pSirenas} // Reemplaza con la URL o ruta de tu logo
                    alt="Logo del taller"
                  />
                  <Typography variant="subtitle1" style={{ color: "#767676" }}>
                    Estado: {handleChange(taller.status)}
                  </Typography>
                  <Typography variant="subtitle1" style={{ color: "#767676" }}>
                    Fecha de inicio: {formatDate(taller.dateStart)}
                  </Typography>
                  <Typography variant="subtitle1" style={{ color: "#767676" }}>
                    Fecha de cierre: {formatDate(taller.dateEnd)}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
        {talleres?.length > 6 && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => setShowMore(!showMore)}
              fullWidth
              color="secondary"
            >
              {showMore ? "Ver menos" : "Ver más"}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Workshops;
