import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  Button,
  Snackbar,
  Chip
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import ModeratorService from '../../services/moderators.service';
import { CartContext } from '../../store/context/CartContext';
import AddModeratorDialog from '../../components/AddModerators/AddModerators';
import ChatService from '../../services/chat.service';


const ModeratorsTable = () => {
  const [moderators, setModerators] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { setTitleSeccion } = useContext(CartContext);
  const [channels, setChannels] = useState([])

  useEffect(() => {
    setTitleSeccion("Moderadores");
  }, []);


  // Procesar los datos para tener moderadores únicos con sus canales asociados
  useEffect(() => {
    const getAllModerators = async () => {
      try {
        // Llamada asíncrona a la API
        const response = await ModeratorService.getAllModerators();
        const channels = response;
        // Estructura para almacenar moderadores únicos
        const uniqueModerators = {};
        // Procesar canales y moderadores
        channels.forEach(channel => {
          channel.moderators.forEach(mod => {
            const userId = mod.userId;
            if (!uniqueModerators[userId]) {
              uniqueModerators[userId] = {
                id: userId,
                name: `${mod.User.name} ${mod.User.lastname}`,
                email: mod.User.email,
                avatar: mod.User.avatar,
                channels: [channel.name],
                level: mod.level
              };
            } else {
              uniqueModerators[userId].channels.push(channel.name);
            }
          });
        });
        // Actualizar estado con la lista de moderadores únicos
        setModerators(Object.values(uniqueModerators));
      } catch (error) {
        console.error('Error al obtener moderadores:', error);
      }
    };
    getAllModerators();
  }, []);

  // Agregar esta función dentro de `ModeratorsTable`
  const addModeratorToTable = (newModerator) => {
    setModerators((prev) => {
      const existingModerator = prev.find((mod) => mod.id === newModerator.id);
      if (existingModerator) {
        // Si el moderador ya existe, agregar solo los canales nuevos
        const updatedModerators = prev.map((mod) =>
          mod.id === newModerator.id
            ? { ...mod, channels: [...new Set([...mod.channels, ...newModerator.channels])] }
            : mod
        );
        return updatedModerators;
      } else {
        // Si el moderador es nuevo, agregarlo al listado
        return [...prev, newModerator];
      }
    });
  };


  // Función para eliminar un chip
  const handleDelete = async (channelToDelete, id, deleteAll) => {

    const toDelete = {
      arrOfChannelsUids: deleteAll ? ["LIVE", "COMUNIDAD", "EJERCICIOS", "RECETAS"] : [channelToDelete],
      userId: id
    }

    // Si se elimina un canal individualmente
    if (channelToDelete && !deleteAll) {
      // Eliminar canal individualmente
      setModerators((prev) =>
        prev.map((mod) =>
          mod.id === id
            ? { ...mod, channels: mod.channels.filter((ch) => ch !== channelToDelete) }
            : mod
        )
      );

      try {
        const response = await ChatService.deleteModerator(toDelete);
        if (response) {
          setSnackbarMessage(`Canal ${channelToDelete} eliminado del servidor.`);
          setOpenSnackbar(true);
        }
      } catch (error) {
        setSnackbarMessage('Error al eliminar el canal:', error);
        setOpenSnackbar(true);
        setChannels((prevChannels) => [...prevChannels, channelToDelete]);
      } finally {
        setOpenSnackbar(false)
      }
    }

    // Si se eliminan todos los canales y el moderador
    if (deleteAll) {
      // Eliminar todos los canales y el moderador completo
      setModerators((prev) => prev.filter((mod) => mod.id !== id));

      try {
        const response = await ChatService.deleteModerator(toDelete);
        if (response) {
          setSnackbarMessage(`Moderador y sus canales eliminados del servidor.`);
          setOpenSnackbar(true);
        }
      } catch (error) {
        setSnackbarMessage('Error al eliminar el moderador:', error);
        setOpenSnackbar(true);

        // Restaurar los canales si falla
        setChannels(channels);
      } finally {
        setOpenSnackbar(false)
      }
    }
  };



  // Definir columnas para DataGrid
  const columns = [
    {
      field: 'avatar',
      headerName: 'Avatar',
      width: 80,
      renderCell: (params) => <Avatar src={params.value} alt={params.row.name} />
    },
    { field: 'name', headerName: 'Nombre', flex: 1 },
    { field: 'email', headerName: 'Correo', flex: 1 },
    {
      field: 'channels',
      headerName: 'Canales',
      flex: 2,
      renderCell: (params) => (
        params.value.map((channel, index) => (
          <Chip key={index} label={channel} color="primary" variant="outlined" sx={{ mr: 1 }} onDelete={() => handleDelete(channel, params.row.id)} />
        ))
      )
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton color="error" onClick={() => handleDelete(null, params.row.id, true)}>
            <Delete />
          </IconButton>
        </>
      )
    }
  ];

  return (
    <Box sx={{ width: '90%', margin: 'auto', mt: 4 }}>
      <Typography variant="h4" color="grey" sx={{ mb: 3 }}>Gestión de Moderadores</Typography>

      <Button variant="contained" onClick={() => setOpenDialog(true)} sx={{ marginBottom: 2 }}>
        Agregar Moderador
      </Button>

      <AddModeratorDialog openDialog={openDialog} handleCloseDialog={() => setOpenDialog(false)} addModeratorToTable={addModeratorToTable} />

      {/* Tabla de moderadores */}
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          rows={moderators.map((mod, index) => ({ ...mod, id: mod.id || index }))}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            }
          }}
        />
      </div>
      {/* Snackbar de notificación */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        message={snackbarMessage}
        onClose={() => setOpenSnackbar(false)}
      />
    </Box>
  );
};

export default ModeratorsTable;
