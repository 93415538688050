import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function SelectFilterDefault({
  filter,
  setFilter,
  disabled,
  title,
  list,
}) {
  const handleChange = (event: SelectChangeEvent) => {
    // console.log(test);
    setFilter(event.target.value);
  };

  return (
    <FormControl
      sx={{ marginTop: 2, marginLeft: 3, marginBottom: 2, minWidth: 170 }}
      size="small"
    >
      <InputLabel id="demo-select-small-label" style={{ color: "#767676" }}>
        {title}
      </InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={filter}
        label={title}
        onChange={handleChange}
        disabled={disabled}
        color="secondary"
        style={{ color: "#767676" }}
      >
        {list.map((item) => {
          return (
            <MenuItem value={item.value} style={{ color: "#767676" }}>
              {item.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
