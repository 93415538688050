import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  IconButton,
  CircularProgress,
  Backdrop,
  TextField,
  MenuItem,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../../modules/APIService.interceptors";
import SnackBar from "../../common/SnackBar/SnackBar";
import formatDate from "../../common/Format/FormatDate";
import { CartContext } from "../../store/context/CartContext";
import pSirenas from "../../assets/pSirenas.png";

const Workshops = () => {
  const { setTitleSeccion } = useContext(CartContext);
  const [messageModal, setMessageModal] = useState("");
  const [openModal, setOpenModal] = useState("");
  const [severity, setSeverity] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [loader, setLoader] = useState(false);
  const [talleres, setTalleres] = useState([]);
  const [estadoFilter, setEstadoFilter] = useState("all");
  const [numeroFilter, setNumeroFilter] = useState("");
  const [ordenFilter, setOrdenFilter] = useState("desc");

  const navigate = useNavigate();

  useEffect(() => {
    setTitleSeccion("Talleres");
    getAllWorkshops();
  }, []);

  const getAllWorkshops = async () => {
    setLoader(true);
    try {
      const workshopsRequest = await ApiService.getAllWorkshops();
      if (workshopsRequest) {
        const sortedWorkshops = workshopsRequest.data.sort(
          (a, b) => a.workshopNumber - b.workshopNumber
        );
        setTalleres(sortedWorkshops);
      } else {
        console.error("Error al obtener los talleres");
      }
    } catch (error) {
      console.error("Error en la solicitud de talleres:", error);
    } finally {
      setLoader(false);
    }
  };

  const goToCreateWorkshop = () => {
    navigate("/taller");
  };

  const goToWorkshop = (id) => {
    navigate(`/talleres/${id}`);
  };

  const handleEstadoChange = (event) => {
    setEstadoFilter(event.target.value);
  };
  ;

  const handleNumeroChange = (event) => {
    setNumeroFilter(event.target.value);
  };

  const handleOrdenChange = (event) => {
    setOrdenFilter(event.target.value);
  };

  const handleChange = (status) => {
    const estados = {
      onHold: "Próximamente",
      inProgress: "En progreso",
      onSale: "En venta",
      finalized: "Finalizado",
    };
    return estados[status] || "Desconocido";
  };

  // 🔹 Aplicar filtros
  let filteredTalleres = talleres
    .filter(
      (taller) => estadoFilter === "all" || taller.status === estadoFilter
    )
    .filter(
      (taller) =>
        numeroFilter === "" ||
        taller.workshopNumber.toString().includes(numeroFilter)
    );

  // 🔹 Aplicar orden (Más Nuevos / Más Viejos)
  filteredTalleres = filteredTalleres.sort((a, b) => {
    return ordenFilter === "desc"
      ? new Date(b.dateStart) - new Date(a.dateStart) // Más nuevos primero
      : new Date(a.dateStart) - new Date(b.dateStart); // Más viejos primero
  });


  const numTalleresToShow = showMore ? filteredTalleres?.length : 8;

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
        <CircularProgress color="primary" />
      </Backdrop>
      <SnackBar message={messageModal} setOpen={setOpenModal} open={openModal} severity={severity} />

      {/* 🔹 Filtros */}
      <Grid container sx={{ mb: 2, display: "flex", justifyContent: "start" }}>

        {/* Filtro de Estado */}
        {/* <Grid item sm={2} marginRight={"1%"}>
          <TextField
            select
            label="Estado"
            fullWidth
            value={estadoFilter}
            onChange={handleEstadoChange}
            variant="outlined"
          >
            <MenuItem value="all">Todos</MenuItem>
            <MenuItem value="onHold">Próximamente</MenuItem>
            <MenuItem value="inProgress">En progreso</MenuItem>
            <MenuItem value="onSale">En venta</MenuItem>
            <MenuItem value="finalized">Finalizado</MenuItem>
          </TextField>
        </Grid> */}

        {/* 🔹 Filtro por Más Nuevos / Más Viejos */}
        <Grid item sm={2} >
          <TextField
            select
            label="Orden"
            fullWidth
            value={ordenFilter}
            onChange={handleOrdenChange}
            variant="outlined"
          >
            <MenuItem value="desc">Más Nuevos</MenuItem>
            <MenuItem value="asc">Más Viejos</MenuItem>
          </TextField>
        </Grid>

        {/* Filtro por Número de Taller */}
        <Grid item sm={2} sx={{ marginLeft: "1%" }}>
          <TextField
            label="Número de Taller"
            type="number"
            fullWidth
            value={numeroFilter}
            onChange={handleNumeroChange}
            variant="outlined"
          />
        </Grid>


      </Grid>

      <Grid container spacing={2}>
        {/* CREATE NEW WORKSHOP CARD */}
        <Grid item>
          <Card
            sx={{
              height: "275px",
              width: "250px",
              cursor: "pointer",
              borderRadius: 5,
              backgroundColor: "rgba(118, 118, 118, 0.05)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={goToCreateWorkshop}
          >
            <CardContent style={{ textAlign: "center", width: "100%" }}>
              <Typography variant="h5" fontWeight="bold" color="#767676" textAlign="center">
                Nuevo Taller
              </Typography>
              <IconButton>
                <AddCircleIcon sx={{ fontSize: 65 }} color="secondary" />
              </IconButton>
            </CardContent>
          </Card>
        </Grid>

        {/* WORKSHOPS CARDS */}
        {filteredTalleres.slice(0, numTalleresToShow).map((taller, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ cursor: "pointer", borderRadius: 5 }} onClick={() => goToWorkshop(taller.id)}>
              <CardContent style={{ textAlign: "center", width: "100%" }} >
                <Typography variant="h5" fontWeight="bold" color="#767676" textAlign="center">
                  Taller Nº {taller.workshopNumber}
                </Typography>
                <img src={pSirenas} alt="Logo del taller" />
                {/* <Typography variant="subtitle1" color="#767676">
                  Estado: {handleChange(taller.status)}
                </Typography> */}
                <Typography variant="subtitle1" color="#767676">
                  Fecha de inicio: {formatDate(taller.dateStart)}
                </Typography>
                <Typography variant="subtitle1" color="#767676">
                  Fecha de cierre: {formatDate(taller.dateEnd)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {filteredTalleres.length > 8 && (
          <Grid item xs={12} textAlign="center">
            <Button variant="contained" onClick={() => setShowMore(!showMore)} color="secondary" sx={{ color: "white" }}>
              {showMore ? "Ver menos" : "Ver más"}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Workshops;
