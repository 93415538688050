import { TableFooter, TablePagination, TableRow } from "@mui/material";

const VoucherTableFooter = ({ rowsPerPage, page, totalRows, handleChangePage, handleChangeRowsPerPage }) => {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          colSpan={3}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ color: "#767676" }}
        />
      </TableRow>
    </TableFooter>
  );
};

export { VoucherTableFooter };
