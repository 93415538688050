import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import AdminService from "../../services/admin.service";

const Admin = () => {
  const [admins, setAdmins] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdmins = async () => {
      const response = await AdminService.getAll();
      setAdmins(response);
    };
    fetchAdmins();
  }, []);

  const handleRowClick = (id) => {
    navigate(`/admin/${id}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Apellido</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {admins.map((admin) => (
            <TableRow
              key={admin.id}
              hover
              onClick={() => handleRowClick(admin.id)}
              style={{ cursor: "pointer" }}
            >
              <TableCell>{admin.firstName}</TableCell>
              <TableCell>{admin.lastName}</TableCell>
              <TableCell>{admin.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Admin;
