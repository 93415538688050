const BUILD = "dev";

// const APIURL = "http://3.81.82.168:3001";
// const API_URL_SOCKET = "http://18.234.225.82:3031";

//Produccion
const APIURL = "https://api-admin.proyecto-sirenas.com";
const API_URL_SOCKET = "https://chat.proyecto-sirenas.com";

console.log(APIURL);

export { APIURL, API_URL_SOCKET };
