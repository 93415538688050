import { TableFooter, TablePagination, TableRow } from "@mui/material";

const UserTableFooter = ({
  rowsPerPage,
  page,
  totalRows,
  handleChangePage,
}) => {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[]}
          colSpan={3}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={Number(page)}
          onPageChange={handleChangePage}
          style={{ color: "#767676" }}
        />
      </TableRow>
    </TableFooter>
  );
};

export { UserTableFooter };
