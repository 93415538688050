import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ApiService from "../../modules/APIService.interceptors"; // Import ApiService

const ProductList = ({
  products,
  Currency,
  TypeProduct,
  DiscountType,
  reloadData,
  user
}) => {
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleOpen = (product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  const handleChange = (e) => {
    setSelectedProduct({
      ...selectedProduct,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelete = () => {
    setConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await ApiService.deleteProductById(selectedProduct.id); // Call deleteProductById
      setConfirmOpen(false);
      handleClose();
      setSnackbarMessage("Producto eliminado exitosamente");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error eliminando producto => ", error);
      setSnackbarMessage("Error eliminando producto");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      reloadData();
    }
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const handleApplyChanges = async () => {
    try {
      await ApiService.updateProduct(selectedProduct.id, selectedProduct); // Call updateProduct
      handleClose();
      setSnackbarMessage("Cambios aplicados exitosamente");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error actualizando producto => ", error);
      setSnackbarMessage("Error aplicando cambios");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      reloadData();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          columnGap: 2,
          rowGap: 0,
        }}
      >
        {products.length > 0 ? (
          products
            .sort((a, b) => b.id - a.id) // Sort products by ID in descending order
            .map((product) => (
              <Box
                key={product.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  marginBottom: 3,
                  padding: 2,
                  border: "1px solid #E0E0E0",
                  borderRadius: 4,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#FFF",
                  width: 420,
                  height: 250,
                  position: "relative",
                  overflow: "hidden",
                  cursor: "pointer", // Change cursor to pointer on hover
                  transition: "transform 0.2s, box-shadow 0.2s", // Add transition for animation
                  "&:hover": {
                    transform: "scale(1.02)", // Slightly scale up on hover
                    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)", // Increase shadow on hover
                  },
                }}
                onClick={() => handleOpen(product)} // Make the card clickable
              >
                {/* Icono de fondo */}
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%) scale(4.5)",
                    color: "#F6919580", // Color semitransparente para fondo
                    zIndex: 0, // Debajo de otros elementos
                    pointerEvents: "none", // Ignora interacciones del usuario
                  }}
                >
                  {product.type === "Workshop" ? (
                    <WorkspacePremiumIcon />
                  ) : (
                    <ShoppingCartIcon />
                  )}
                </Box>

                {/* Contenido del contenedor */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ zIndex: 1 }} // Asegura que el contenido esté encima del ícono
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {product.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="GrayText"
                    sx={{
                      background: "#D0749B",
                      color: "#FFF",
                      padding: "2px 8px",
                      borderRadius: "12px",
                      fontSize: "0.8rem",
                    }}
                  >
                    {product.type}
                  </Typography>
                </Box>

                {/* Descripción */}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ zIndex: 1 }}
                >
                  {product.description || "Sin descripción disponible."}
                </Typography>

                {/* Precio y moneda - Posicionado al final */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    marginTop: "auto",
                    zIndex: 1, // Asegura que el contenido esté encima del ícono
                  }}
                >
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{ fontWeight: 600 }}
                  >
                    {`$${product.price} ${product.currency}`}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => handleOpen(product)}
                  >
                    Ver detalles
                  </Button>
                </Box>
              </Box>
            ))
        ) : (
          <Typography variant="body2" color="GrayText">
            No hay productos disponibles.
          </Typography>
        )}
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            borderRadius: 8,
            boxShadow: 24,
            p: 4,
          }}
        >
          {selectedProduct && (
            <div>
              <TextField
                label="Nombre"
                name="name"
                value={selectedProduct.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Descripción"
                name="description"
                value={selectedProduct.description}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Precio"
                name="price"
                value={selectedProduct.price}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Moneda</InputLabel>
                <Select
                  label="Moneda"
                  name="currency"
                  value={selectedProduct.currency}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                >
                  {Currency.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tipo de Producto</InputLabel>
                <Select
                  label="Tipo de Producto"
                  name="type"
                  value={selectedProduct.type}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                >
                  {TypeProduct.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tipo de Descuento</InputLabel>
                <Select
                  label="Tipo de Descuento"
                  name="discountType"
                  value={selectedProduct.discountType}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                >
                  {DiscountType.map((discountType) => (
                    <MenuItem key={discountType} value={discountType}>
                      {discountType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Valor del Descuento"
                name="discountValue"
                value={selectedProduct.discountValue}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Button
                  onClick={handleDelete}
                  sx={{ color: "rgb(231, 68, 56)" }}
                >
                  Eliminar
                </Button>
                <Button onClick={handleApplyChanges}>Aplicar cambios</Button>
              </Box>
            </div>
          )}
        </Box>
      </Modal>

      <Dialog open={confirmOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar este producto?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Button onClick={handleCancelDelete} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProductList;
