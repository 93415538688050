import { create } from "zustand";
import { createSocketConnection } from "../../services/socket.service";

export const useSocketStore = create((set) => ({
  mainSocket: null,
  liveSocket: null,
  channelSocket: null,

  connectMainSocket: (token) => {
    const mainSocket = createSocketConnection(token, "");
    set({ mainSocket });

    mainSocket.on("connect", () => {
      console.log("Main socket conectado:", mainSocket.id);
    });

    mainSocket.on("disconnect", () => {
      console.log("Main socket desconectado OSO FANTASMA");
    });
  },

  connectLiveSocket: (token) => {
    const liveSocket = createSocketConnection(token, "/live");
    set({ liveSocket });

    liveSocket.on("connect", () => {
      console.log("Live socket conectado:", liveSocket.id);
    });

    liveSocket.on("disconnect", () => {
      console.log("Live socket desconectado");
    });
  },

  connectChannelSocket: (token) => {
    const channelSocket = createSocketConnection(token, "/channels");
    set({ channelSocket });

    channelSocket.on("connect", () => {
      console.log("Channel socket conectado:", channelSocket.id);
    });

    channelSocket.on("disconnect", () => {
      console.log("Channel socket desconectado DESDE SOCKET STORE");
    });
  },

  disconnectMainSocket: () => {
    set((state) => {
      state.mainSocket?.disconnect();
      return { mainSocket: null };
    });
  },

  disconnectLiveSocket: () => {
    set((state) => {
      state.liveSocket?.disconnect();
      return { liveSocket: null };
    });
  },

  disconnectChannelSocket: () => {
    set((state) => {
      state.channelSocket?.disconnect();
      return { channelSocket: null };
    });
  },

  disconnectAllSocket: () => {
    set((state) => {
      state.channelSocket?.disconnect();
      state.liveSocket?.disconnect();
      state.mainSocket?.disconnect();
      return { channelSocket: null };
    });
  },
}));
