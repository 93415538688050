import { Alert, Box, Button, IconButton, Input, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import FormularioBancario from '../../components/BankForm/BankFrom';
import CloseIcon from "@mui/icons-material/Close";
import { useAuthStore } from "../../store/zustand/authStore";
import { CartContext } from '../../store/context/CartContext';


const Settings = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState(false);
    const correctPassword = process.env.REACT_APP_SETTINGS_PASSWORD;
    const user = useAuthStore((state) => state.user);
    const { setTitleSeccion } = useContext(CartContext);
  
    useEffect(() => {
      setTitleSeccion("Datos Bancarios");
    }, []);

    const handlePasswordSubmit = () => {
        if (password === correctPassword) {
            setIsAuthenticated(true);
            setAlert(false)
        } else {
            setAlert(true)
            setPassword('');
        }
    };

    const handleChange = (e) => {
        setPassword(e.target.value);
    };

    return (
        <>
            {alert && <Alert
                severity="error"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setAlert(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
            >
                Clave incorrecta, por favor intenta nuevamente.
            </Alert>}

            {!isAuthenticated && user?.admin_users?.BANK?.WRITE[0] && (
                <Box style={{ padding: 20 }} sx={{ height: "230px", width: "250px", margin: "50px auto", borderWidth: 5, backgroundColor: "white" }} boxShadow={3} borderRadius={8} display={"flex"} flexDirection={"column"}>
                    <Typography textAlign={"center"} marginBottom={"5%"} color={"grey"}>Ingresá la clave para acceder a los datos bancarios:</Typography>
                    <Input
                        type="password"
                        placeholder="Clave"
                        value={password}
                        onChange={handleChange}
                        style={{ borderColor: 'gray', borderWidth: 1, marginBottom: 10, padding: 5 }}
                    />
                    <Button onClick={handlePasswordSubmit} variant="contained" color="primary">Acceder</Button>
                </Box>
            )}
            {isAuthenticated && <FormularioBancario />}
        </>
    );
};

export default Settings;