import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/zustand/authStore";
import AdminService from "../services/admin.service";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const login = useAuthStore((state) => state.login);
  const navigate = useNavigate();

  const handleLogin = async ({ email, password }) => {
    setLoading(true);
    setError(null);

    try {
      const { user, token } = await AdminService.signin({ email, password });
      login(user, token);
      navigate("/");
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { handleLogin, loading, error };
};
