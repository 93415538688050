import React, { useState } from "react";
import {
  Button,
  Chip,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";
import VoucherModal from "../../../common/Modals/VoucherModal";
import { useNavigate } from "react-router-dom";
import "./voucherTable.css";
import formatDate from "../../../common/Format/FormatDate";
import { useAuthStore } from "../../../store/zustand/authStore";

const VoucherTableBody = ({ data, updatePaymentStatus }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [paymentId, setPaymentId] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(undefined);
  const [loadingRows, setLoadingRows] = useState({});

  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);

  const handleOpenModal = (imageSrc, id, status) => {
    setSelectedImage(imageSrc);
    setPaymentId(id);
    setPaymentStatus(status);
    setOpenModal(true);
  };

  const handleOpenModalMercadopago = (data) => {
    console.log("handle open --->",data)
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setOpenModal(false);
  };

  const goToProfile = async (id) => {
    const rute = "/usuarios/" + id;
    navigate(rute);
  };

  const handleStatusChange = (paymentId, newStatus) => {
    setLoadingRows((prev) => ({ ...prev, [paymentId]: true }));
    updatePaymentStatus({ paymentId, newStatus });
    setTimeout(() => {
      setLoadingRows((prev) => ({ ...prev, [paymentId]: false }));
    }, 1000);
  };

  return (
    <TableBody>
      {data?.map((row, index) => {
        const isLoading = loadingRows[row.id];

        return (
          <TableRow key={index}>
            <TableCell
              onClick={() => goToProfile(row?.orders?.user?.id)}
              className="rowhover"
            >
              <Typography color="text.primary" fontWeight="400">
                {row?.orders?.user?.name} {row?.orders?.user?.lastname}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="text.secondary" fontWeight="400">
                {row?.orders?.user?.email}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="text.secondary" fontWeight="400">
                {row?.orders?.user?.dni}
              </Typography>
            </TableCell>

            <TableCell>
              <Typography color="text.secondary" fontWeight="400">
                {formatDate(row?.orders?.createdAt)}
              </Typography>
            </TableCell>

            <TableCell>
              <Typography color="text.secondary" fontWeight="400">
                {row?.method}
              </Typography>
            </TableCell>

            <TableCell>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Chip
                  label={
                    row?.status === "approved"
                      ? "Confirmado"
                      : row?.status === "rejected"
                      ? "Rechazado"
                      : "Pendiente"
                  }
                  sx={{
                    bgcolor:
                      row?.status === "approved"
                        ? "#a9dda9"
                        : row?.status === "rejected"
                        ? "#dea4a7"
                        : "#9ec9e3",
                    color: "#333",
                  }}
                />
              )}
            </TableCell>

            <TableCell>
              {
              row?.method !== "Transfer" ||
              !user?.admin_users?.PAYMENTS?.WRITE[0] ? (
                ""
              ) :  (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ color: "white" }}
                  onClick={() =>
                    handleOpenModal(row?.voucherLink, row?.id, row?.status)
                  }
                >
                  Ver
                </Button>
              )}
               {/* {
              row?.method !== "MercadoPago" ||
              !user?.admin_users?.PAYMENTS?.WRITE[0] ? (
                ""
              ) :  (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ color: "white" }}
                  onClick={() =>
                    handleOpenModalMercadopago(row)
                  }
                >
                  Ver
                </Button>
              )} */}
            </TableCell>
          </TableRow>
        );
      })}
      <VoucherModal
        open={openModal}
        onClose={handleCloseModal}
        imageSrc={selectedImage}
        paymentId={paymentId}
        status={paymentStatus}
        updatePaymentStatus={handleStatusChange}
      />
    </TableBody>
  );
};

export { VoucherTableBody };
