import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../../store/context/CartContext";
import {
  Box,
  Button,
  Modal,
  Fade,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import ProductForm from "./ProductForm";
import ProductList from "./ProductList";
import useProducts from "../../hooks/useProducts";
import CuponForm from "./CuponForm";
import CuponList from "./CuponList";
import PackCards from "./Packs/PackCards";
import { useAuthStore } from "../../store/zustand/authStore";

export default function CreateProduct() {
  const { setTitleSeccion } = useContext(CartContext);
  const user = useAuthStore((state) => state.user);

  const {
    productButtonSelected,
    setProductButtonSelected,
    cuponButtonSelected,
    setCuponButtonSelected,
    formDataProduct,
    handleChangeProduct,
    handleSwitchChange,
    handleSubmitProduct,
    workshops,
    products,
    formDataCupon,
    handleChangeCupon,
    handleSubmitCupon,
    cupons,
    loading,
    toastOpen,
    toastMessage,
    toastSeverity,
    showToast,
    setToastOpen,
    reloadData,
  } = useProducts();

  const handleToastClose = () => {
    setToastOpen(false);
  };

  useEffect(() => {
    setTitleSeccion("Paquetes y Productos");
  }, []);

  const mainButtons = [
    {
      id: 0,
      title: "CREAR PRODUCTO",
      onClick: () => {
        setProductButtonSelected(true);
        setCuponButtonSelected(false);
      },
      selected: productButtonSelected,
    },
    {
      id: 1,
      title: "CREAR CUPÓN",
      onClick: () => {
        setCuponButtonSelected(true);
        setProductButtonSelected(false);
      },
      selected: cuponButtonSelected,
    },
  ];

  const TypeProduct = ["Workshop", "Merch"];
  const Currency = ["ARS", "USD"];
  const DiscountType = ["percentage", "fixed"];

  const [openModal, setOpenModal] = useState(false);
  const [filter, setFilter] = useState("pack"); // Opciones: "products", "cupons"

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <Box
      sx={{
        height: "90vh",
        flexDirection: "column",
        alignItems: "start",
        marginLeft: 1,
      }}
      display={"flex"}
    >
      <Backdrop
        sx={{ color: "#D0749B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {filter !== "pack" && user?.admin_users?.PRODUCTS?.WRITE?.[0] && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          sx={{
            position: "fixed",
            right: "25px",
            bottom: "25px",
            zIndex: "999",
          }}
        >
          Abrir Formulario
        </Button>
      )}


      {/* Modal */}
      <Modal
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              maxWidth: 900,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: 2,
              p: 4,
            }}
          >
            <Box
              display={"flex"}
              sx={{
                justifyContent: "space-evenly",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              {mainButtons.map((button) => (
                <Button
                  key={button.title}
                  sx={{ width: "45%" }}
                  variant={button.selected ? "contained" : "outlined"}
                  onClick={button.onClick}
                  color={"primary"}
                  style={button.selected ? { color: "white" } : {}}
                >
                  {button.title}
                </Button>
              ))}
            </Box>
            <Box>
              {productButtonSelected ? (
                <ProductForm
                  workshops={workshops}
                  Currency={Currency}
                  TypeProduct={TypeProduct}
                  DiscountType={DiscountType}
                  formData={formDataProduct}
                  handleSubmit={handleSubmitProduct}
                  handleChange={handleChangeProduct}
                  handleSwitchChange={handleSwitchChange}
                  handleClose={handleClose}
                  showToast={showToast}
                />
              ) : (
                <CuponForm
                  formDataCupon={formDataCupon}
                  handleChangeCupon={handleChangeCupon}
                  handleSubmitCupon={handleSubmitCupon}
                  handleClose={handleClose}
                  showToast={showToast}
                />
              )}
            </Box>
          </Box>
        </Fade>
      </Modal>

      {/* Botones de filtro */}
      {/* <Box
        display={"flex"}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          marginTop: 1,
        }}
      >
        <Button
          variant={filter === "pack" ? "contained" : "outlined"}
          onClick={() => setFilter("pack")}
        >
          Paquetes
        </Button>
        <Button
          variant={filter === "products" ? "contained" : "outlined"}
          onClick={() => setFilter("products")}
        >
          Productos
        </Button>
        <Button
          variant={filter === "cupons" ? "contained" : "outlined"}
          onClick={() => setFilter("cupons")}
        >
          Cupones
        </Button>
      </Box> */}

      {/* Listados de productos y cupones */}
      <Box
        sx={{
          marginTop: 3,
          width: "100%",
        }}
      >
        {filter === "all" || filter === "pack" ? (
          <PackCards workshops={workshops} user={user}/>
        ) : null}

        {filter === "all" || filter === "products" ? (
          <ProductList
            products={products}
            Currency={Currency}
            TypeProduct={TypeProduct}
            DiscountType={DiscountType}
            reloadData={reloadData}
            user={user}
          />
        ) : null}

        {filter === "all" || filter === "cupons" ? (
          <CuponList
            cupons={cupons}
            Currency={Currency}
            TypeProduct={TypeProduct}
            DiscountType={DiscountType}
            reloadData={reloadData}
            user={user}
          />
        ) : null}
      </Box>

      <Snackbar
        open={toastOpen}
        autoHideDuration={3000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleToastClose}
          severity={toastSeverity}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
