import { Box } from "@mui/material";
import BarChart from "../../components/BarChart/BarChart";
import PieChart from "../../components/PieChart/PieChart";
import MetricsExample from "../../components/MetricsExample/MetricsExample";

function Statistics() {
  return (
    <Box
      sx={{
        margin: 2,
        width: "95%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mb: 3,
        }}
      >
        <BarChart />
        <PieChart />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <MetricsExample />
        <MetricsExample />
        <MetricsExample />
      </Box>
    </Box>
  );
}

export default Statistics;
