import { useContext, useEffect, useRef, useState } from "react";
import useChat from "../../hooks/useChat";
import { CartContext } from "../../store/context/CartContext";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ChannelsContainer = ({ channelName }) => {
  const {
    messages,
    sendMessage,
    loadOldMessages,
    deleteMessage,
    mutedUser,
    loading,
    error,
  } = useChat({
    channelId: channelName,
    role: true,
  });
  const { user } = useContext(CartContext);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null); // Referencia para el final de los mensajes
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);

  // Desplazar al fondo al enviar/recibir mensajes
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    loadOldMessages();
  }, [loadOldMessages]);

  // Escrollea al fondo cuando los mensajes cambian
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleCopyMessage = () => {
    navigator.clipboard.writeText(selectedMessage.message);
    handleMenuClose();
  };

  const handleSendMessage = () => {
    if (user && newMessage.trim() !== "") {
      const msg = {
        avatar: "",
        createdAt: new Date(Date.now()).toString(),
        message: newMessage,
        name: user.name,
        userId: user.id,
        deleted: false,
      };
      sendMessage(msg);
      setNewMessage("");
    }
  };

  const handleMuteUser = (duration) => {
    mutedUser(selectedMessage.userId, duration);
    handleMenuClose();
  };
  const handleDeleteMessage = () => {
    deleteMessage(selectedMessage);
    handleMenuClose();
  };

  const handleMenuOpen = (event, message) => {
    setAnchorEl(event.currentTarget);
    setSelectedMessage(message);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMessage(null);
  };

  return (
    <div>
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          height: 700,
          display: "flex",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <CardContent sx={{ flex: "1 1 auto", overflowY: "auto" }}>
          <Typography variant="h5" component="div" gutterBottom>
            Chat
          </Typography>
          <List sx={{ maxHeight: "90%", overflowY: "auto" }}>
            {messages.map((message, index) => (
              <ListItem
                key={index}
                sx={{
                  justifyContent:
                    user.id === message.userId ? "flex-end" : "flex-start",
                  textAlign: user.id === message.userId ? "right" : "left",
                  position: "relative",
                }}
              >
                {user.id !== message.userId && (
                  <IconButton
                    onClick={(event) => handleMenuOpen(event, message)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
                {user.id !== message.userId && (
                  <ListItemAvatar>
                    <Avatar>{message.name?.charAt(0)}</Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText
                  primary={message.name}
                  secondary={
                    message.deleted
                      ? "Mensaje eliminado por el administrador!"
                      : message.message
                  }
                  sx={{
                    maxWidth: "75%",
                    bgcolor:
                      user.id === message.userId ? "primary.light" : "grey.300",
                    p: 1,
                    borderRadius: 2,
                  }}
                />
                {user.id === message.userId && (
                  <>
                    <ListItemAvatar sx={{ ml: 2 }}>
                      <Avatar>{message.name?.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <IconButton
                      onClick={(event) => handleMenuOpen(event, message)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </>
                )}
              </ListItem>
            ))}
            <div ref={messagesEndRef} />{" "}
            {/* Referencia al final de los mensajes */}
          </List>
        </CardContent>
        <Box sx={{ display: "flex", p: 2 }}>
          <TextField
            label="Escribe un mensaje"
            variant="outlined"
            fullWidth
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSendMessage();
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            sx={{ ml: 1 }}
          >
            Enviar
          </Button>
        </Box>

        {/* Menú de opciones del mensaje */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleDeleteMessage}>Eliminar mensaje</MenuItem>
          <MenuItem onClick={() => handleMuteUser(60000)}>
            Mutear 1 minuto
          </MenuItem>
          <MenuItem onClick={() => handleMuteUser(3600000)}>
            Mutear 1 hora
          </MenuItem>
          <MenuItem onClick={() => handleMuteUser(86400000)}>
            Mutear 1 día
          </MenuItem>
          <MenuItem onClick={handleCopyMessage}>Copiar mensaje</MenuItem>
        </Menu>
      </Card>
    </div>
  );
};

export default ChannelsContainer;
