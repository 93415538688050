import { APIURL } from "../models/api-base";
import {
  apiUrl_user,
  apiUrl_user_id,
  apiUrl_user_singup,
  apiUrl_user_update,
  apiUrl_user_validate,
  apiUrl_user_delete_membership,
  apiUrl_user_add_membership,
} from "../models/api-urls";

import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  /**
   * Recibo cantidad de usuarios a mostrar y la pagina a la que pertencen
   * @param {number} page
   * @param {number} rowsPerPage
   * @returns
   */
  getAllUsers = async (page, rowsPerPage, data) => {
    const endpoint = `${apiUrl_user}/${rowsPerPage}/${page}`;
    const response = await this.post(endpoint, data);
    const { users } = response;
    return users;
  };

  getUserById = async (id) => {
    try {
      const response = await this.get(`${apiUrl_user_id}/test/${id}`);
      return response.user;
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Edito un Usuario
   * @param {string} id
   * @param {object} user
   * @returns
   */
  updateUser = async (id, user) => {
    const response = await this.put(`${apiUrl_user_update}/${id}`, user);
    return response;
  };

  /**
   * Creo un nuevo Usuario
   * @param {object} user
   * @returns
   */
  createUser = async (user) => {
    const response = await this.post(apiUrl_user_singup, user);
    return response;
  };

  validateUser = async () => {
    const response = await this.post(apiUrl_user_validate);
    return response;
  };


  giveMembership = async (id, memeshipID) => {
    try {
      const response = await this.put(`${apiUrl_user_add_membership}/${id}/${memeshipID}`)

      return response;
    } catch (error) {
      console.log(error)
    }
  }

  removeMembership = async (id, memeshipID) => {
    try {
      const response = await this.delete(`${apiUrl_user_delete_membership}/${id}/${memeshipID}`)
      return response;
    } catch (error) {
      console.log(error)
    }
  }


}

const UserService = new Service();

export default UserService;
