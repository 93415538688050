import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";

export const MasiveAddForm = ({ open, handleClose, handleCreate }) => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [recurringDays, setRecurringDays] = useState([]);
  const [quest, setQuest] = useState(false);

  const handleTitleChange = (event) => setTitle(event.target.value);
  const handleSubtitleChange = (event) => setSubtitle(event.target.value);

  const handleStartDateChange = (event) => {
    const date = event.target.value;
    setStartDate(date);
    setRecurringDays([]); // Clear existing recurring days
  };

  const handleEndDateChange = (event) => {
    const date = event.target.value;
    setEndDate(date);
    setRecurringDays([]); // Clear existing recurring days
  };

  const handleStartTimeChange = (event) => setStartTime(event.target.value);
  const handleEndTimeChange = (event) => setEndTime(event.target.value);
  const handleChangeQuest = (event) => setQuest(event.target.value);

  const handleCreateEvent = () => {
    if (
      !title ||
      !subtitle ||
      !startDate ||
      !endDate ||
      !startTime ||
      !endTime
    ) {
      console.log("Faltan campos requeridos.");
      return; // Prevent creating event without required fields
    }
    const events = [];
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    // Iterate over each day between startDate and endDate
    let currentDate = startMoment.clone();
    while (currentDate.isSameOrBefore(endMoment, "day")) {
      // Create event for the current day with selected start and end times
      const startDateTime = moment(
        currentDate.format("YYYY-MM-DD") + " " + startTime,
        "YYYY-MM-DD HH:mm"
      );
      const endDateTime = moment(
        currentDate.format("YYYY-MM-DD") + " " + endTime,
        "YYYY-MM-DD HH:mm"
      );

      events.push({
        title,
        subtitle, // Add subtitle to the event
        quest,
        start: startDateTime.toDate(),
        end: endDateTime.toDate(),
      });
      // Move to the next day
      currentDate.add(1, "day");
    }
    console.log("Eventos creados:", events);
    handleCreate(events); // Send events array to the parent component or handle logic
    handleClose(); // Close the modal after creating events
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      justifyContent={"center"}
      style={{ alignContent: "center" }}
    >
      <Box
        sx={{
          width: "50%",
          mx: "auto",
          backgroundColor: "white",
          alignContent: "center",
          height: "auto",
          p: 5,
        }}
      >
        <Typography variant="h6" component="h2" mt={3}>
          Crear Eventos Masivos
        </Typography>
        <TextField
          label="Título"
          value={title}
          onChange={handleTitleChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Subtítulo"
          value={subtitle}
          onChange={handleSubtitleChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Quest"
          type="checkbox"
          value={quest}
          onChange={handleChangeQuest}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Fecha Inicio"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Fecha Final"
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Hora de Inicio"
          value={startTime}
          onChange={handleStartTimeChange}
          type="time"
          InputLabelProps={{ shrink: true }}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Hora de Finalización"
          value={endTime}
          onChange={handleEndTimeChange}
          type="time"
          InputLabelProps={{ shrink: true }}
          margin="normal"
          fullWidth
        />

        <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
          <Button
            variant="contained"
            onClick={handleClose}
            style={{ margin: 5 }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateEvent}
            style={{ margin: 5 }}
          >
            Crear Eventos Masivos
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MasiveAddForm;
