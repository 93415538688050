import { APIURL } from "../models/api-base";
import { apiUrl_workshop } from "../models/api-urls";
import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  getAllWorkshops = async () => {
    try {
      const response = await this.get(apiUrl_workshop);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getWorkshopById = async (id) => {
    try {
      const response = await this.get(`${apiUrl_workshop}/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Creo un nuevo taller
   * @param {object} workshop
   * @returns
   */
  createWorkshop = async (workshop) => {
    try {
      const response = await this.post(apiUrl_workshop, workshop);
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Edito un taller
   * @param {object} workshop
   * @returns
   */
  updateWorkshop = async (id, workshop) => {
    try {
      const response = await this.put(`${apiUrl_workshop}/${id}`, workshop);
      // console.log(`${apiUrl_workshop}/${id}`, workshop);
      return response;
    } catch (error) {
      throw error;
    }
  };

  updateStatusWorkshop = async (key, id) => {
    try {
      if (key !== "pending" && key !== "approved" && key !== "rejected") {
        console.error("Parametro no existe");
        return false;
      } else {
        const response = await this.put(`${apiUrl_workshop}/${id}/${key}`);
        return response;
      }
    } catch (error) {
      throw error;
    }
  };

  DeleteWorkshopById = async (id) => {
    try {
      const response = await this.delete(`${apiUrl_workshop}/${id}`);
      console.log(`${apiUrl_workshop}/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  addLive = async (formData) => {
    try {
      const response = await fetch(
        APIURL + apiUrl_workshop + "/audiolive/addlive",
        {
          method: "POST",
          body: formData,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}

const WorkshopService = new Service();

export default WorkshopService;
