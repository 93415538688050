import React, { useState, useEffect } from "react";
import {
  Button,
  Chip,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import VoucherModal from "../../../common/Modals/VoucherModal";
import { useNavigate } from "react-router-dom";
import "./voucherTable.css";
import formatDate from "../../../common/Format/FormatDate";

const VoucherTableBody = ({ data, updatePaymentStatus }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [paymentId, setPaymentId] = useState(0);
  const navigate = useNavigate();

  const handleOpenModal = (imageSrc, id) => {
    setSelectedImage(imageSrc);
    setPaymentId(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setOpenModal(false);
  };

  const goToProfile = async (id) => {
    const rute = "/usuarios/" + id;
    navigate(rute);
  };

  return (
    <TableBody>
      {data?.map((row, index) => {
        return (
          <TableRow key={index}>
            <TableCell
              onClick={() => goToProfile(row?.user_fk.id)}
              className="rowhover"
            >
              <Typography color={"primary"} fontWeight={"700"}>
                {row?.user_fk?.name} {row?.user_fk?.lastname}
              </Typography>
            </TableCell>
            <TableCell>{row?.user_fk.email}</TableCell>
            <TableCell>{row?.user_fk.dni}</TableCell>
            <TableCell>{formatDate(row.createdAt)}</TableCell>
            <TableCell>{row.paymentMethod}</TableCell>
            <TableCell>
              {row.paymentStatus === "approved" ? (
                <Chip label="Confirmado" color="success" />
              ) : row.paymentStatus === "rejected" ? (
                <Chip label="Rechazado" color="error" />
              ) : row.paymentStatus === "pending" ? (
                <Chip label="Pendiente" color="info" />
              ) : (
                ""
              )}
            </TableCell>
            <TableCell>
              {row.paymentMethod !== "Transfer" ? (
                ""
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ color: "white" }}
                  onClick={() => handleOpenModal(row.voucherLink, row.id)}
                >
                  Ver
                </Button>
              )}
            </TableCell>
          </TableRow>
        );
      })}
      <VoucherModal
        open={openModal}
        onClose={handleCloseModal}
        imageSrc={selectedImage}
        paymentId={paymentId}
        updatePaymentStatus={updatePaymentStatus} // Pasa la función de actualización
      />
    </TableBody>
  );
};

export { VoucherTableBody };
