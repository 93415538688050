import React, { useState } from "react";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  CardActions,
  Snackbar,
  Alert,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import ApiService from "../../modules/APIService.interceptors";

const UserForm = ({ user, setUser, permission, id }) => {
  const [formData, setFormData] = useState(user);
  const [status, setStatus] = useState({
    editing: false,
    loading: false,
    showBtn: false,
    succes: false
  });
  const [fieldModified, setFieldModified] = useState({});
  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const fieldConfig = [
    { name: "name", label: "Nombre" },
    { name: "lastname", label: "Apellido" },
    { name: "email", label: "Email" },
    { name: "phone", label: "Teléfono" },
    { name: "user_location", label: "Dirección" },
    { name: "birth_date", label: "Fecha de nacimiento" },
    { name: "occupation", label: "Ocupacion" },
    { name: "instagram", label: "Instagram" },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Marcar el campo como modificado
    setFieldModified((prevModified) => ({
      ...prevModified,
      [name]: true,
    }));
  };

  const handleEdit = () => {
    setStatus({ editing: true, loading: false, showBtn: true });
  };

  const handleCancel = () => {
    setStatus({ editing: false, loading: false, showBtn: false });
    setFormData(user);
    setFieldModified({}); // Limpiar el estado de modificaciones
  };

  const handleConfirm = async () => {
    setStatus({ ...status, loading: true });
    const response = await updateUser();
    setStatus({ editing: false, loading: false, showBtn: false, succes: response });
  };


  const updateUser = async () => {
    if (Object.keys(formData).length > 0) {
      const response = await ApiService.updateUser(id, formData);
      if (response) {
        setUser(response);
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <Card component="form">
        <CardContent>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 3, md: 5 }}>
            {fieldConfig?.map((field) =>
              field.name === "birth_date" ? (
                <Grid item xs={6} sx={{ mt: 1 }} key={field.name}>
                  <TextField
                    fullWidth
                    name={field.name}
                    label={field.label}
                    type="date"
                    value={
                      fieldModified[field.name]
                        ? formData[field.name] || ""
                        : formatDate(user[field.name]) || ""
                    }
                    onChange={handleChange}
                    disabled={!status.editing}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        max: "2100-12-31",
                      },
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={6} sx={{ mt: 1 }} key={field.name}>
                  <TextField
                    fullWidth
                    name={field.name}
                    label={field.label}
                    value={
                      fieldModified[field.name]
                        ? formData[field.name] || ""
                        : user[field.name] || ""
                    }
                    onChange={handleChange}
                    disabled={!status.editing}
                  />
                </Grid>
              )
            )}
          </Grid>
          <CardActions sx={{ display: "flex", justifyContent: "end" }}>
            {!status.showBtn && permission?.admin_users?.USERS?.WRITE[0] && (
              <Button
                onClick={handleEdit}
                variant="contained"
                startIcon={<EditIcon />}
              >
                Editar
              </Button>
            )}
            {status.showBtn && (
              <>
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  disabled={status.loading}
                  startIcon={<CancelIcon />}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleConfirm}
                  startIcon={<CheckIcon />}
                  disabled={status.loading}
                  variant="contained"
                >
                  Confirmar
                </Button>
              </>
            )}
          </CardActions>
        </CardContent>
      </Card>


      {/* Snackbar con mensaje de éxito */}
      <Snackbar open={status.succes} autoHideDuration={3000} onClose={() => setStatus({ succes: false })}>
        <Alert onClose={() => setStatus({ succes: false })} severity="success" sx={{ width: "100%" }}>
          Usuario actualizado con éxito.
        </Alert>
      </Snackbar>
    </>

  );
};

export default UserForm;
