import React from "react";
import {
  Modal,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh", // Limitar la altura del modal al 80% de la altura de la pantalla
  overflowY: "auto", // Habilitar el desplazamiento vertical si es necesario
};

const formatDateTime = (isoDate: string) => {
  if (!isoDate) return "";
  const date = new Date(isoDate);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const CommentsModal = ({ open, setOpen, comments }) => {
  // 1. Parsear los mensajes para asegurarse de que sean objetos
  const parsedComments =
    comments?.LiveMessages?.map((comment) =>
      typeof comment === "string" ? JSON.parse(comment) : comment
    ) || [];

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <List
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "background.paper",
            overflowY: "auto",
          }}
          className="scrollable-list"
        >
          {parsedComments?.map((comment) => (
            <React.Fragment key={comment.id}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt={comment?.name} src={comment?.avatar} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Link
                      to={`/usuarios/${comment?.userId}`}
                      color="primary"
                      style={{ textDecoration: "none" }}
                    >
                      {`${comment?.name} ${
                        comment.lastname ? comment.lastname : ""
                      }`}
                    </Link>
                  }
                  secondary={
                    <>
                      <Typography
                        component="span"
                        sx={{
                          color: "text.primary",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {comment?.message}
                      </Typography>

                      <Typography>
                        {formatDateTime(comment?.createdAt)}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              {comments?.LiveMessages?.length > 1 && (
                <Divider variant="inset" component="li" />
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export default CommentsModal;


//Cartel de éxito de envío de notificación del live.
//Agregar link de Whatsapp cuando se haga click en el numero telefónico del usuario.