import React, { useState, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ApiService from "../../modules/APIService.interceptors";
import { CartContext } from "../../store/context/CartContext";

const CreateUser = () => {
  const { setTitleSeccion } = useContext(CartContext);

  useEffect(() => {
    setTitleSeccion("Crear Sirena");
  }, {});

  const initialUserData = {
    name: null,
    lastname: null,
    // username: null,
    dni: null,
    phone: null,
    birth_date: null,
    // height: 0,
    // location: "",
    email: "",
    password: null,
  };

  const booleanFields = ["admin", "superAdmin"];

  const nonBooleanFields = [
    "name",
    "lastname",
    // "username",
    "dni",
    "phone",
    // "height",
    // "location",
    "email",
    "password",
  ];

  const [userData, setUserData] = useState(initialUserData);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setUserData({ ...userData, [name]: fieldValue });
  };

  const handleSubmit = async () => {
    userData.phone = Number(userData.phone);
    userData.dni = Number(userData.dni);
    userData.birth_date = Date(userData.birth_date);
    const response = await ApiService.createUser(userData);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          {nonBooleanFields?.map((field) => (
            <Grid item xs={6} key={field}>
              <TextField
                name={field}
                label={field?.charAt(0).toUpperCase() + field?.slice(1)}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                type={field === "password" ? "password" : "text"}
              />
            </Grid>
          ))}
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="birth_date"
              label="Fecha de Nacimiento"
              type="date"
              value={userData.birth_date || ""}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: "2100-12-31",
                },
              }}
            />
          </Grid>
          {booleanFields?.map((field) => (
            <Grid item xs={6} key={field}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={field}
                    checked={userData[field]}
                    onChange={handleChange}
                  />
                }
                label={field?.charAt(0).toUpperCase() + field?.slice(1)}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Crear Administrador
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CreateUser;
