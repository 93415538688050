import React from "react";
import { Box, Typography } from "@mui/material";

const NotAllowed = ({ user, page }) => {
    if (user?.admin_users?.[page]?.READ[0]) return null; // Si no hay usuario, no muestra nada

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                backgroundColor: "#f9f9f9",
                textAlign: "center",
                padding: 4,
            }}
        >
            <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: 2 }}>
                Acceso denegado
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary", marginBottom: 4 }}>
                No tienes los permisos necesarios para acceder a esta página.
            </Typography>
        </Box>
    );
};

export default NotAllowed;
