import React, { useState } from "react";
import {
  Avatar,
  Box,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { formatLinkInstagram } from "../../../utils/formatLinkInstagram";

const UserTableBody = ({ data }) => {
  const navigate = useNavigate();

  const goToProfile = (id) => {
    const rute = `/usuarios/${id}`; // La ruta debe estar bien definida
    navigate(rute);
  };

  return (
    <TableBody>
      {data?.map((row, index) => {
        return (
          <TableRow key={index}>
            {/* Aquí define las celdas del cuerpo */}
            <TableCell>
              <Box
                sx={{
                  width: 50, // Tamaño del círculo
                  height: 50,
                  borderRadius: "50%", // Hace que sea un círculo
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Avatar
                  src={row?.avatar} // Ruta de la imagen del usuario
                  alt={row?.name} // Texto alternativo
                  sx={{ width: "100%", height: "100%" }} // Ajusta al tamaño del contenedor
                />
              </Box>
            </TableCell>

            <TableCell
              onClick={() => goToProfile(row?.id)}
              className="rowhover"
            >
              <Typography color="text.primary" fontWeight="400">
                {row?.fullName}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="text.secondary" fontWeight="400">
                {row?.email}
              </Typography>
            </TableCell>

            <TableCell>
              {row.instagram ? (
                <a
                  href={formatLinkInstagram(row.instagram)}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#D0749B",
                    fontWeight: "bold",
                  }}
                >
                  <Typography
                    color="text.secondary"
                    fontWeight="400"
                    textDecoration
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.textDecoration = "underline")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.textDecoration = "none")
                    }
                  >
                    {row.instagram.startsWith("@")
                      ? row.instagram.slice(1)
                      : row.instagram}
                  </Typography>
                </a>
              ) : (
                "N/A"
              )}
            </TableCell>

            <TableCell>
              <Typography
                color="text.secondary"
                fontWeight="400"
                component="a"
                href={`https://web.whatsapp.com/send?phone=${row?.phone}&app_absent=0`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#D0749B",
                }} // Color de WhatsApp
                onMouseEnter={(e) =>
                  (e.currentTarget.style.textDecoration = "underline")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.textDecoration = "none")
                }
              >
                {row?.phone}
              </Typography>
            </TableCell>

            <TableCell>
              <Typography color="text.secondary" fontWeight="400">
                {row?.dni}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="text.secondary" fontWeight="400">
                {row?.user_location}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="text.secondary" fontWeight="400">
                {row?.membership?.length > 0 ? (
                  <CheckIcon sx={{ color: "#28b06d" }} />
                ) : (
                  <CloseIcon sx={{ color: "#d96a70" }} />
                )}
              </Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export { UserTableBody };
