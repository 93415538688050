import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import URLBACKGROUND from "../../assets/logo.jpg";
import ellipse from "../../assets/Vector7.png";
import vector from "../../assets/Vector6.png";
import vectorCentral from "../../assets/Vector141.png";
import { useLogin } from "../../hooks/useLogin";
import { Backdrop, CircularProgress } from "@mui/material";

export default function SignIn() {
  const { handleLogin, loading, error } = useLogin();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let loginUser = {
      email: data.get("email"),
      password: data.get("password"),
    };

    // Validaciones básicas
    if (!loginUser.email || !loginUser.password) {
      alert("Por favor, completa todos los campos");
      return;
    }

    handleLogin(loginUser);
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${URLBACKGROUND})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: "white",
          backgroundSize: "contain",
          backgroundPosition: "center",
          position: "relative",
          zIndex: 3,
        }}
      >
        <img
          src={ellipse}
          alt="ellipse"
          style={{
            width: "50%",
            height: "auto",
            objectFit: "contain",
            top: 0,
            right: 0,
            position: "absolute",
          }}
        />

        <img
          src={vector}
          alt="vector"
          style={{
            width: "19%",
            height: "auto",
            objectFit: "contain",
            bottom: 0,
            left: 0,
            position: "absolute",
          }}
        />

        <img
          src={vectorCentral}
          alt="ellipse"
          style={{
            width: "50%",
            height: "auto",
            objectFit: "contain",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            zIndex: 0,
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        zIndex={5}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar Sesion
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo Electronico"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recordar Usuario"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Iniciar Sesion
            </Button>
            <Grid container>
              <Grid item xs>
                {error && <p style={{ color: "red" }}>{error}</p>} {/* Error */}
                {/* <Link href="#" variant="body2">
                  Olvidaste tu contraseña?
                </Link> */}
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {""}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
