import { APIURL } from "../models/api-base";
import {
  apiUrl_payment_cancel_tranfer,
  apiUrl_payment_confirm_tranfer,
  apiUrl_payment_external_mp,
  apiUrl_payment_tranfer,
  apiUrl_payment_workshop,
} from "../models/api-urls";
import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  /**
   * Confimo Pago de transferencia
   * @param {number} idPayment
   * @returns
   */
  confirmPaymentToTransfer = async (idPayment) => {
    const response = await this.get(apiUrl_payment_confirm_tranfer + idPayment);
    return response;
  };

  /**
   * Rechazo pago de transferencia
   * @param {number} idPayment
   * @returns
   */
  cancelPaymentToTransfer = async (idPayment) => {
    const response = await this.get(apiUrl_payment_cancel_tranfer + idPayment);
    return response;
  };

  /**
   * Traer los pagos por transferencias
   * @param {string} key pending,approved,rejected
   * @returns { array | boolean }
   */
  getAllPaymentsTransfer = async (key) => {
    let response = false;
    if (key !== "pending" && key !== "approved" && key !== "rejected") {
      console.error("Parametro no existe");
    } else {
      response = await this.get(apiUrl_payment_tranfer + key);
    }
    return response;
  };

  getPaymentByWorkshopId = async (idWorkshop) => {
    const response = await this.get(apiUrl_payment_workshop + idWorkshop);
    return response;
  };

  async getExternalInfoMpPayment(operationNumber) {
    try {
      console.info("operationNumber--->",operationNumber)
      const res = await this.get(apiUrl_payment_external_mp + operationNumber);
      return res
    } catch (e) {
      console.log(e);
      throw new Error(e);
    }
  }
}

const PaymentsService = new Service();

export default PaymentsService;
