import React from "react";
import {
  TableCell,
  TableHead as TableHeadMUI,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";

const TableHead = ({ headers, order, orderBy, onRequestSort }) => {
  const handleSort = (property) => {
    onRequestSort(property);
  };

  return (
    <TableHeadMUI>
      <TableRow>
        {headers?.map((title) => {
          return (
            <TableCell sx={{ backgroundColor: "#D0749B" }}>
              <TableSortLabel
                active={orderBy === title}
                direction={orderBy === title ? order : "asc"}
                onClick={() => handleSort(title.label)}
              >
                <Typography
                  style={{ color: "whitesmoke" }}
                  fontWeight="400"
                  fontSize={20}
                >
                  {title.label}
                </Typography>
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHeadMUI>
  );
};

export { TableHead };
