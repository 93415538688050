import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, FormControlLabel, Switch } from "@mui/material";
import CardProfileImage from "../../components/CardProfileImage/CardProfileImage";
import CardProfileForm from "../../components/CardProfileForm/CardProfileForm";
import CardPaymentsUser from "../../components/CardPaymentsUser/CardPaymentsUser";
import ApiService from "../../modules/APIService.interceptors";
import { CartContext } from "../../store/context/CartContext";
import "./profileUser.css";
import UserService from "../../services/user.service";
import UserWeightTable from "../../components/UserWeightTable/UserWeightTable";
import NotAllowed from "../NotAllowed/NotAllowed";
import { useAuthStore } from "../../store/zustand/authStore";

const ProfileUser = () => {
  const { userId } = useParams();
  const [user, setUser] = useState({});
  const { setTitleSeccion } = useContext(CartContext);
  const [userPayments, setUserPayments] = useState([]);
  const [weight, setWeight] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [state, setState] = useState();
  const userPermission = useAuthStore((state) => state.user);
  const [isPremium, setIsPremium] = useState(user?.membershipId);

  useEffect(() => {
    if (user?.membershipId !== undefined) {
      setIsPremium(!!user.membershipId);
    }
  }, [user]);


  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await UserService.getUserById(userId);
        if (response) {
          setUser(response.user);
          setIsAdmin(response.user.admin)
          setWeight(response)
        } else {
          console.log("Usuario no encontrado");
        }
      } catch (error) {
        console.log("Error al seleccionar usuario");
      }
    };

    const getUserPayments = async () => {
      try {
        const response = await ApiService.userPayments(userId);
        if (response) {
          setUserPayments(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getUserPayments();
    getUserData();
    setTitleSeccion("Perfil de Usuario");
  }, [userId]);

  const editUser = async (admin) => {
    try {
      const response = await ApiService.updateStatusUser(userId, admin);
      if (!response.error) {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = async (event) => {
    const newStatus = event.target.checked;
    await editUser(newStatus); // Llamamos a la función editUser pasándole el nuevo estado
  };

  const handleSwitchChange = async () => {
    // Cambia el estado local de forma inmediata
    setIsPremium((prev) => !prev);

    try {
      if (isPremium) {
        await UserService.removeMembership(userId, user.membershipId);
      } else {
        await await UserService.giveMembership(userId, 5);
      }
    } catch (error) {
      console.error("Error al cambiar la membresía:", error);
      // Revertir el cambio local si la acción falla
      setIsPremium((prev) => !prev);
    }
  };


  return (
    <>
      <NotAllowed user={userPermission} page={"USERS"} />

      <Box className="user__box">
        <Box className="user__box__container">
          <Box className="user__box__container__body">

            <Box display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <CardProfileImage user={user} />

              <Box marginY={3}>
                <FormControlLabel
                  control={
                    <Switch checked={isPremium} onChange={handleSwitchChange} />
                  }
                  label={isPremium ? "Cuenta Premium Activada" : "Activar Premium"}
                />
              </Box>
            </Box>

            <Box>
              <CardProfileForm id={userId} user={user} setUser={setUser} admin={isAdmin} setAdmin={setIsAdmin} handleToggle={handleToggle} permission={userPermission} />
              <br />
              <UserWeightTable data={weight?.weight} />
              <br />
              <CardPaymentsUser userPaymemts={userPayments} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProfileUser;
