import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useContext, useState } from "react";
import { CartContext } from "../../store/context/CartContext";
import { Avatar, Box, Menu, MenuItem, Tooltip } from "@mui/material";
import styled from "@emotion/styled";


const drawerWidth = 240;

const settings = ["Cerrar sesión"];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const NavBar = React.memo(({ open, toggleDrawer, isMobile }) => {
  const { user, closeSesion } = useContext(CartContext);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (event, setting) => {
    setAnchorElUser(null);

    switch (setting) {
      case "Cerrar sesión":
        closeSesion();
        break;

      default:
        break;
    }
  };

  const { titleSeccion } = useContext(CartContext);
  return (
    <AppBar position="absolute" open={open} sx={{ position: 'fixed' }} >
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        {!isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          {titleSeccion}
        </Typography>

        <Typography>Hola {user?.name}!</Typography>

        <Box sx={{ flexGrow: 0, marginLeft: "2%" }}>
          <Tooltip title="Abrir opciones">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt={user ? user.name : ""}
                src={user ? user.avatar : ""}
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px", backgroundColor: "rgba(118, 118, 118, 0.1)" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings?.map((setting) => (
              <MenuItem
                key={setting}
                onClick={(event) => handleCloseUserMenu(event, setting)}
                sx={{ backgroundColor: "rgba(118, 118, 118, 0.1)" }}
              >
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
});

export default NavBar;
