import React, { useState } from "react";
import { Tabs, Tab, Typography, Box, Paper } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";

const HeadersUsers = ({ tab, setTab }) => {
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
    >
      <Box display="flex" alignItems="center" p={2}>
        <PeopleIcon fontSize="medium" color="secondary" />
        <Typography variant="h5" ml={1} color={"#767676"}>
          Sirenas
        </Typography>
      </Box>
      <Box>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Usuarias" />
          <Tab label="Pagos" />
        </Tabs>
      </Box>
    </Box>
  );
};

export { HeadersUsers };
