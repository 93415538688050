import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Chip,
  OutlinedInput,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import WorkshopService from "../../services/workshop.service";
import ProfileService from "../../services/profile.service";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LiveService from "../../services/live.service";

const LivesCategories = {
  NUTRICION: "Nutrición",
  ESPECIAL_NUTRICION: "Especial de Nutrición",
  DUPLEX_SIRENAS: "Duplex sirena",
  DERMATOLOGIA: "Dermatología",
  MOTIVACIONAL_PAREJAS: "Motivacional Parejas",
  ENTRENAMIENTO: "Entrenamiento",
  ENDOCRINOLOGIA: "Endocrinología",
  NEUROLOGIA: "Nuerología",
  PSICOLOGIA: "Psicología",
  GINECOLOGIA: "Ginecología",
  MEDICINA_FUNCIONAL: "Medicina Funcional",
  CARDIOLOGIA: "Cardiología",
  PSIQUIATRIA: "Psiquiatría",
  MOTIVACIONALES: "Motivacionales",
  COCINA: "Vivos cocina",
  INVITADOS_ESPECIALES: "Invitados Especiales",
};

const Lives = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    title: "",
    thumbnail: null,
    video: null,
    category: "",
    workshop: "",
    date: "",
    profesionals: [],
  });
  const [workshopsList, setWorkshopsList] = useState([]);
  const [profesionals, setProfesionals] = useState([]);
  const [loading, setLoading] = useState(false); // Estado para manejar el loader

  useEffect(() => {
    const getWorkshops = async () => {
      try {
        const response = await WorkshopService.getAllWorkshops();
        if (!response.error) {
          setWorkshopsList(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getProfesionals = async () => {
      try {
        const response = await ProfileService.getProfiles();
        if (!response.error) {
          setProfesionals(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getWorkshops();
    getProfesionals();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleProfesionalChange = (event) => {
    const { value } = event.target;
    console.log("value-->")
    setFormData({
      ...formData,
      profesionals: value.includes("none") ? [] : value, // Si selecciona "Ninguno", limpiar el array
    });
  };


  const uploadLive = async () => {
    try {
      setLoading(true); // Activar loader
      const workshop = workshopsList.find(
        (workshop) => workshop.id === formData.workshop
      );
      const data = new FormData();
      data.append("image", formData.thumbnail);
      data.append("video", formData.video);
      data.append("date", formData.date);
      data.append("workshopId", formData.workshop);
      data.append("workshopNumber", workshop ? workshop.workshopNumber : null);
      data.append("title", formData.title);
      data.append("category", formData.category);
      data.append(
        "profesionals",
        formData.profesionals.length > 0 ? formData.profesionals : null
      );
      const response = await LiveService.upload(data);
      if (response) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Desactivar loader
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar si se han subido la imagen y el video
    if (!formData.thumbnail) {
      alert("Debes subir una imagen de portada.");
      return;
    }

    if (!formData.video) {
      alert("Debes subir un video.");
      return;
    }

    uploadLive();
  };


  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} sm={8} md={6}>
          <Paper elevation={3} style={{ padding: "3% 3%", borderRadius: 12 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4" component="h1" gutterBottom>
                Nuevo Vivo
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon color="primary" />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Título"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="thumbnail-upload"
                    type="file"
                    name="thumbnail"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="thumbnail-upload">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<CloudUpload />}
                    >
                      Subir Portada
                    </Button>
                  </label>
                  {formData.thumbnail && (
                    <Typography variant="body2">
                      {formData.thumbnail.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <input
                    accept="video/*"
                    style={{ display: "none" }}
                    id="video-upload"
                    type="file"
                    name="video"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="video-upload">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<CloudUpload />}
                    >
                      Subir Video
                    </Button>
                  </label>
                  {formData.video && (
                    <Typography variant="body2">
                      {formData.video.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel id="category-label">Categoría</InputLabel>
                    <Select
                      labelId="category-label"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Selecciona una categoría
                      </MenuItem>
                      {Object.entries(LivesCategories).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="profesionals-label">Profesional</InputLabel>
                    <Select
                      labelId="profesionals-label"
                      name="profesionals"
                      value={formData.profesionals} // ✅ Asegurar que sea un solo valor
                      onChange={handleChange} // ✅ Usa el mismo manejador de otros campos
                    >
                      <MenuItem value="">Ninguno</MenuItem>
                      {profesionals.map((profesional) => (
                        <MenuItem key={profesional.id} value={profesional.id}>
                          {profesional.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>


                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Taller</InputLabel>
                    <Select
                      name="workshop"
                      value={formData.workshop}
                      onChange={handleChange}
                    >
                      <MenuItem value="">Ninguno</MenuItem>
                      {workshopsList
                        .slice() // 🔹 Copia el array para evitar mutarlo directamente
                        .sort((a, b) => a.workshopNumber - b.workshopNumber) // 🔹 Ordenar de menor a mayor
                        .map((workshop) => (
                          <MenuItem key={workshop.id} value={workshop.id}>
                            Taller número {workshop?.workshopNumber}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Fecha"
                    name="date"
                    type="date"
                    value={formData.date}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Lives;
