import { APIURL } from "../models/api-base";
import {
  apiURL_admin_by_id,
  apiURL_admin_create,
  apiURL_admin_getAll,
  apiURL_admin_update,
  apiURL_admin_login,
  apiURL_admin_delete,
  apiURL_admin_update_user,
} from "../models/api-urls";
import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  signin = async (loginUser) => {
    try {
      const response = await this.post(apiURL_admin_login, loginUser);
      return { user: response.admin, token: response.token };
    } catch (error) {
      throw new Error(error.response?.data?.message || "Login failed");
    }
  };

  getById = async (id) => {
    try {
      const response = await this.get(`${apiURL_admin_by_id}/${id}`);
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "get by id failed");
    }
  };

  getAll = async () => {
    try {
      const response = await this.get(apiURL_admin_getAll);
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "get all failed");
    }
  };

  create = async (admin) => {
    try {
      const response = await this.post(apiURL_admin_create, { admin });
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Create failed");
    }
  };

  update = async (id, permissions) => {
    try {
      const endpoint = `${apiURL_admin_update}/${id}`;
      const response = await this.put(endpoint, {
        manageUserPermissions: permissions,
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "update failed");
    }
  };

  updateUser = async (id, user) => {
    try {
      const endpoint = `${apiURL_admin_update_user}/${id}`;
      const response = await this.put(endpoint, {
        user,
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "update user failed");
    }
  };

  deleteAdmin = async (id) => {
    try {
      const endpoint = `${apiURL_admin_delete}/${id}`;
      const response = await this.delete(endpoint);
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "deleted failed");
    }
  };
}

const AdminService = new Service();

export default AdminService;
