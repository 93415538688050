import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  CircularProgress,
} from "@mui/material";
import ApiService from "../../modules/APIService.interceptors";

const VoucherModal = ({
  open,
  onClose,
  imageSrc,
  paymentId,
  status,
  updatePaymentStatus,
}) => {
  const [loading, setLoading] = useState(false);

  const handleAction = async (action) => {
    setLoading(true);
    try {
      const response =
        action === "approve"
          ? await ApiService.confirmPaymentToTransfer(paymentId)
          : await ApiService.cancelPaymentToTransfer(paymentId);

      if (response) {
        updatePaymentStatus(
          paymentId,
          action === "approve" ? "approved" : "rejected"
        );
      }
    } catch (error) {
      console.error("Error updating payment status", error);
    }
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <DialogContentText>
          <img
            src={imageSrc}
            alt="Imagen"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {(status === "pending" || status === "rejected") && (
          <Button
            onClick={() => handleAction("approve")}
            color="success"
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "Aceptar"}
          </Button>
        )}
        {(status === "pending" || status === "approved") && (
          <Button
            onClick={() => handleAction("reject")}
            color="error"
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "Rechazar"}
          </Button>
        )}
        <Button onClick={onClose} color="info" disabled={loading}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VoucherModal;
