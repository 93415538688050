import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import Search from "../../common/Search/Search";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../modules/APIService.interceptors";

const CardWorkshopUsers = (height, width) => {
  const [users, setUsers] = useState([]);
  const { workshopId } = useParams();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await ApiService.getUsersWorkshop(workshopId);
        if (!response.error) {
          setUsers(response.data[0]);
          setFilteredUsers(response.data[0]); // Inicialmente muestra todos los usuarios
        } else {
          setUsers([]);
          setFilteredUsers([]);
          console.error("error");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
      }
    };
    getUsers();
  }, [workshopId]);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchText(value);

    if (value.trim() === "") {
      // Si no hay texto de búsqueda, mostrar todos los usuarios
      setFilteredUsers(users);
    } else {
      // Filtrar usuarios según el texto de búsqueda
      const lowercasedFilter = value.toLowerCase();
      const filtered = users.filter(
        (user) =>
          user?.name.toLowerCase().includes(lowercasedFilter) ||
          user?.lastname.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredUsers(filtered);
    }
  };

  return (
    <Card sx={{ width: width, height: height }}>
      <CardContent>
        <Typography>Lista de Sirenas</Typography>
        <Divider />

        <ListSubheader
          sx={{
            fontWeight: "bold",
            bgcolor: "background.paper",
            paddingY: "2%",
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={1}>
              <Typography variant="body2" fontWeight="bold"></Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" fontWeight="bold">
                Nombre y Apellido
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" fontWeight="bold">
                DNI
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" fontWeight="bold">
                Email
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" fontWeight="bold">
                Dirección
              </Typography>
            </Grid>
          </Grid>
        </ListSubheader>

        <Search
          setSearchText={setSearchText}
          searchText={searchText}
          handleSearchChange={handleSearchChange}
          filter={"nombre"}
        />

        {/* Mostrar Loader si está cargando */}
        {loader ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={230}
          >
            <CircularProgress />
          </Box>
        ) : (
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              height: 500,
              overflow: "auto",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
              mt: 2,
            }}
          >
            {filteredUsers?.map((user, index) => (
              <Fragment key={index}>
                <ListItem>
                  <Grid container alignItems="center">
                    <Grid item xs={1}>
                      <ListItemAvatar>
                        <Avatar src={user?.avatar} />
                      </ListItemAvatar>
                    </Grid>
                    <Grid item xs={3}>
                      <ListItemText
                        onClick={() => navigate(`/usuarios/${user?.id}`)}
                        primary={
                          <Typography
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                textDecoration: "underline",
                                color: "grey",
                              },
                            }}
                          >
                            {user?.name} {user?.lastname}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        sx={{ color: "gray", fontSize: 14, fontWeight: 200 }}
                      >
                        {user?.dni}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        sx={{ color: "gray", fontSize: 14, fontWeight: 200 }}
                      >
                        {user?.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        sx={{ color: "gray", fontSize: 14, fontWeight: 200 }}
                      >
                        {user?.user_location}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider component="li" />
              </Fragment>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default CardWorkshopUsers;
