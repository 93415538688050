import React, { useState } from "react";
import { Button, Chip, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import VoucherModal from "../../../common/Modals/VoucherModal"; // Importa el componente modal
import { useNavigate } from "react-router-dom";

const UserTableBody = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setSelectedImage(null);
    setOpenModal(false);
  };

  const goToProfile = async (id) => {
    const rute = "/usuarios/" + id;
    navigate(rute);
  };

  return (
    <TableBody>
      {data?.map((row, index) => {
        return (
          <TableRow key={index}>
            {/* Aquí define las celdas del cuerpo */}

            <TableCell onClick={() => goToProfile(row?.id)} className="rowhover" >
              <Typography color={"primary"} fontWeight={"700"}>{row?.fullName}</Typography>
            </TableCell>
            <TableCell>{row?.email}</TableCell>
            <TableCell>{row?.instagram}</TableCell>
            <TableCell>{row?.phone}</TableCell>
            <TableCell>{row?.dni}</TableCell>
            <TableCell>{row?.user_location}</TableCell>
            <TableCell>
              {row?.premium ? (
                <Chip label="Premium" color="primary" sx={{ color: "white" }} />
              ) : (
                <Chip label="No Premium" sx={{ color: "#767676" }} />
              )}
            </TableCell>
          </TableRow>
        );
      })}
      <VoucherModal
        open={openModal}
        onClose={handleCloseModal}
        imageSrc={selectedImage}
      />
    </TableBody>
  );
};

export { UserTableBody };
