import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { mainListItems, secondaryListItems } from './listItems';
import { CartContext } from '../../store/context/CartContext';

const drawerWidth = 240;


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            marginTop: '-1px',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(9),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const SideBar = ({ open, toggleDrawer }) => {
    const {user} = React.useContext(CartContext);

    return (
        <Drawer variant="permanent" open={open} sx={{ position: 'fixed', height: '100vh', zIndex: 100 }}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    px: [1],
                    backgroundColor: "rgba(118, 118, 118, 0.05)"
                }}
            >
                <IconButton onClick={toggleDrawer}>
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <List component="nav" sx={{
                backgroundColor: "rgba(118, 118, 118, 0.05)",
                height: "100vh"
            }}>
                {mainListItems(open, user)}
                {/* {secondaryListItems} */}
            </List>
        </Drawer>
    );
}

export default SideBar;