import { Navigate, Route, Routes as RoutesDom } from "react-router-dom";
import NotFound from "../../pages/NotFound/NotFound";
import Home from "../../pages/Home/Home";
import Users from "../../pages/Users/Users";
import ProfileUser from "../../pages/ProfileUser/ProfileUser";
import Workshops from "../../pages/Workshops/Workshops";
import Taller from "../../pages/Workshop/Workshop";
import CreateWorkshop from "../../pages/CreateWorkshop/CreateWorkshop";
import CreateUser from "../../pages/CreateUser/CreateUser";
import Statistics from "../../pages/Statistics/Statistics";
import BroadcastLive from "../../pages/BroadcastLive/BroadcastLive";
import Calendario from "../../pages/Calendar/Calendar";
import Recipes from "../../pages/Recipes/Recipes";
import Profesionals from "../../pages/Profesionals/Profesionals";
import Settings from "../../pages/Settings/Settings";
import Lives from "../../pages/Lives/Lives";
import Categories from "../../pages/Categories/Categories";
import Archives from "../../pages/Archives/Archives";

const Routes = () => {
  return (
    <RoutesDom>
      <Route path="/" element={<Home />} />
      <Route path="/usuarios" element={<Users />} />
      <Route path="/usuario" element={<CreateUser />} />
      <Route path="/usuarios/:userId" element={<ProfileUser />} />
      <Route path="/talleres" element={<Workshops />} />
      <Route path="/talleres/:workshopId" element={<Taller />} />
      <Route path="/taller" element={<CreateWorkshop />} />
      <Route path="/calendario" element={<Calendario />} />
      <Route path="/recetas" element={<Recipes />} />
      <Route path="/broadcast" element={<BroadcastLive />} />
      <Route path="/estadisticas" element={<Statistics />} />
      <Route path="/profesionales" element={<Profesionals />} />
      <Route path="/settings" element={<Settings />} />
      {/* <Route path="/lives" element={<Lives />} /> */}
      <Route path="/categories" element={<Categories />} />
      <Route path="/archives" element={<Archives />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </RoutesDom>
  );
};

export default Routes;
