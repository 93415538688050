import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

const RecipesCard = ({
  recipes,
  handleDelete,
  editMode,
  editId,
  handleSubmit,
  handleInputChange,
  formValues,
  handleEdit,
  onClose,
  handleFileChange,
}) => {
  return (
    <Grid container spacing={3} sx={{ marginTop: 2 }}>
      {recipes?.map((recipe) => (
        <Grid item key={recipe?.id} xs={12} sm={6} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image={recipe?.thumbnail}
              alt={recipe?.title}
            />
            <CardContent>
              {editMode && editId === recipe?.id ? (
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Titulo"
                    name="title"
                    fullWidth
                    value={formValues.title}
                    onChange={handleInputChange}
                    required
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Subtitulo"
                    name="subtitle"
                    fullWidth
                    value={formValues.subtitle}
                    onChange={handleInputChange}
                    sx={{ marginBottom: 2 }}
                  />

                  {/* Replace TextField with input type="file" */}
                  <input
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="image-upload">
                    <Button
                      variant="contained"
                      component="span"
                      sx={{ marginBottom: 2 }}
                    >
                      Cambiar Portada
                    </Button>
                  </label>

                  <TextField
                    label="Link de la publicacion"
                    name="link"
                    fullWidth
                    value={formValues.link}
                    onChange={handleInputChange}
                    required
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Descripcion"
                    name="description"
                    fullWidth
                    multiline
                    rows={4}
                    value={formValues.description}
                    onChange={handleInputChange}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Autor"
                    name="autor"
                    fullWidth
                    value={formValues.autor}
                    onChange={handleInputChange}
                    sx={{ marginBottom: 2 }}
                  />
                  <Button type="submit" variant="contained" color="primary">
                    Guardar Cambios
                  </Button>
                  <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                    sx={{ marginX: 3 }}
                  >
                    Cancelar
                  </Button>
                </form>
              ) : (
                <>
                  <Typography variant="h5" component="div" gutterBottom>
                    {recipe?.title}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {recipe?.subtitle}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {recipe?.description}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Autor: {recipe?.autor || "No especificado"}
                  </Typography>
                  <Box sx={{ marginVertical: 2 }}>
                    <IconButton
                      onClick={() => handleEdit(recipe?.id)}
                      aria-label="edit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(recipe?.id)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default RecipesCard;
