import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectSmall({
  status,
  setStatusWorkshop,
  isEditing,
  setStatusLabel,
}) {
  const handleChange = (event) => {
    setStatusWorkshop(event.target.value);
    if (event.target.value === "onHold") {
      setStatusLabel("Taller pronto a salir");
    } else if (event.target.value === "inProgress") {
      setStatusLabel("Taller en progreso");
    } else if (event.target.value === "onSale") {
      setStatusLabel("Taller en venta");
    } else if (event.target.value === "finalized") {
      setStatusLabel("Taller finalizado");
    }
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">Estado</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={status}
        label="Estado"
        onChange={handleChange}
        disabled={!isEditing}
      >
        <MenuItem value={"onHold"}>Proximamente</MenuItem>
        <MenuItem value={"inProgress"}>En Progreso</MenuItem>
        <MenuItem value={"onSale"}>En Venta</MenuItem>
        <MenuItem value={"finalized"}>Finalizado</MenuItem>
      </Select>
    </FormControl>
  );
}
