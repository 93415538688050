import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableContainer,
  Paper,
  CircularProgress,
  Box,
  Button,
  Modal,
} from "@mui/material";
import { TableHead } from "../../TableHead/TableHead";
import { VoucherTableBody } from "./VoucherTableBody";
import { VoucherTableFooter } from "./VoucherTableFooter";
import ApiService from "../../../modules/APIService.interceptors";
import SelectFilterDefault from "../../SelectFilterDefault/SelectFilterDefault";
import Search from "../../../common/Search/Search";
import { MercadoPagoApi } from "../../ExternalPaymentsStatus/mercadopago";

const VoucherTable = ({ handleUpdateKey }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [users, setUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [paymentFilter, setPaymentFilter] = useState("all");
  const [stateFilter, setStateFilter] = useState("all");
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Nombre Apellido");
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("name");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const headersFiltersSearch = [
    { label: "Nombre Apellido", value: "name" },
    { label: "Email", value: "email" },
    { label: "DNI", value: "dni" },
  ];

  const headersFilters = [
    { label: "Nombre Apellido", value: "name" },
    { label: "Email", value: "email" },
    { label: "DNI", value: "dni" },
    { label: "Fecha", value: "date" },
    { label: "Método", value: "method" },
    { label: "Estado", value: "state" },
    { label: "Comprobante", value: "comprobante" },
  ];
  const StatePaymentList = [
    { value: "all", text: "Todos" },
    { value: "approved", text: "Aprobados" },
    { value: "pending", text: "Pendientes" },
    { value: "rejected", text: "Rechazados" },
  ];

  const PaymentMethodList = [
    { value: "all", text: "Todos" },
    { value: "MercadoPago", text: "Mercado Pago" },
    { value: "PayPal", text: "Paypal" },
    { value: "Transfer", text: "Transferencia" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllUsersPayments(newPage);
  };

  // Manejar el cambio de ordenamiento
  const handleRequestSort = (property) => {
    const validProperties = ["Nombre Apellido", "Email", "DNI"];

    if (!validProperties.includes(property)) {
      return; // No hacer nada si la propiedad no es válida
    }

    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const getAllUsersPayments = async (newPage, searchText = "") => {
    try {
      setLoader(true);
      const response = await ApiService.allPayments(
        rowsPerPage,
        newPage,
        paymentFilter,
        stateFilter,
        filter,
        searchText
      );
      setUsers(response.data.payments);
      setTotalRows(response.data.total || totalRows);
    } catch (error) {
      console.error("ERROR TRAYENDO LOS PAGOS", error);
    }
    setLoader(false);
  };
  useEffect(() => {
    getAllUsersPayments(page);
  }, [paymentFilter, stateFilter]);

  const updatePaymentStatus = (updatedPayment) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === updatedPayment.paymentId
          ? { ...user, status: updatedPayment.newStatus }
          : user
      )
    );
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchText(value);
    setPage(0);
    getAllUsersPayments(0, value);
  };

  const sortedUsers = useMemo(() => {
    return users?.slice().sort((a, b) => {
      let aValue = "";
      let bValue = "";

      // Mapear columnas a las claves reales del objeto
      if (orderBy === "Nombre Apellido") {
        aValue = `${a.name} ${a.lastname}`.toLowerCase();
        bValue = `${b.name} ${b.lastname}`.toLowerCase();
      } else if (orderBy === "Email") {
        aValue = a.email?.toLowerCase() || "";
        bValue = b.email?.toLowerCase() || "";
      } else if (orderBy === "DNI") {
        aValue = a.dni?.toString() || ""; // Convertir números a cadenas
        bValue = b.dni?.toString() || "";
      }
      // Comparar valores para ordenar
      if (order === "asc") {
        return aValue.localeCompare(bValue);
      }
      return bValue.localeCompare(aValue);
    });
  }, [users, order, orderBy]);

  return (
    <TableContainer component={Paper} style={{ overflowX: "auto" }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <MercadoPagoApi />
      </Modal>

      <Box
        sx={{
          paddingRight: "2%",
          textAlign: "end",
          backgroundColor: "#D0749B",
          color: "white",
          fontWeight: "300",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box p={2} width="50%">
          <Search
            searchText={searchText}
            setSearchText={setSearchText}
            color={"#767676"}
            handleSearchChange={handleSearchChange}
            headersFilters={headersFiltersSearch}
            filter={filter}
            setFilter={setFilter}
          />
        </Box>
        <Box>
          <SelectFilterDefault
            filter={paymentFilter}
            setFilter={setPaymentFilter}
            title="Método de pago"
            list={PaymentMethodList}
          />
          <SelectFilterDefault
            filter={stateFilter}
            setFilter={setStateFilter}
            title="Estado de pagos"
            list={StatePaymentList}
          />
          <Button
            sx={{
              marginTop: 2,
              marginLeft: 3,
              marginBottom: 2,
              minWidth: 170,
              border: "1px solid black",
              color: "white",
            }}
            onClick={handleOpen}
          >
            Ver pagos externos
          </Button>
        </Box>
      </Box>

      {loader ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Table>
            <TableHead
              headers={headersFilters}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <VoucherTableBody
              data={sortedUsers}
              updatePaymentStatus={updatePaymentStatus}
            />
            <VoucherTableFooter
              rowsPerPage={rowsPerPage}
              page={page}
              totalRows={totalRows}
              handleChangePage={handleChangePage}
            />
          </Table>
        </>
      )}
    </TableContainer>
  );
};

export { VoucherTable };
