import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  Box,
  Grid,
  Divider,
  Container,
} from "@mui/material";
import AdminService from "../../services/admin.service";

// Enum de permisos disponibles
const UserAdminServicesEnum = {
  SADMIN: "SADMIN",
  USERS: "USERS",
  PAYMENTS: "PAYMENTS",
  WORKSHOPS: "WORKSHOPS",
  EVENTS: "EVENTS",
  QUESTS: "QUESTS",
  PROFESSIONALS: "PROFESSIONALS",
  LIVES: "LIVES",
  MOD: "MOD",
  BANK: "BANK",
};

const Permissions = () => {
  const { id } = useParams();
  const [admin, setAdmin] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [initialPermissions, setInitialPermissions] = useState([]);

  useEffect(() => {
    const fetchAdmin = async () => {
      const adminData = await AdminService.getById(id);
      const user = adminData[0];

      // Mapear permisos disponibles con permisos actuales del usuario
      const allPermissions = Object.entries(UserAdminServicesEnum).map(
        ([key, name]) => {
          const currentPermission = user.admin_users?.[name] || {
            READ: [],
            WRITE: [],
          };

          return {
            name,
            read: currentPermission.READ.length > 0,
            write: currentPermission.WRITE.length > 0,
            currentReadIds: currentPermission.READ.map(
              (item) => item?.admin_service_id || null
            ).filter((id) => id !== null),
            currentWriteIds: currentPermission.WRITE.map(
              (item) => item?.admin_service_id || null
            ).filter((id) => id !== null),
          };
        }
      );

      setAdmin(user);
      setPermissions(allPermissions);
      setInitialPermissions(JSON.parse(JSON.stringify(allPermissions))); // Clonar estado inicial
    };

    fetchAdmin();
  }, [id]);

  const handlePermissionChange = (index, type) => {
    const updatedPermissions = [...permissions];
    updatedPermissions[index][type] = !updatedPermissions[index][type];
    setPermissions(updatedPermissions);
  };

  const handleSave = async () => {
    const toDelete = { READ: [], WRITE: [] };
    const toCreate = [];

    // Comparar el estado inicial con el actual para determinar cambios
    permissions.forEach((perm, index) => {
      const initialPerm = initialPermissions[index];

      // Detectar permisos de lectura eliminados
      if (initialPerm.read && !perm.read) {
        toDelete.READ.push(...perm.currentReadIds);
      }

      // Detectar permisos de escritura eliminados
      if (initialPerm.write && !perm.write) {
        toDelete.WRITE.push(...perm.currentWriteIds);
      }

      // Detectar nuevos permisos de lectura
      if (!initialPerm.read && perm.read) {
        toCreate.push({
          service: {
            name: perm.name,
            id: perm.currentReadIds[0] || index + 1,
            method: "READ",
          },
        });
      }

      // Detectar nuevos permisos de escritura
      if (!initialPerm.write && perm.write) {
        toCreate.push({
          service: {
            name: perm.name,
            id: perm.currentWriteIds[0] || index + 1,
            method: "WRITE",
          },
        });
      }
    });

    const payload = { toDelete, toCreate };

    await AdminService.update(id, payload);
    alert("Permisos actualizados correctamente");
  };

  if (!admin) return <Typography align="center">Cargando...</Typography>;

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            {admin.name} {admin.lastname}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {admin.email}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Ocupación: {admin.occupation}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" sx={{ mb: 2 }}>
          Gestión de Permisos
        </Typography>
        <Grid container spacing={3}>
          {permissions.map((permission, index) => (
            <Grid item xs={12} sm={6} key={permission.name}>
              <Paper
                elevation={1}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  borderRadius: 2,
                  backgroundColor: "#f9f9f9",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  {permission.name}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permission.read}
                      onChange={() => handlePermissionChange(index, "read")}
                      color="primary"
                    />
                  }
                  label="Lectura"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permission.write}
                      onChange={() => handlePermissionChange(index, "write")}
                      color="secondary"
                    />
                  }
                  label="Escritura"
                />
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              paddingX: 4,
              borderRadius: 8,
              fontWeight: "bold",
            }}
            onClick={handleSave}
          >
            Guardar Cambios
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Permissions;
