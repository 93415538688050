import React, { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Checkbox,
    FormControlLabel,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Snackbar,
    CircularProgress
} from '@mui/material';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import GroupsIcon from '@mui/icons-material/Groups';
import ApiService from '../../modules/APIService.interceptors';
import ChatService from '../../services/chat.service';

// Canales disponibles con sus respectivos iconos
const availableChannels = [
    { name: 'RECETAS', icon: <RestaurantMenuIcon /> },
    { name: 'LIVE', icon: <LiveTvIcon /> },
    { name: 'EJERCICIOS', icon: <FitnessCenterIcon /> },
    { name: 'COMUNIDAD', icon: <GroupsIcon /> }
];

const AddModeratorDialog = ({ openDialog, handleCloseDialog, addModeratorToTable }) => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedChannels, setSelectedChannels] = useState([]);
    const [loader, setLoader] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // Cargar usuarios al abrir el modal
    useEffect(() => {
        if (openDialog) {
            getAllUsers();
        }
    }, [openDialog]);

    // Obtener usuarios desde la API
    const getAllUsers = async () => {
        try {
            setLoader(true);
            const response = await ApiService.getAllUsers();
            if (!response.error) {
                setUsers(response.data);
            } else {
                setSnackbarMessage('Error al obtener usuarios.');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error al conectar con la API:', error);
            setSnackbarMessage('Error al conectar con el servidor.');
            setOpenSnackbar(true);
        } finally {
            setLoader(false);
        }
    };

    // Filtrar usuarios dinámicamente según el texto de búsqueda
    useEffect(() => {
        if (search.trim().length > 0) {
            const lowerSearch = search.toLowerCase();
            const filtered = users.filter(
                (user) =>
                    user.name.toLowerCase().includes(lowerSearch) ||
                    user.email.toLowerCase().includes(lowerSearch)
            );
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers([]);
        }
    }, [search, users]);

    // Seleccionar un usuario
    const handleSelectUser = (user) => {
        setSelectedUser(user);
    };

    // Alternar selección de canales
    const handleToggleChannel = (channel) => {
        if (selectedChannels.includes(channel)) {
            setSelectedChannels(selectedChannels.filter((c) => c !== channel));
        } else {
            setSelectedChannels([...selectedChannels, channel]);
        }
    };

    // Enviar datos al backend para agregar un nuevo moderador
    const handleAddModerator = async () => {
        if (!selectedUser) {
            setSnackbarMessage('Debes seleccionar un usuario.');
            setOpenSnackbar(true);
            return;
        }

        if (selectedChannels.length === 0) {
            setSnackbarMessage('Debes seleccionar al menos un canal.');
            setOpenSnackbar(true);
            return;
        }

        const arrOfChannelsProps = {
            arrOfChannelsProps: {
                userId: selectedUser.id,
                level: "fullAccess",
                channelUid: selectedChannels
            },
            userId: selectedUser.id,
        }
        try {
            setLoader(true);
            const response = await ChatService.addModerator(arrOfChannelsProps)
            if (response && !response.error) {
                setSnackbarMessage(`Moderador ${selectedUser.name} agregado correctamente.`);

                // Actualizar la tabla inmediatamente
                const newModerator = {
                    id: selectedUser.id,
                    name: `${selectedUser.name} ${selectedUser.lastname}`,
                    email: selectedUser.email,
                    avatar: selectedUser.avatar,
                    channels: selectedChannels,
                    level: "fullAccess"
                };
                addModeratorToTable(newModerator);

            } else {
                setSnackbarMessage('Hubo un problema al agregar el moderador.');
            }
        } catch (error) {
            console.error('Error al enviar datos al servidor:', error);
            setSnackbarMessage('Error al conectar con el servidor.');
        } finally {
            setOpenSnackbar(true);
            setLoader(false);
            setSelectedUser(null);
            setSelectedChannels([]);
            setSearch('');
            handleCloseDialog();
        }
    };

    // Limpiar la selección al cancelar
    const handleCancel = () => {
        setSelectedUser(null);
        setSelectedChannels([]);
        setSearch('');
        handleCloseDialog();
    };

    return (
        <Dialog open={openDialog} onClose={handleCancel} maxWidth="lg" fullWidth>
            <DialogTitle>Agregar Nuevo Moderador</DialogTitle>
            <DialogContent>
                {/* Buscador de usuarios */}
                <TextField
                    label="Buscar usuario por nombre o correo"
                    variant="outlined"
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{ mb: 2, mt: 2 }}
                />

                {/* Lista de usuarios solo si se ingresa texto */}
                {search.trim().length > 0 && (
                    <List sx={{ maxHeight: 400, overflow: 'auto', mb: 2 }}>
                        {filteredUsers?.map((user) => (
                            <ListItem
                                key={user.id}
                                button
                                onClick={() => handleSelectUser(user)}
                                selected={selectedUser?.id === user.id}
                                sx={{
                                    border: selectedUser?.id === user.id ? '2px solid pink' : '1px solid #ccc',
                                    borderRadius: 2,
                                    mb: 1,
                                    cursor: 'pointer',
                                    '&:hover': { backgroundColor: '#f0f0f0' }
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar src={user?.avatar || 'https://via.placeholder.com/50'} />
                                </ListItemAvatar>
                                <ListItemText primary={`${user?.name} ${user?.lastname}`} secondary={user.email} />
                            </ListItem>
                        ))}
                    </List>
                )}

                {/* Información del usuario seleccionado */}
                {selectedUser && (
                    <Box sx={{ mb: 3, p: 2, border: '1px solid #ccc', borderRadius: 2, backgroundColor: '#f5f5f5' }}>
                        <Typography variant="h6">Sirena seleccionada:</Typography>
                        <Typography><strong>Nombre:</strong> {selectedUser?.name} {selectedUser?.lastname}</Typography>
                        <Typography><strong>Correo:</strong> {selectedUser?.email}</Typography>
                    </Box>
                )}

                {/* Selección de canales */}
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>Selecciona los canales:</Typography>
                    {availableChannels?.map(({ name, icon }) => (
                        <FormControlLabel
                            key={name}
                            control={
                                <Checkbox
                                    checked={selectedChannels.includes(name)}
                                    onChange={() => handleToggleChannel(name)}
                                />
                            }
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    {icon}
                                    {name}
                                </Box>
                            }
                            sx={{ mr: 2 }}
                        />
                    ))}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button color="error" onClick={handleCancel}>Cancelar</Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleAddModerator}
                    disabled={!selectedUser || selectedChannels.length === 0 || loader}
                >
                    {loader ? <CircularProgress size={20} sx={{ mr: 1 }} /> : 'Agregar'}
                </Button>
            </DialogActions>

            {/* Snackbar de notificación */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                message={snackbarMessage}
                onClose={() => setOpenSnackbar(false)}
            />
        </Dialog>
    );
};

export default AddModeratorDialog;
