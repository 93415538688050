import { APIURL } from "../models/api-base";
import { apiUrl_profile } from "../models/api-urls";
import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  getProfiles = async () => {
    const response = await this.get(apiUrl_profile);
    return response;
  };

  createProfile = async (profile) => {
    const response = await this.post(apiUrl_profile, profile);
    return response.data;
  };

  updateProfile = async (update, id) => {
    const endpoint = apiUrl_profile + "/" + id;
    const response = await this.put(endpoint, update);
    return response;
  };

  deleteProfile = async (id) => {
    const endpoint = apiUrl_profile + "/" + id;
    const response = await this.delete(endpoint);
    return response;
  };
}

const ProfileService = new Service();

export default ProfileService;
