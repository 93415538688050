import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Backdrop } from "@mui/material";

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  text,
  textConfirmation,
  textCancel,
  showConfirmationButton = true,
  showCancelButton = true,
}) => {
  return (
    <Backdrop
      open={open}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          width: 480,
          textAlign: "center",
          borderRadius: 5,
        }}
      >
        <Typography
          variant="h5"
          sx={{ textAlign: "center", margin: "6% 0 8% 0", color: "GrayText" }}
        >
          {text}
        </Typography>
        {showConfirmationButton && (
          <Button
            variant="contained"
            onClick={onConfirm}
            color="primary"
            sx={{ mr: 4, mb: 2 }}
          >
            {textConfirmation}
          </Button>
        )}
        {showCancelButton && (
          <Button variant="outlined" onClick={onClose} sx={{ mb: 2 }}>
            {textCancel}
          </Button>
        )}
      </Box>
    </Backdrop>
  );
};

export default ConfirmationModal;
