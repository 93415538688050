import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import AdminService from "../../services/admin.service";
import NewUserModal from "../../components/NewUserModal/NewUserModal";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useAuthStore } from "../../store/zustand/authStore";
import NotAllowed from "../NotAllowed/NotAllowed";
import { CartContext } from "../../store/context/CartContext";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: theme.spacing(2),
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "& th": {
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 18,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Admin = () => {
  const [admins, setAdmins] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([
    { name: "" },
    { lastname: "" },
    { address: "" },
    { phone: "" },
    { dni: "" },
    { email: "" },
    { birthdate: null },
    { password: "" },
  ]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const [openAlert, setOpenAlert] = useState(false);
  const { setTitleSeccion } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTitleSeccion("Administradoras");
  }, []);

  const handleOpen = (isEditing = false, admin = null) => {
    if (isEditing && admin) {
      setIsEditing(true);
      setEditingId(admin.id);
      console.log(admin);
      setData({
        name: admin.name,
        lastname: admin.lastname,
        address: admin.user_location,
        phone: admin.phone,
        dni: admin.dni,
        email: admin.email,
        birthdate: admin.birth_date,
        password: "", // No mostramos la contraseña existente
      });
    } else {
      setIsEditing(false);
      setEditingId(null);
      setData({
        name: "",
        lastname: "",
        address: "",
        phone: "",
        dni: "",
        email: "",
        birthdate: null,
        password: "",
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchAdmins = async () => {
      setIsLoading(true);
      try {
        const response = await AdminService.getAll();
        setAdmins(response);
      } catch (error) {
        console.log("Error", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAdmins();
  }, []);

  const handleRowClick = (id) => {
    if (user?.admin_users?.SADMIN?.WRITE[0]) {
      navigate(`/admin/${id}`);
    } else {
      return null;
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const admin = {
      name: data?.name,
      lastname: data?.lastname,
      user_location: data?.address,
      phone: data?.phone,
      dni: data?.dni,
      email: data?.email,
      birth_date: data?.birthdate,
      password: data?.password,
    };

    try {
      setIsLoading(true);
      if (isEditing) {
        // Actualiza el usuario
        await AdminService.updateUser(editingId, admin);
        setAdmins((prev) =>
          prev.map((item) =>
            item.id === editingId ? { ...item, ...admin } : item
          )
        );
      } else {
        // Crea un nuevo usuario
        const newAdmin = await AdminService.create(admin);
        setAdmins((prev) => [...prev, newAdmin]);
      }
      handleClose();
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (admin) => {
    handleOpen(true, admin);
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      await AdminService.deleteAdmin(id); // Llama al servicio de eliminación
      setAdmins((prevAdmins) => prevAdmins.filter((admin) => admin.id !== id));
      setOpenAlert(true);
      setTimeout(() => setOpenAlert(false), 3000);
    } catch (error) {
      console.error("Error al eliminar el administrador:", error);
      setOpenAlert(false);
    } finally {
      setOpenAlert(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <NotAllowed user={user} page={"SADMIN"} />
      <Backdrop
        sx={{ color: "#D0749B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        {openAlert && (
          <Alert severity="success">Admnistrador eliminado existosamente</Alert>
        )}

        {/* Boton de crear administradores */}
        {user?.admin_users?.SADMIN?.WRITE[0] && (
          <Button variant="outlined" onClick={handleOpen}>
            Crear Administradora
          </Button>
        )}

        {/* Tabla de Administradores */}
        <StyledTableContainer component={Paper}>
          <Table>
            <StyledTableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Apellido</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Editar</TableCell>
                <TableCell>Eliminar</TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {admins?.map((admin) => {
                return (
                  <StyledTableRow
                    key={admin.id}
                    hover
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell
                      onClick={() => handleRowClick(admin.id)}
                      style={{
                        fontWeight: 100,
                        color: "grey",
                        fontSize: 14,
                      }}
                    >
                      {admin?.name}
                    </TableCell>
                    <TableCell
                      onClick={() => handleRowClick(admin.id)}
                      style={{
                        fontWeight: 100,
                        color: "grey",
                        fontSize: 14,
                      }}
                    >
                      {admin?.lastname}
                    </TableCell>
                    <TableCell
                      onClick={() => handleRowClick(admin.id)}
                      style={{
                        fontWeight: 100,
                        color: "grey",
                        fontSize: 14,
                      }}
                    >
                      {admin?.email}
                    </TableCell>
                    <TableCell>
                      {user?.admin_users?.SADMIN?.WRITE[0] && (
                        <Button
                          onClick={() => handleEditClick(admin)}
                          sx={{ minWidth: 0 }}
                        >
                          <EditIcon />
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      {user?.admin_users?.SADMIN?.WRITE[0] && (
                        <DeleteOutlineIcon
                          onClick={() => handleDelete(admin?.id)}
                        />
                      )}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>

        {/* Modal de creaicon/edicion de administardores */}
        <NewUserModal
          handleClose={handleClose}
          open={open}
          data={data}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isEditing={isEditing}
        />
      </Box>
    </>
  );
};

export default Admin;
