import { Box, Card, CardContent, Chip, TextField } from "@mui/material";
import { useState, useEffect } from "react";

const CardWorkshopDate = ({ dateStart, dateEnd, mode, setStartDate, setEndDate, isEditing, height, lastWorkshopCreated}) => {
    const [formattedDateStart, setFormattedDateStart] = useState(dateStart);
    const [formattedDateEnd, setFormattedDateEnd] = useState(dateEnd);

    useEffect(() => {
        setFormattedDateStart(new Date(dateStart).toISOString().split('T')[0]);
        setFormattedDateEnd(new Date(dateEnd).toISOString().split('T')[0]);
    }, [dateStart, dateEnd]);

    const handleStartDateChange = (event) => {
        setFormattedDateStart(event.target.value);
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        const endDate = event.target.value;
        if (formattedDateStart && endDate < formattedDateStart) {
            return;
        }
        setFormattedDateEnd(endDate);
        setEndDate(endDate);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 2); // Sumamos un día correctamente aquí
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Obtenemos el mes y lo formateamos
        let day = date.getDate().toString().padStart(2, '0'); // Obtenemos el día y lo formateamos
        return `${year}-${month}-${day}`;
    };

    return (
        <Card sx={{ mt: 2, width: "100%", height:height}}>
            <CardContent sx={{ display: "flex", height: '100%', gap:'2%', justifyContent: 'center', alignItems: 'center' }}>
                <TextField
                    fullWidth
                    name={"startDate"}
                    label={"Fecha de Inicio"}
                    type="date"
                    value={formattedDateStart}
                    onChange={handleStartDateChange}
                    disabled={!isEditing}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        inputProps: {
                            min: lastWorkshopCreated ? formatDate(lastWorkshopCreated.dateEnd) : formattedDateStart,
                            max: "2100-12-31",
                        },
                    }}
                />
                <TextField
                    fullWidth
                    name={"endDate"}
                    label={"Fecha de Culminación"}
                    type="date"
                    value={formattedDateEnd}
                    onChange={handleEndDateChange}
                    disabled={!isEditing}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        inputProps: {
                            min: formattedDateStart,
                            max: "2100-12-31",
                        },
                    }}
                />
                {/* <Box sx={{ textAlign: "center" }}>
                    {mode === "new" ? <Chip label="Nuevo taller" color="info" /> : ""}
                    {mode === "finish" ? <Chip label="Finalizado" color="error" /> : ""}
                    {mode === "edit" ? <Chip label="En curso" color="success" /> : ""}
                </Box> */}
            </CardContent>
        </Card>
    );
};

export default CardWorkshopDate;
