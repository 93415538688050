import { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Modal,
  Typography,
  Checkbox,
  Switch,
  FormControlLabel,
  Snackbar,
  Alert,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import ProductService from "../../../services/product.services";

const FormPack = ({
  open,
  handleClose,
  product,
  workshops: allWorkshops,
  showToast,
  getPacks,
  loading,
  setLoading,
}) => {
  const [form, setForm] = useState({
    product: {
      name: "",
      description: "",
      type: "",
      ARG: {
        id: null,
        price: "",
        discountType: "",
        discountValue: "",
        currency: "ARS",
      },
      DOLARS: {
        id: null,
        price: "",
        discountType: "",
        discountValue: "",
        currency: "USD",
      },
      createdAt: "",
      updatedAt: "",
    },
    membership: { id: null, start: "", end: "", createdAt: "", updatedAt: "" },
    workshops: [],
  });
  const [enabled, setEnabled] = useState(true); // Inicializar el switch como true
  const [initialWorkshops, setInitialWorkshops] = useState([]);
  const [isFormChanged, setIsFormChanged] = useState(false); // Track form changes

  const isFormEqual = (form1, form2) => {
    return JSON.stringify(form1) === JSON.stringify(form2);
  };

  // Al cargar el formulario, inicializar el estado con los datos del paquete
  useEffect(() => {
    const selectedWorkshops = product?.workshops || [];
    setForm({
      product: {
        name: product?.product?.name || "",
        description: product?.product?.description || "",
        type: product?.product?.type || "",
        ARG: {
          id: product?.product?.ARG?.id || null,
          price: product?.product?.ARG?.price || "",
          discountType: product?.product?.ARG?.discountType || null,
          discountValue: product?.product?.ARG?.discountValue || null,
          currency: product?.product?.ARG?.currency || "ARS",
        },
        DOLARS: {
          id: product?.product?.DOLARS?.id || null,
          price: product?.product?.DOLARS?.price || "",
          discountType: product?.product?.DOLARS?.discountType || null,
          discountValue: product?.product?.DOLARS?.discountValue || null,
          currency: product?.product?.DOLARS?.currency || "USD",
        },
        createdAt: product?.product?.createdAt || "",
        updatedAt: product?.product?.updatedAt || "",
      },
      membership: {
        id: product?.membership?.id || null,
        start: product?.membership?.start || "",
        end: product?.membership?.end || "",
        createdAt: product?.membership?.createdAt || "",
        updatedAt: product?.membership?.updatedAt || "",
      },
      workshops: selectedWorkshops, // Talleres seleccionados
    });
    setEnabled(product?.product?.type === "Workshop" ? true : false); // Inicializar el switch como true
    setInitialWorkshops(product?.workshops?.map((w) => w.id) || []);
    setIsFormChanged(false); // Reset form change tracking on product change
  }, [product]);

  useEffect(() => {
    setIsFormChanged(
      !isFormEqual(form, {
        product: {
          name: product?.product?.name || "",
          description: product?.product?.description || "",
          type: product?.product?.type || "",
          ARG: {
            id: product?.product?.ARG?.id || null,
            price: product?.product?.ARG?.price || "",
            discountType: product?.product?.ARG?.discountType || null,
            discountValue: product?.product?.ARG?.discountValue || null,
            currency: product?.product?.ARG?.currency || "ARS",
          },
          DOLARS: {
            id: product?.product?.DOLARS?.id || null,
            price: product?.product?.DOLARS?.price || "",
            discountType: product?.product?.DOLARS?.discountType || null,
            discountValue: product?.product?.DOLARS?.discountValue || null,
            currency: product?.product?.DOLARS?.currency || "USD",
          },
          createdAt: product?.product?.createdAt || "",
          updatedAt: product?.product?.updatedAt || "",
        },
        membership: {
          id: product?.membership?.id || null,
          start: product?.membership?.start || "",
          end: product?.membership?.end || "",
          createdAt: product?.membership?.createdAt || "",
          updatedAt: product?.membership?.updatedAt || "",
        },
        workshops: product?.workshops || [],
      })
    );
  }, [form, product]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        [name]: value,
      },
    }));
  };

  const handleWorkshopChange = (event) => {
    const {
      target: { value },
    } = event;

    setForm((prev) => ({
      ...prev,
      workshops:
        typeof value === "string"
          ? value.split(",")
          : value.map((id) => allWorkshops.find((w) => w.id === id)),
    }));
    setIsFormChanged(true); // Mark form as changed
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target; // Fecha en formato YYYY-MM-DD
    const isoDate = new Date(value).toISOString(); // Convertir a ISO (UTC)
    setForm((prev) => ({
      ...prev,
      membership: {
        ...prev.membership,
        [name]: isoDate,
      },
    }));
    setIsFormChanged(true); // Mark form as changed
  };

  const handleCurrencyChange = (e, currency) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      product: {
        ...prev.product,
        [currency]: {
          ...prev.product[currency],
          [name]: value,
        },
      },
    }));
    setIsFormChanged(true); // Mark form as changed
  };

  const handleSwitchChange = async (e) => {
    const { checked } = e.target;
    console.log("Switch changed", checked);
    try {
      setLoading(true);
      await ProductService.toggleEnableProduct(
        form.product.ARG.id,
        form.product.DOLARS.id,
        { disabled: checked }
      );
      showToast("Paquete actualizado correctamente.", "success");
      getPacks();
      setEnabled(checked);
    } catch (error) {
      showToast("Error al actualizar el paquete.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString();
    const updatedForm = {
      ...form,
      product: {
        ...form.product,
        updatedAt: currentDate,
      },
    };

    const updatedFormWithoutWorkshops = {
      product: { ...updatedForm.product },
      membership: { ...updatedForm.membership },
    };

    const currentWorkshops = form.workshops.map((w) => w.id);
    const workshopsChanged =
      currentWorkshops.length !== initialWorkshops.length ||
      currentWorkshops.some((id) => !initialWorkshops.includes(id));

    if (currentWorkshops.length !== initialWorkshops.length) {
      showToast(
        `El paquete debe tener ${initialWorkshops.length} talleres.`,
        "error"
      );
      return;
    }

    if (workshopsChanged) {
      try {
        setLoading(true);
        await ProductService.createPack(updatedForm);
        showToast("Paquete actualizado correctamente.", "success");
        getPacks();
        handleClose();
      } catch (error) {
        showToast("Error al actualizar el paquete.", "error");
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        await ProductService.updatePack(updatedFormWithoutWorkshops);
        showToast("Paquete actualizado correctamente.", "success");
        getPacks();
        handleClose();
      } catch (error) {
        showToast("Error al actualizar el paquete.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: 800,
          maxHeight: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 8,
          outline: "none",
        }}
      >
        <Backdrop
          sx={{
            color: "#D0749B",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            borderRadius: 8,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          display={"flex"}
          alignItems={"center"}
          marginBottom={4}
          justifyContent={"space-between"}
        >
          <Typography variant="h5" textAlign={"center"} color={"GrayText"}>
            {product ? "Editar paquete" : "Create Pack"}
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={enabled}
                onChange={handleSwitchChange}
                name="enabled"
                color="primary"
              />
            }
            label={enabled ? "Habilitado" : "Deshabilitado"}
          />
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TextField
            label="Nombre"
            name="name"
            value={form.product.name}
            onChange={handleChange}
            fullWidth
            disabled={!enabled}
          />

          <TextField
            label="Descripción"
            name="description"
            value={form.product.description}
            onChange={handleChange}
            multiline
            rows={4}
            fullWidth
            disabled={!enabled}
          />
          <FormControl
            fullWidth
            disabled={!enabled || initialWorkshops.length === 0}
          >
            <InputLabel id="workshops-label">
              {initialWorkshops.length === 0
                ? "Todos los talleres"
                : "Talleres"}
            </InputLabel>
            <Select
              labelId="workshops-label"
              id="workshops"
              name="workshops"
              label="Talleres"
              multiple
              value={form.workshops?.map((w) => w.id) || []} // IDs seleccionados
              onChange={handleWorkshopChange}
              renderValue={
                (selected) =>
                  selected
                    .map((id) => {
                      const workshop = allWorkshops.find((w) => w.id === id);
                      return workshop ? workshop.workshopNumber : id;
                    })
                    .join(", ") // Mostrar números de talleres seleccionados
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Altura máxima del dropdown para que sea deslizable
                  },
                },
              }}
            >
              {allWorkshops?.map((workshop) => (
                <MenuItem key={workshop.id} value={workshop.id}>
                  <Checkbox
                    checked={
                      form.workshops?.some((w) => w.id === workshop.id) || false
                    }
                  />
                  {`Taller #${workshop.workshopNumber}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Válido Hasta"
            name="end"
            value={form.membership.end.split("T")[0]}
            onChange={handleDateChange}
            type="date"
            fullWidth
            disabled={!enabled}
          />
          <Typography variant="h5" color={"GrayText"}>
            Pesos Argetinos
          </Typography>
          <Box display={"flex"} gap={2}>
            <TextField
              label="Precio ARS"
              name="price"
              value={form.product.ARG.price}
              onChange={(e) => handleCurrencyChange(e, "ARG")}
              type="number"
              fullWidth
              disabled={!enabled}
            />

            <FormControl fullWidth disabled={!enabled}>
              <InputLabel id="discountType-ARS-label">
                Tipo de Descuento ARS
              </InputLabel>
              <Select
                label="Tipo de Descuento ARS"
                labelId="discountType-ARS-label"
                id="discountType-ARS"
                name="discountType"
                value={form.product.ARG.discountType || ""}
                onChange={(e) => handleCurrencyChange(e, "ARG")}
              >
                <MenuItem value="fixed">Fijo</MenuItem>
                <MenuItem value="percentage">Porcentaje</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Descuento ARS"
              name="discountValue"
              value={form.product.ARG.discountValue || ""}
              onChange={(e) => handleCurrencyChange(e, "ARG")}
              type="number"
              fullWidth
              disabled={!enabled}
            />
          </Box>
          <Typography variant="h5" color={"GrayText"}>
            Dólares
          </Typography>
          <Box display={"flex"} gap={2} sx={{ marginBottom: 2 }}>
            <TextField
              label="Precio USD"
              name="price"
              value={form.product.DOLARS.price}
              onChange={(e) => handleCurrencyChange(e, "DOLARS")}
              type="number"
              fullWidth
              disabled={!enabled}
            />
            <FormControl fullWidth disabled={!enabled}>
              <InputLabel id="discountType-USD-label">
                Tipo de Descuento USD
              </InputLabel>
              <Select
                label="Tipo de Descuento USD"
                labelId="discountType-USD-label"
                id="discountType-USD"
                name="discountType"
                value={form.product.DOLARS.discountType || ""}
                onChange={(e) => handleCurrencyChange(e, "DOLARS")}
              >
                <MenuItem value="fixed">Fijo</MenuItem>
                <MenuItem value="percentage">Porcentaje</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Descuento USD"
              name="discountValue"
              value={form.product.DOLARS.discountValue || ""}
              onChange={(e) => handleCurrencyChange(e, "DOLARS")}
              type="number"
              fullWidth
              disabled={!enabled}
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!enabled || !isFormChanged} // Disable button if form is not changed
          >
            Confirmar cambios
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FormPack;
