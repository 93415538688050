import React from "react";
import {
  Box,
  IconButton,
  Typography,
  CardMedia,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  makeStyles,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

const CardProfesional = ({
  profesionals,
  handleDelete,
  editMode,
  editId,
  handleSubmit,
  handleInputChange,
  formValues,
  handleEdit,
  onClose,
  handleFileChange,
  permission
}) => {

  return (
    <Grid container spacing={3} sx={{ marginTop: 2 }}>
      {profesionals?.map((profesional) => (
        <Grid item key={profesional.id} xs={12} sm={6} md={4}>
          <Card sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: editMode && editId === profesional.id ? "850px" : "500px",
            borderRadius: "15px", // 🔹 Redondea las esquinas
            boxShadow: "0px 4px 8px rgba(18, 17, 17, 0.22)", // 🔹 Sombra suave para un mejor diseño
            overflow: "hidden", // 🔹 Evita que el contenido sobresalga
          }}>
            <CardMedia
              component="img"
              height="250"
              image={profesional?.thumbnail}
              alt={profesional?.name}
            />
            <CardContent sx={{ height: "1000px" }} >
              {editMode && editId === profesional.id ? (
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Nombre"
                    name="name"
                    fullWidth
                    value={`${formValues.name}`}
                    onChange={handleInputChange}
                    required
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Apellido"
                    name="lastname"
                    fullWidth
                    value={`${formValues.lastname}`}
                    onChange={handleInputChange}
                    required
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Profesion"
                    name="occupation"
                    fullWidth
                    value={formValues.occupation}
                    onChange={handleInputChange}
                    sx={{ marginBottom: 2 }}
                  />

                  {/* Replace TextField with input type="file" */}
                  <input
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="image-upload">
                    <Button
                      variant="contained"
                      component="span"
                      sx={{ marginBottom: 2 }}
                    >
                      Cambiar Portada
                    </Button>
                  </label>

                  <TextField
                    label="Link de la publicacion"
                    name="insta"
                    fullWidth
                    value={formValues.insta}
                    onChange={handleInputChange}
                    required
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Descripcion"
                    name="description"
                    fullWidth
                    multiline
                    rows={4}
                    value={formValues.description}
                    onChange={handleInputChange}
                    sx={{ marginBottom: 2 }}
                  />

                  <Box
                    sx={{
                      display: "flex", // Flexbox para alinear horizontalmente
                      justifyContent: "center", // Centrar horizontalmente
                      alignItems: "center", // Alineación vertical
                      gap: 2, // Espacio uniforme entre botones
                      marginTop: 2, // Separación superior opcional
                    }}
                  >
                    <Button type="submit" variant="contained" color="primary">
                      Guardar Cambios
                    </Button>
                    <Button
                      onClick={onClose}
                      variant="contained"
                      color="primary"
                    >
                      Cancelar
                    </Button>
                  </Box>
                </form>
              ) : (
                <>
                  <Typography variant="h5" component="div" gutterBottom>
                    {profesional?.name} {profesional?.lastname}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {profesional?.occupation}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {profesional?.description}
                  </Typography>
                  {permission?.admin_users?.PROFESSIONALS?.WRITE[0] && <Box sx={{ marginVertical: 2 }}>
                    <IconButton
                      onClick={() => handleEdit(profesional?.id)}
                      aria-label="edit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(profesional?.id)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardProfesional;
