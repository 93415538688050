import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import moment from "moment-timezone";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const CalendarEventModal = ({ open, handleClose, event, onSave, onDelete }) => {
  const [editedEvent, setEditedEvent] = useState({ ...event });

  useEffect(() => {
    setEditedEvent({ ...event });
  }, [event]);

  const handleChangeTitle = (e) => {
    setEditedEvent((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };
  const handleChangeSubtitle = (e) => {
    setEditedEvent((prev) => ({
      ...prev,
      subtitle: e.target.value,
    }));
  };
  const handleChangeQuest = (e) => {
    setEditedEvent((prev) => ({
      ...prev,
      quest: e.target.value,
    }));
  };

  const handleChangeStart = (e) => {
    setEditedEvent((prev) => ({
      ...prev,
      start: new Date(e.target.value),
    }));
  };

  const handleChangeEnd = (e) => {
    setEditedEvent((prev) => ({
      ...prev,
      end: new Date(e.target.value),
    }));
  };

  const handleSave = () => {
    onSave(editedEvent);
    handleClose();
  };

  const handleDelete = () => {
    onDelete(editedEvent); // Pasar editedEvent para asegurar que se elimine correctamente
    handleClose();
  };

  const formatToLocalDatetime = (date) => {
    return moment(date).format("YYYY-MM-DDTHH:mm");
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">
          Editar Evento
        </Typography>
        <TextField
          label="Título"
          type="text"
          value={editedEvent?.title}
          onChange={handleChangeTitle}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Descripcion"
          value={editedEvent?.subtitle}
          onChange={handleChangeSubtitle}
          margin="normal"
          fullWidth
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={editedEvent?.quest}
              onChange={handleChangeQuest}
              color="primary"
              style={{ padding: 15 }}
            />
          }
          label="Consigna Diaria"
        />

        {!editedEvent.quest && (
          <>
            <TextField
              label="Inicio"
              type="datetime-local"
              value={
                editedEvent?.start
                  ? formatToLocalDatetime(editedEvent.start)
                  : ""
              }
              onChange={handleChangeStart}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Fin"
              type="datetime-local"
              value={
                editedEvent?.end ? formatToLocalDatetime(editedEvent.end) : ""
              }
              onChange={handleChangeEnd}
              margin="normal"
              fullWidth
            />
          </>
        )}

        <Box justifyContent={"center"}>
          <Button onClick={handleSave} size="large" sx={{ mt: 2 }}>
            Guardar
          </Button>
          <Button onClick={handleDelete} size="large" sx={{ mt: 2, mx: 2 }}>
            Eliminar
          </Button>
          <Button onClick={handleClose} size="large" sx={{ mt: 2 }}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CalendarEventModal;
