import React from "react";
import {
  Box,
  IconButton,
  Typography,
  CardMedia,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  makeStyles,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

const CardProfesional = ({
  profesionals,
  handleDelete,
  editMode,
  editId,
  handleSubmit,
  handleInputChange,
  formValues,
  handleEdit,
  onClose,
  handleFileChange,
}) => {

  return (
    <Grid container spacing={3} sx={{ marginTop: 2 }}>
      {profesionals.map((profesional) => (
        <Grid item key={profesional.id} xs={12} sm={6} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="400"
              image={profesional?.thumbnail}
              alt={profesional?.name}
              objectFit={'cover'}
              objectPosition={'center top'}
            />
            <CardContent>
              {editMode && editId === profesional.id ? (
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Nombre"
                    name="name"
                    fullWidth
                    value={`${formValues.name}`}
                    onChange={handleInputChange}
                    required
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Apellido"
                    name="lastname"
                    fullWidth
                    value={`${formValues.lastname}`}
                    onChange={handleInputChange}
                    required
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Profesion"
                    name="occupation"
                    fullWidth
                    value={formValues.occupation}
                    onChange={handleInputChange}
                    sx={{ marginBottom: 2 }}
                  />

                  {/* Replace TextField with input type="file" */}
                  <input
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="image-upload">
                    <Button
                      variant="contained"
                      component="span"
                      sx={{ marginBottom: 2 }}
                    >
                      Cambiar Miniatura
                    </Button>
                  </label>

                  <TextField
                    label="Link de la publicacion"
                    name="insta"
                    fullWidth
                    value={formValues.insta}
                    onChange={handleInputChange}
                    required
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Descripcion"
                    name="description"
                    fullWidth
                    multiline
                    rows={4}
                    value={formValues.description}
                    onChange={handleInputChange}
                    sx={{ marginBottom: 2 }}
                  />

                  <Button type="submit" variant="contained" color="primary">
                    Guardar Cambios
                  </Button>

                  <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                    sx={{ marginX: 3 }}
                  >
                    Cancelar
                  </Button>
                </form>
              ) : (
                <>
                  <Typography variant="h5" component="div" gutterBottom>
                    {profesional.name} {profesional.lastname}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {profesional.occupation}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {profesional.description}
                  </Typography>
                  <Box sx={{ marginVertical: 2 }}>
                    <IconButton
                      onClick={() => handleEdit(profesional.id)}
                      aria-label="edit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(profesional.id)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardProfesional;
