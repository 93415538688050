import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import LayersIcon from "@mui/icons-material/Layers";
import PaymentIcon from "@mui/icons-material/Payment";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { LiveTv } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import SettingsIcon from "@mui/icons-material/Settings";
import CategoryIcon from "@mui/icons-material/Category";
import ArchiveIcon from "@mui/icons-material/Archive";
import ChatIcon from "@mui/icons-material/Chat";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const CustomLink = ({ to, icon: Icon, primary, open }) => {
  const location = useLocation();
  const isSelected = location.pathname === to;

  const getStyles = (isSelected, open) => ({
    textDecoration: "none",
    color: isSelected ? "#D0749B" : "#767676",
    backgroundColor: open ? "rgba(0, 0, 0, 0.04)" : "transparent",
  });

  return (
    <Link to={to} style={getStyles(isSelected, open)}>
      <ListItemButton
        sx={{
          backgroundColor: isSelected & open ? "#D0749B" : "transparent",
          borderRadius: 15,
          margin: isSelected & open ? "5%" : "0 5%",
        }}
      >
        <ListItemIcon>
          <Icon
            sx={{
              color: isSelected ? (open ? "white" : "#D0749B") : "#767676",
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={primary}
          sx={{ color: isSelected ? (open ? "white" : "#D0749B") : "#767676" }}
        />
      </ListItemButton>
    </Link>
  );
};

export const mainListItems = (open, userPermission) => {
  return (
    <React.Fragment>
      <>
        <CustomLink to="/" icon={HomeIcon} primary="Inicio" open={open} />
        {userPermission?.admin_users?.USERS?.READ[0] && (
          <CustomLink
            to="/usuarios"
            icon={PeopleIcon}
            primary="Usuarias"
            open={open}
          />
        )}
        {userPermission?.admin_users?.PAYMENTS?.READ[0] && (
          <CustomLink
            to="/pagos"
            icon={PaymentIcon}
            primary="Pagos"
            open={open}
          />
        )}
        {userPermission?.admin_users?.SADMIN?.READ[0] && (
          <CustomLink
            to="/admin"
            icon={AdminPanelSettingsIcon}
            primary="Administradores"
            open={open}
          />
        )}

        {userPermission?.admin_users?.WORKSHOPS?.READ[0] && (
          <CustomLink
            to="/talleres"
            icon={LayersIcon}
            primary="Talleres"
            open={open}
          />
        )}

        {userPermission?.admin_users?.PRODUCTS?.READ[0] && (
          <CustomLink
            to="/products"
            icon={StorefrontIcon}
            primary="Productos"
            open={open}
          />
        )}

        {/* <CustomLink to="/estadisticas" icon={AutoGraphIcon} primary="Estadísticas" open={open} /> */}
        {userPermission?.admin_users?.EVENTS?.READ[0] && (
          <CustomLink
            to="/calendario"
            icon={CalendarMonthIcon}
            primary="Calendario"
            open={open}
          />
        )}

        {userPermission?.admin_users?.PROFESSIONALS?.READ[0] && (
          <CustomLink
            to="/profesionales"
            icon={LocalHospitalIcon}
            primary="Profesionales"
            open={open}
          />
        )}
        {/* <CustomLink to="/recetas" icon={RestaurantIcon} primary="Recetas" open={open} /> */}
        {userPermission?.admin_users?.LIVES?.READ[0] && (
          <CustomLink
            to="/categories"
            icon={CategoryIcon}
            primary="Categorias"
            open={open}
          />
        )}
        {userPermission?.admin_users?.LIVES?.READ[0] && (
          <CustomLink
            to="/archives"
            icon={ArchiveIcon}
            primary="Vivos Archivados"
            open={open}
          />
        )}
        <CustomLink
          to="/comments"
          icon={ChatIcon}
          primary="Historial ChatLives"
          open={open}
        />

        {/* {userPermission?.admin_users?.PRODUCTS?.READ[0] && ( */}
        <CustomLink
          to="/moderators"
          icon={PeopleAltIcon}
          primary="Moderadores"
          open={open}
        />
        {/* )} */}


        <CustomLink to="/broadcast" icon={LiveTv} primary="Live" open={open} />
        {userPermission?.admin_users?.BANK?.READ[0] && (
          <CustomLink
            to="/settings"
            icon={SettingsIcon}
            primary="CBU"
            open={open}
          />
        )}
      </>
    </React.Fragment>
  );
};
