import { createContext, useEffect, useState } from "react";
import { TOKENSTORAGE } from "../../models/constants";
import UserService from "../../services/user.service";
const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [session, setSession] = useState(false);
  const [titleSeccion, setTitleSeccion] = useState("Panel Administrador");
  const [user, setUser] = useState({});

  const getUser = async () => {
    try {
      const response = await UserService.validateUser();
      if (!response.error) {
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeSesion = async () => {
    localStorage.removeItem(TOKENSTORAGE);
    setSession(false);
  };

  useEffect(() => {
    const token = localStorage.getItem(TOKENSTORAGE);
    if (token) {
      setSession(true);
      // Llama a getUser solo si es necesario
      getUser();
    }
  }, []); // Si el token cambia, considera añadirlo a las dependencias si es necesario

  const data = {
    user,
    setUser,
    session,
    setSession,
    titleSeccion,
    setTitleSeccion,
    closeSesion,
    getUser,
  };
  return <CartContext.Provider value={data}>{children}</CartContext.Provider>;
};

export { CartProvider, CartContext };
