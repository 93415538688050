import React, { useState, useCallback, useEffect,  useContext } from "react";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import StyledCalendar from "./Calendar.styles";
import "moment-timezone";
import "moment/locale/es";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { NewEventModal } from "../../components/NewEventModal/NewEventModal";
import { MasiveAddForm } from "../../components/MasiveAddForm/MasiveAddForm";
import CalendarEventModal from "../../components/CalendarEventModal/CalendarEventModal";
import CalendarService from "../../services/calendar.service";
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from '@mui/icons-material/Circle';
import { useAuthStore } from "../../store/zustand/authStore";
import NotAllowed from "../NotAllowed/NotAllowed";
import { CartContext } from "../../store/context/CartContext";

moment.locale('es');
moment.tz.setDefault("America/Buenos_Aires");
const localizer = momentLocalizer(moment);

const Calendario = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [workshops, setWorkshops] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showMasiveAdd, setShowMassiveAdd] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loader, setLoader] = useState(false);
  const user = useAuthStore((state) => state.user);
  const { setTitleSeccion } = useContext(CartContext);

    useEffect(() => {
      setTitleSeccion("Calendario");
    }, []);

  useEffect(() => {
    const getEvents = async () => {
      try {
        setLoader(true);
        const response = await CalendarService.getCalendarEvents();
        const workshopsDates = await CalendarService.getWorkshopsDates();
        if (!response.error) {
          setEvents(response.data);
        }
        if (!workshopsDates.error) {
          setWorkshops(workshopsDates.data);
          // Aquí puedes procesar los datos de workshops si es necesario
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoader(false);
      }
    };
    getEvents();
  }, []);

  const allEventsArray = () => {
    const allEvents = [];

    allEvents.push(
      ...events.map(event => ({
        ...event,
        start: new Date(event?.start),
        end: new Date(event?.end),
      }))
    );

    workshops.forEach((workshop) => {
      const workshopEvent = {
        id: workshop?.id,
        start: new Date(workshop?.dateStart),
        end: new Date(workshop?.dateEnd),
        title: `Taller #${workshop?.workshopNumber}`,
        allDay: true,
      };
      allEvents.push(workshopEvent);
    });
    return allEvents;
  };

  const handleCloseModal = () => setShowForm(false) || setShowMassiveAdd(false);
  ///////Crear un evento//////////////////////////////////
  const handleCreateEvent = (newEvents) => {
    if (Array.isArray(newEvents)) {
      setEvents((prevEvents) => [...prevEvents, ...newEvents]); // Spread the new events into the existing array
      CalendarService.createCalendarEventArray(newEvents);
    } else {
      setEvents((prevEvents) => [...prevEvents, newEvents]); // Add the single new event to the array
    }
  };

  const handleSelectSlot = (slotInfo) => {
    setSelectedDate(slotInfo);
    setShowForm(true);
  };

  const handleSelectEvent = useCallback((event) => {
    setSelectedEvent(event);
    handleOpen();
  }, []);

  ///////////////////////////UPDATE DATE MODAL SELECTED DATE/////////////////////////
  const handleSaveEvent = useCallback(
    async (editedEvent) => {
      try {
        const response = await CalendarService.updateCalendarEvent(
          editedEvent.id,
          editedEvent
        );

        setEvents((prevEvents) =>
          prevEvents?.map((event) =>
            event === selectedEvent ? { ...event, ...editedEvent } : event
          )
        );
        setSelectedEvent(null);
      } catch (error) {
        console.log(error);
      }
    },
    [selectedEvent]
  );

  //////////////////Elimina eventos////////////////////
  const handleDeleteEvent = useCallback(async (eventToDelete) => {
    try {
      console.log(eventToDelete)
      const response = await CalendarService.deleteCalendarEvent(
        eventToDelete.id
      );
      if (response) {
        setEvents((prevEvents) =>
          prevEvents.filter((e) => e !== eventToDelete)
        );
        setSelectedEvent(null);
        setShowForm(false);
        setIsSend(true);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <NotAllowed user={user} page={"EVENTS"} />
      {/* Backdrop con CircularProgress */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        onClick={() => setLoader(false)} // Opcional: cerrar el Backdrop haciendo clic en él
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSend && (
        <Box
          position={"absolute"}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
        >
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsSend(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            <AlertTitle>Exito!</AlertTitle>
            Se ha eliminado el evento.
          </Alert>
        </Box>
      )}



      {user?.admin_users?.EVENTS?.READ[0] && (<>


        {user?.admin_users?.EVENTS?.WRITE[0] && <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<AddIcon />}
          onClick={() => setShowMassiveAdd(true)}
          style={{ marginTop: 10, marginBottom: 20 }}
        >
          Agregar eventos
        </Button>}

        <Box flexDirection={"column"} display={"flex"} marginY={2}>
          <Box flexDirection={"row"} display={"flex"} >
            <CircleIcon fontSize="small" sx={{ color: '#d0749b' }} />
            <Typography>Eventos</Typography>

            <CircleIcon fontSize="small" sx={{ color: '#F69195', marginLeft: "2%" }} />
            <Typography>Consignas Diarias</Typography>
          </Box>


          {user?.admin_users?.quest?.WRITE[0] && <Box flexDirection={"row"} display={"flex"} >
            <CircleIcon fontSize="small" sx={{ color: '#F69195' }} />
            <Typography>Consigna Diaria</Typography>
          </Box>}


        </Box>

        <StyledCalendar
          localizer={localizer}
          events={allEventsArray()}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "100%" }}
          selectable
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          views={['month', 'day', 'agenda']}
          defaultView="month"
          messages={{
            next: "Siguiente",
            previous: "Anterior",
            today: "Hoy",
            month: "Mes",
            week: "Semana",
            day: "Día",
            agenda: "Agenda",
            date: "Fecha",
            time: "Hora",
            event: "Evento",
            allDay: "Todo el día",
            noEventsInRange: "No hay eventos en este rango."
          }}
          maxRows={5}
          popup={false}
          eventPropGetter={(event) => {
            const style = {
              backgroundColor: event.quest ? '#F69195' : '#d0749b',
              color: 'white',
              borderRadius: '4px',
              border: 'none',
              margin: '2px',
              fontSize: 13
            };
            return { style };
          }}
        /> </>)}

      <CalendarEventModal
        open={open}
        handleClose={handleClose}
        event={selectedEvent}
        onSave={handleSaveEvent}
        onDelete={() => handleDeleteEvent(selectedEvent)}
      />

      {showMasiveAdd && (
        <MasiveAddForm
          open={showMasiveAdd}
          handleClose={handleCloseModal}
          handleCreate={handleCreateEvent}
        />
      )}

      {showForm && user?.admin_users?.EVENTS?.WRITE[0] && (
        <NewEventModal
          open={showForm}
          handleClose={handleCloseModal}
          handleCreate={handleCreateEvent}
          selectedSlot={selectedDate}
          setSelectedDate={setSelectedDate}
          handleDeleteEvent={handleDeleteEvent}
          permisos={user}
        />
      )}
    </>
  );
};

export default Calendario;
