const BUILD = "dev"; // <<<<-------- CAMBIAR POR "dev" | "prod"

const APIURL = "https://api-admin.proyecto-sirenas.com";

const API_URL_SOCKET = "https://chat.proyecto-sirenas.com";

console.log(`=========>`, APIURL);
console.log(`=======>`, API_URL_SOCKET);

export { APIURL, API_URL_SOCKET };
