import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CardContent,
    Card,
    Divider
} from '@mui/material';


const UserWeightTable = ({ data }) => {

    return (
        <Card>

            <CardContent>
                <Typography variant="h5" color={"#767676"}>Registro de peso</Typography>
                <Divider sx={{ mb: 1 }} />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Peso Actual</TableCell>
                                <TableCell align="left">Peso Objetivo</TableCell>
                                <TableCell align="center">Fecha de Registro</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{!row?.current_weight ? "Sin registro" : `${row?.current_weight}Kg`}</TableCell>
                                    <TableCell align="left">{(row?.objective_weight == 0 || !row?.objective_weight) ? "Sin registro" : `${row?.objective_weight}Kg`}</TableCell>
                                    <TableCell align="center">
                                        {row?.date ? new Date(row.date).toLocaleDateString('es-AR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                        }) : ''}
                                    </TableCell>                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>

    );
};

export default UserWeightTable;
