import React from "react";
import { TableCell, TableHead as TableHeadMUI, TableRow, TableSortLabel, Typography } from "@mui/material";



const TableHead = ({ headers }) => {
    return (
        <TableHeadMUI >
            <TableRow  >
                {headers?.map((title) => {
                    return (
                        <TableCell>
                            <TableSortLabel>
                                <Typography style={{color:"#767676"}}>
                                    {title}
                                </Typography>
                            </TableSortLabel>
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHeadMUI>
    );
};

export { TableHead };
