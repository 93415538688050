import { APIURL } from "../models/api-base";
import {
  apiUrl_user,
  apiUrl_user_id,
  apiUrl_user_singup,
  apiUrl_user_update,
  apiUrl_user_signin,
  apiUrl_user_validate,
  apiUrl_handleUserPremium,
} from "../models/api-urls";

import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  signin = async (loginUser) => {
    const response = await this.post(apiUrl_user_signin, loginUser);
    return response;
  };

  /**
   * Recibo cantidad de usuarios a mostrar y la pagina a la que pertencen
   * @param {number} page
   * @param {number} rowsPerPage
   * @returns
   */
  getAllUsers = async (page, rowsPerPage, data) => {
    const endpoint = `${apiUrl_user}/${rowsPerPage}/${page}`;
    const response = await this.post(endpoint, data);
    const { users } = response;
    return users;
  };

  getUserById = async (id) => {
    console.log(`${apiUrl_user_id}/${id}`);
    const response = await this.get(`${apiUrl_user_id}/test/${id}`);
    return response.user;
  };

  /**
   * Edito un Usuario
   * @param {string} id
   * @param {object} user
   * @returns
   */
  updateUser = async (id, user) => {
    const response = await this.put(`${apiUrl_user_update}/${id}`, user);
    return response;
  };

  /**
   * Creo un nuevo Usuario
   * @param {object} user
   * @returns
   */
  createUser = async (user) => {
    const response = await this.post(apiUrl_user_singup, user);
    return response;
  };

  validateUser = async () => {
    const response = await this.post(apiUrl_user_validate);
    return response;
  };

  handleUserPremium = async (premium, userId) => {
    try {
      return await this.put(`${apiUrl_handleUserPremium}/${userId}`, {
        premium,
      });
    } catch (e) {
      console.log(e);
      return { error: true };
    }
  };
}

const UserService = new Service();

export default UserService;
