import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Table,
  TableContainer,
  Paper,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { UserTableFooter } from "./UserTableFooter";
import ApiService from "../../../modules/APIService.interceptors";
import { UserTableBody } from "./UserTableBody";
import { TableHead } from "../../TableHead/TableHead";
import Search from "../../../common/Search/Search";

const UserTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loader, setLoader] = useState(false);
  const headersFilters = [
    { label: "Avatar", value: "" },
    { label: "Nombre Apellido", value: "name" },
    { label: "Email", value: "email" },
    { label: "Instagram", value: "instagram" },
    { label: "Teléfono", value: "phone" },
    { label: "DNI", value: "dni" },
    { label: "Dirección", value: "address" },
    { label: "Activa", value: "" },
  ];
  const [filter, setFilter] = useState("name");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Nombre Apellido");
  const [usersWithMembershipCount, setUsersWithMembershipCount] = useState(0);

  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchText(value);
    setPage(0);
    getAllUsers(0, value);
  };

  useEffect(() => {
    getAllUsers(page);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllUsers(newPage);
  };

  const getAllUsers = async (newPage, searchText = "") => {
    try {
      setLoader(true);

      // Verificar valores enviados a la API
      const response = await ApiService.getUsers(
        rowsPerPage,
        newPage,
        filter,
        searchText
      );

      if (!response.error) {
        setUsers(response.data.users);
        console.log("DATA", response);
        setTotalUsers(response.total || totalUsers);
        setUsersWithMembershipCount(response.data.activas);
      } else {
        console.error("Error al obtener usuarios");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  // Manejar el cambio de ordenamiento
  const handleRequestSort = (property) => {
    const validProperties = [
      "Nombre Apellido",
      "Email",
      "Instagram",
      "Teléfono",
      "DNI",
      "Dirección",
    ];

    if (!validProperties.includes(property)) {
      return; // No hacer nada si la propiedad no es válida
    }

    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  // Ordenar usuarios

  const sortedUsers = useMemo(() => {
    return users?.slice().sort((a, b) => {
      let aValue = "";
      let bValue = "";

      // Mapear columnas a las claves reales del objeto
      if (orderBy === "Nombre Apellido") {
        aValue = `${a.name} ${a.lastname}`.toLowerCase();
        bValue = `${b.name} ${b.lastname}`.toLowerCase();
      } else if (orderBy === "Email") {
        aValue = a.email?.toLowerCase() || "";
        bValue = b.email?.toLowerCase() || "";
      } else if (orderBy === "Instagram") {
        aValue = a.instagram?.toLowerCase() || "";
        bValue = b.instagram?.toLowerCase() || "";
      } else if (orderBy === "Teléfono") {
        aValue = a.phone?.toString() || ""; // Asegúrate de que sea una cadena
        bValue = b.phone?.toString() || "";
      } else if (orderBy === "DNI") {
        aValue = a.dni?.toString() || ""; // Convertir números a cadenas
        bValue = b.dni?.toString() || "";
      } else if (orderBy === "Dirección") {
        aValue = a.address?.toLowerCase() || "";
        bValue = b.address?.toLowerCase() || "";
      }

      // Comparar valores para ordenar
      if (order === "asc") {
        return aValue.localeCompare(bValue);
      }
      return bValue.localeCompare(aValue);
    });
  }, [users, order, orderBy]);

  console.log({users})

  return (
    <TableContainer component={Paper}>
      <Box
        sx={{
          paddingRight: "2%",
          textAlign: "end",
          backgroundColor: "#D0749B",
          color: "white",
          fontWeight: "300",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box p={2} width="50%">
          <Search
            searchText={searchText}
            setSearchText={setSearchText}
            color={"#767676"}
            handleSearchChange={handleSearchChange}
            headersFilters={headersFilters}
            filter={filter}
            setFilter={setFilter}
          />
        </Box>
        <Box>
          <Typography color="text.secondary" fontWeight="400">
            Sirenas Activas: {usersWithMembershipCount}
          </Typography>
          <Typography color="whitesmoke" fontWeight="400" fontSize={20}>
            Total Sirenas: {totalUsers}
          </Typography>
        </Box>
      </Box>

      {loader ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Table>
          <TableHead
            headers={headersFilters}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <UserTableBody data={sortedUsers} />

          <UserTableFooter
            rowsPerPage={rowsPerPage}
            page={page}
            totalRows={totalUsers}
            handleChangePage={handleChangePage}
          />
        </Table>
      )}
    </TableContainer>
  );
};

export default UserTable;
