import styled from "styled-components";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css"; // Import default styles
import moment from "moment";

const localizer = momentLocalizer(moment);

const StyledCalendar = styled(Calendar)`
  /* Base styles for the calendar container */
  /* Inherit most styles from default CSS */

  /* Specific styles for weekdays layout */
  .rbc-header .rbc-header-content {
    display: flex; /* Make header content a flexbox */
    justify-content: space-between; /* Distribute weekdays evenly */
  }

  .rbc-header .rbc-header-cell {
    flex: 1; /* Make each weekday cell occupy equal space */
    text-align: center; /* Center weekday text */
  }

  /* Specific styles for day numbers */
  .rbc-month-view .rbc-day {
    text-align: center; /* Center day numbers */
  }

  .rbc-month-view .rbc-day:not(.rbc-off-range) {
    border-bottom: 1px solid #ddd; /* Add a subtle bottom border */
  }

  /* Styles for today's date */
  .rbc-today {
    background-color: #efb6ce !important; /* Set background color for today */
    color: white; /* Text color for today */
  }

  /* Styles for event display */
  .rbc-event {
    background-color: #d0749b; /* Background color of events */
    color: white; /* Text color of events */
    border: none; /* Remove default border */
    border-radius: 4px; /* Optional: Add border radius for events */
    margin: 2px;
  }
`;

export default StyledCalendar;
