import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
} from "@mui/material";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ApiService from "../../modules/APIService.interceptors";
import ConfirmationModal from "../../components/ConfirmationModal/confirmationModal";
import CardMedia from "@mui/material/CardMedia";
import formatDate from "../../common/Format/FormatDate";

const Archives = () => {
  const [archivedLives, setArchivedLives] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [liveToUnarchive, setLiveToUnarchive] = useState(null);

  useEffect(() => {
    getArchivedLives();
  }, []);

  const getArchivedLives = async () => {
    setLoader(true);
    try {
      const response = await ApiService.getArchivedLives(); // Define esta ruta en tu ApiService
      if (!response.error) {
        setArchivedLives(response.data);
      } else {
        console.error("Error al obtener los lives archivados");
      }
    } catch (error) {
      console.error("Error al obtener los lives archivados:", error);
    } finally {
      setLoader(false);
    }
  };

  const unarchiveLive = async () => {
    setLoader(true);
    try {
      const response = await ApiService.unarchiveLive(liveToUnarchive.id); // Define esta ruta en tu ApiService
      if (!response.error) {
        setShowConfirmationModal(false);
        setLiveToUnarchive(null);

        // Remover el elemento desarchivado de la lista
        setArchivedLives((prevLives) =>
          prevLives.filter((live) => live.id !== liveToUnarchive.id)
        );
      }
    } catch (error) {
      console.error("Error al desarchivar el live:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "1%",
          gap: "2%",
          minWidth: "50vh",
        }}
      >
        <Typography variant="h4" color="primary">
          Lives Archivados
        </Typography>

        {archivedLives.length === 0 ? (
          <Typography variant="h6" color="GrayText">
            No hay lives archivados
          </Typography>
        ) : (
          <List dense>
            {archivedLives.map((item) => (
              <ListItem
                key={item.id}
                sx={{ margin: "3% 0" }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="unarchive"
                    onClick={() => {
                      setShowConfirmationModal(true);
                      setLiveToUnarchive(item);
                    }}
                  >
                    <UnarchiveIcon color="primary" />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <CardMedia
                      component="img"
                      height="auto"
                      image={item.thumbnail}
                      alt="Miniatura"
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={item.title}
                  sx={{ color: "black" }}
                  secondary={formatDate(item.createdAt)}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>

      {/* Backdrop de Carga */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      {/* Modal de Confirmación para Desarchivar */}
      <ConfirmationModal
        text={`¿Desarchivar el vivo "${liveToUnarchive?.title}"?`}
        textConfirmation={"Desarchivar"}
        textCancel={"Cancelar"}
        open={showConfirmationModal}
        showConfirmationButton={true}
        showCancelButton={true}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={unarchiveLive}
      />
    </>
  );
};

export default Archives;
