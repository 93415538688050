import {
  Box,
  Button,
  CardActions,
  IconButton,
  Typography,
  Card,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CardWorkshopTitle from "../../components/CardWorkshopTitle/CardWorkshopTitle";
import CardWorkshopDate from "../../components/CardWorkshopDate/CardWorkshopDate";
import CardWorkshopDetails from "../../components/CardWorkshopDetails/CardWorkshopDetails";
import CardWorkshopInvite from "../../components/CardWorkshopInvite/CardWorkshopInvite";
import CardWorkshopUsers from "../../components/CardWorkshopUsers/CardWorkshopUsers";
import { useEffect, useState } from "react";
import { Await, useParams, useNavigate } from "react-router-dom";
import formatDateTwo from "../../common/Format/FormatDateTwo";
import ApiService from "../../modules/APIService.interceptors";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import SelectSmall from "../../components/SelectSmall/SelectSmall";
import ConfirmationModal from "../../components/ConfirmationModal/confirmationModal";
import AddVideo from "../../components/AddVideo/AddVideo";
import FileUploadButton from "../../components/AddVideo/AddVideo";
import LiveVideoCard from "../../common/LiveVideoCard/LiveVideoCard";
import SnackBar from "../../common/SnackBar/SnackBar";

const styles = {
  icon: {
    color: "white",
  },
};

const Taller = () => {
  const [lives, setLives] = useState([]);
  const [guests, setGuests] = useState([]);
  const [users, setUsers] = useState([]);
  const [startDate, setStartDate] = useState(Date);
  const [endDate, setEndDate] = useState(Date);
  const [observacion, setObservacion] = useState("Sin ninguna observacion");
  const [instructor, setInstructor] = useState("Agostina");
  const [numWorkshop, setWorkshopNum] = useState(null);
  const [workshopStatus, setWorkshopStatus] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { workshopId } = useParams();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectVideo, setSelectVideo] = useState(null);
  const [getMiniature, setGerMiniature] = useState("");
  const [arg, setArg] = useState(0);
  const [usd, setUsd] = useState(0);
  const [loader, setLoader] = useState(false);
  const [messageModal, setMessageModal] = useState("no message");
  const [severity, setSeverity] = useState("info");
  const [openModal, setOpenModal] = useState(false);
  const [isHorizontal, setIsHorizontal] = useState(
    window.innerWidth > window.innerHeight
  );
  // controlo que el workshop al que estoy accediendo exista
  const [workshopDeleted, setWorkshopDeleted] = useState(false);
  const navigate = useNavigate();
  const [statusLabel, setStatusLabel] = useState("");

  const goToWorkshops = () => {
    navigate(`/talleres/`);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsHorizontal(window.innerWidth > window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    setGerMiniature(selectedFile);
  }, []);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    const getLives = async () => {
      if (workshopId) {
        const data = await ApiService.getLivesWorkshopById(workshopId);
        setLives(data.lives.sort((a, b) => a.id - b.id));
      }
    };
    const getWorkshop = async () => {
      setLoader(true)
      try {
        const workshopRequest = await ApiService.getWorkshopById(workshopId);
        if (!workshopRequest.error) {
          // setGuests(workshopRequest.guests)  // modificar para traer el los invitados reales cuando este disponible
          setStartDate(workshopRequest.data.dateStart);
          setEndDate(workshopRequest.data.dateEnd);
          setWorkshopNum(workshopRequest.data.workshopNumber);
          setInstructor(workshopRequest.data.instructor);
          setObservacion(workshopRequest.data.observation);
          setStatus(workshopRequest.data.status);
          setWorkshopStatus(workshopRequest.data.status);
          setArg(workshopRequest.data.priceARS);
          setUsd(workshopRequest.data.priceUSD);
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoader(false)
      }
    };

    const getUsers = async () => {
      try {
        const response = await ApiService.getUsersWorkshop(workshopId);
      } catch (error) {
        console.log("Error al traer usuarios del taller");
      }
    };
    getWorkshop();
    getLives();
    getUsers();

    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, [workshopId]);

  const [status, setStatus] = useState({
    editing: false,
    loading: false,
    showBtn: false,
  });

  const handleConfirm = async () => {
    setStatus({ ...status, loading: true });
    console.log("Linea 145")
    await updateOneWorkshop();
    console.log("Linea 147")
    setStatus({ editing: false, loading: false, showBtn: false });
  };

  const handleEdit = () => {
    setStatus({
      ...status,
      editing: true,
      loading: false,
      showBtn: true,
    });
  };

  const handleCancel = () => {
    setInstructor("Agostina");
    setObservacion();
    setStatus({
      ...status,
      editing: false,
      loading: false,
      showBtn: false,
    });
  };

  const OpenModal = async () => {
    setShowConfirmationModal(true);
  };


  ////////UPDATE WORSHOP//////////
  const updateOneWorkshop = async () => {
    setLoader(true)
    const updatedWorkshop = {
      workshopNumber: parseInt(numWorkshop),
      dateStart: startDate,
      dateEnd: endDate,
      instructor: instructor,
      observation: observacion,
      status: workshopStatus,
      inviteId: 1,
      priceARS: arg ? parseFloat(arg?.toString().replace(/,/g, '')) : 0,
      priceUSD: usd ? parseFloat(usd?.toString().replace(/,/g, '')) : 0
    };
    try {
      const response = await ApiService.updateWorkshop(workshopId, updatedWorkshop);
      if (!response.error) {
        setOpenModal(true);
        setSeverity("success");
        setMessageModal("El taller fue actualizado correctamente.");
      }
    } catch (error) {
      setOpenModal(true);
      setSeverity("error");
      setMessageModal("El taller no pudo ser actualizado.");
    } finally {
      setLoader(false)
    }

  };

  /////////////DELETE WORKSHOP////////////
  const handleConfirmDelete = async () => {
    setLoader(true)
    try {
      const workshopDetails = await ApiService.deleteWorkshopById(workshopId);
      if (workshopDetails) {
        setWorkshopDeleted(true);
        setShowConfirmationModal(false);
        OpenModal();
        console.log(workshopDetails);
      } else {
        console.error("error haciendo el fetch");
      }
    } catch (error) {
      console.error("error eliminando el Taller", error);
    } finally {
      setLoader(false)
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackBar
        message={messageModal}
        setOpen={setOpenModal}
        open={openModal}
        severity={severity}
      />
      <Box
        sx={{
          justifyContent: "center",
          width: "100%",
          maxHeight: "100vh",
          maxWidth: "100vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isHorizontal ? "row" : "column",
            width: "100%",
            gap: "10px",
          }}
        >
          <Card
            sx={{
              p: "1rem 2rem 1rem 2rem",
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              backgroundColor: "white",
            }}
          >
            <Typography variant="h4" color="primary">
              Taller N°: {numWorkshop}
            </Typography>
            {/* <Typography variant="h4" color='white' >Sirenas: {numMermaids}</Typography> */}
            <SelectSmall
              isEditing={status?.editing}
              status={workshopStatus}
              setStatusWorkshop={setWorkshopStatus}
              setStatusLabel={setStatusLabel}
            />
          </Card>

          <CardActions
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "fixed",
              right: "1%",
              bottom: "3%",
              zIndex: 100,
              backgroundColor: "white",
              borderRadius: 5,
            }}
          >
            {!status?.showBtn && (
              <IconButton
                variant="contained"
                onClick={handleEdit}
                size="large"
                sx={{ background: "#D0749B" }}
              >
                <EditIcon style={styles.icon} />
              </IconButton>
            )}
            {status?.showBtn && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: isHorizontal ? "column" : "row",
                  gap: 1,
                }}
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  disabled={status?.loading}
                  startIcon={<CancelIcon />}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={() => handleConfirm()}
                  startIcon={<CheckIcon />}
                  disabled={status?.loading}
                  variant="contained"
                >
                  Confirmar
                </Button>
                <Button
                  onClick={OpenModal}
                  startIcon={<DeleteIcon style={styles?.icon} />}
                  variant="contained"
                  color="secondary"
                >
                  <Typography style={styles?.icon}>Eliminar taller</Typography>
                </Button>
                <ConfirmationModal
                  text={"Esta usted segura que desea eliminar este taller?"}
                  textConfirmation={"Si"}
                  textCancel={"No"}
                  open={showConfirmationModal}
                  showConfirmationButton={true}
                  showCancelButton={true}
                  onClose={() => setShowConfirmationModal(false)}
                  onConfirm={handleConfirmDelete}
                />
              </Box>
            )}
          </CardActions>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CardWorkshopDate
            height={"100%"}
            dateStart={startDate}
            dateEnd={endDate}
            isEditing={status?.editing}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: isHorizontal ? "row" : "column",
              mt: "10px",
              gap: isHorizontal ? "2.5%" : "0px",
              maxHeight: "100vh",
              height: "100%",
              alignItems: "flex-start",
            }}
          >
            <CardWorkshopDetails
              observacion={observacion}
              instructor={instructor}
              setObservacion={setObservacion}
              setInstructor={setInstructor}
              isEditing={status?.editing}
              argPrice={arg}
              usdPrice={usd}
              setArgPrice={setArg}
              setUsdPrice={setUsd}
            />
            {/* <CardWorkshopInvite
              width={isHorizontal ? "50%" : "100%"}
              guests={guests}
              setGuests={setGuests}
            /> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isHorizontal ? "row" : "column",
              mt: "10px",
              gap: "10px",
              width: "100%",
              maxWidth: "100vw",
            }}
          >
            <CardWorkshopUsers width={"100%"} />
          </Box>
        </Box>
        {workshopDeleted ? (
          <ConfirmationModal
            text={"Taller elimimnado con exito"}
            textConfirmation={"Aceptar"}
            textCancel={"No"}
            open={showConfirmationModal}
            showConfirmationButton={true}
            showCancelButton={false}
            // onClose={}
            onConfirm={() => goToWorkshops()}
          />
        ) : null}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box display={"flex"} marginTop={2.5}>
            <FileUploadButton
              numWorkshop={numWorkshop}
              workshopId={workshopId}
            />
          </Box>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: "36px",
              justifyContent: "center",
            }}
          >
            {lives?.map((live, index) => {
              return <LiveVideoCard live={live} key={index} />;
            })}
          </div>
        </div>
      </Box>

      {/*  */}

      {/* <Box display={"flex"} m={10} style={{margin: '10 auto'}}>
          <FileUploadButton numWorkshop={numWorkshop} workshopId={workshopId} />
        </Box>   */}
    </>
  );
};
export default Taller;
