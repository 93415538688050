import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../store/context/CartContext";
import SnackBar from "../../common/SnackBar/SnackBar";
import { Box, Card } from "@mui/material";
import UserTable from "../../components/UserTable/UserTable/UserTable";
import NotAllowed from "../NotAllowed/NotAllowed";
import { useAuthStore } from "../../store/zustand/authStore";

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const Users = () => {
  // const { setTitleSeccion } = useContext(CartContext);
  const [messageModal, setMessageModal] = useState("");
  const [openModal, setOpenModal] = useState("");
  const [severity, setSeverity] = useState("");
  const [tab, setTab] = useState(0);
  const user = useAuthStore((state) => state.user);
  const { setTitleSeccion } = useContext(CartContext);

  useEffect(() => {
    setTitleSeccion("Sirenas");
  }, []);

  return (
    <>
      <NotAllowed user={user} page={"USERS"} />

      <SnackBar
        message={messageModal}
        setOpen={setOpenModal}
        open={openModal}
        severity={severity}
      />

      <Card sx={{ backgroundColor: "#D0749B", marginBottom: 2 }}>
        <TabPanel value={tab} index={0}>
          <UserTable />
        </TabPanel>
      </Card>
    </>
  );
};

export default Users;
