import React from 'react';
import {
    TextField,
    Button,
    Container,
    Typography,
    Grid,
    Box,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const RecipeForm = ({ onClose, handleInputChange, submit, formValues, handleFileChange }) => {

    return (
        <Container maxWidth="sm">
            <Box sx={{backgroundColor: 'white', padding: '5% 5%', borderRadius: 5 }}>
                <Box display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
                <Typography variant="h4" gutterBottom color={'black'}>
                    Nueva Receta
                </Typography>
                <IconButton onClick={onClose}>
                  <CloseIcon color="primary"/>
                </IconButton>
                </Box>
                <form onSubmit={submit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Titulo"
                                name="title"
                                fullWidth
                                value={formValues.title}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Subtitulo"
                                name="subtitle"
                                fullWidth
                                value={formValues.subtitle}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* Replace TextField with input type="file" */}
                            <input
                                accept="image/*"
                                id="image-upload"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor="image-upload">
                                <Button variant="contained" component="span" fullWidth>
                                    {formValues.image ? 'Cambiar Portada' : 'Subir Portada'}
                                </Button>
                            </label>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Link de la publicacion"
                                name="link"
                                fullWidth
                                value={formValues.link}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Descipcion"
                                name="description"
                                fullWidth
                                multiline
                                rows={4}
                                value={formValues.description}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Autor"
                                name="autor"
                                fullWidth
                                value={formValues.autor}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>)
}

export default RecipeForm