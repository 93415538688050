import { APIURL } from "../models/api-base";
import { apiUrl_calendar } from "../models/api-urls";
import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  /**
   * Traer todos los eventos
   */
  getCalendarEvents = async () => {
    const response = await this.get(apiUrl_calendar);
    return response;
  };

  /**
   * Traer todos los eventos
   */
  getWorkshopsDates = async () => {
    const endpoint = apiUrl_calendar + "/workshops";
    const response = await this.get(endpoint);
    return response;
  };

  /**
   * crear evento para el calendario
   * @param {calendarEvent}
   */
  createCalendarEvent = async (calendarEvent) => {
    const response = await this.post(apiUrl_calendar, calendarEvent);
    return response;
  };

  /**
   * crear evento para el calendario
   * @param {calendarEvent}
   */
  createCalendarEventArray = async (calendarEvents) => {
    const endpoint = apiUrl_calendar + "/array";
    const response = await this.post(endpoint, calendarEvents);
    return response;
  };

  /**
   *  Update de un evento
   * @param {number} id
   */
  updateCalendarEvent = async (id, update) => {
    const response = await this.put(apiUrl_calendar + "/" + id, update);
    return response;
  };
  /**
   * 
   * Elimina un los evento
   * @param {number} id
   * @returns
   */
  deleteCalendarEvent = async (id) => {
    const endpoint = apiUrl_calendar + "/" + id;
    const response = await this.delete(endpoint);
    return response;
  };
}

const CalendarService = new Service();

export default CalendarService;
