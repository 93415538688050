import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import "./styles.css";
import { useAuthStore } from "../../store/zustand/authStore";
import CloseIcon from "@mui/icons-material/Close";

const EditProfileModal = (props) => {
  const { openModal, onCloseModal, onSumbit, errorModal } = props;
  const user = useAuthStore((state) => state.user);
  const [formData, setFormData] = useState(user || {});

  useEffect(() => {
    if (user) {
      const {
        id,
        admin_users,
        uid,
        premium,
        occupation,
        idDuo,
        instagram,
        avatar,
        ...userWithoutId
      } = user;
      setFormData({
        ...userWithoutId,
        password: "", // Asegúrate de limpiar la contraseña también
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    onSumbit(formData);
  };

  return (
    <Modal open={openModal} onClose={onCloseModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: "25px 30px",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 3,
          }}
        >
          <Typography variant="h6" component="h2">
            Editar Perfil
          </Typography>
          <IconButton onClick={onCloseModal} color="primary">
            <CloseIcon />
          </IconButton>
        </Box>
        <TextField
          name="name"
          required
          label="Nombre"
          fullWidth
          margin="normal"
          value={formData.name || ""}
          onChange={handleChange}
        />
        <TextField
          name="lastname"
          label="Apellido"
          fullWidth
          required
          margin="normal"
          value={formData.lastname || ""}
          onChange={handleChange}
        />
        <TextField
          autoComplete="new-password"
          name="email"
          label="Correo Electrónico"
          type="email"
          fullWidth
          required
          margin="normal"
          value={formData.email || ""}
          onChange={handleChange}
        />
        <TextField
          name="phone"
          label="Teléfono"
          type="number"
          fullWidth
          margin="normal"
          required
          value={formData.phone || ""}
          onChange={handleChange}
        />
        <TextField
          autoComplete="new-password"
          name="password"
          label="Contraseña"
          type="password"
          fullWidth
          required
          margin="normal"
          value={formData.password || ""}
          onChange={handleChange}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={handleSubmit}
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
          >
            Guardar
          </Button>
          {errorModal && (
            <Typography variant="body2" color="error" sx={{ mt: 1.7 }}>
              Todos los campos son requeridos.
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EditProfileModal;
