export function timestampToMinutesAndSeconds(timestamp) {
  const totalSeconds = Math.floor(timestamp / 1000); // Convierte el timestamp a segundos
  const minutes = Math.floor(totalSeconds / 60); // Calcula los minutos
  const remainingSeconds = totalSeconds % 60; // Calcula los segundos restantes

  // Asegura que los minutos y segundos tengan dos dígitos
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
  if (formattedMinutes && formattedMinutes) {
    return `${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `00:00`;
  }
}
export const createMutedMessage = (until) => {
  const newMessage = {
    createdAt: new Date(),
    message:
      "Has sido silenciado, te faltan " + timestampToMinutesAndSeconds(until),
    name: "Moderador",
    deleted: false,
    avatar: "",
    userId: 0,
  };
  return newMessage;
};
