import React, { useState } from "react";
import {
  Typography,
  Box,
  Backdrop,
  Button,
  Avatar,
  CardMedia,
  TextField,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import ApiService from "../../modules/APIService.interceptors";

const EditLiveModal = ({ live, onCancelEdit, isEditing, setIsEditing }) => {
  const [thumbnail, setThumbnail] = useState(live.thumbnail);
  const [imageFile, setImageFile] = useState(live.thumbnail);
  const [title, setTitle] = useState(live.title);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setThumbnail(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleConfirmEdit = async () => {
    if (imageFile !== live.thumbnail || title !== live.title) {
      setLoader(true);
      try {
        // Si la imagen ha cambiado, actualiza el thumbnail
        if (imageFile !== live.thumbnail) {
          const formData = new FormData();
          formData.append("thumbnail", imageFile);

          const responseImg = await ApiService.updateLiveThumbnail(
            live.id,
            formData
          );
        }
        // Si el título ha cambiado, actualiza el título
        if (title !== live.title) {
          const responseTitle = await ApiService.updateLiveName(live.id, {
            title: title,
            streamingDate: new Date(),
          });
        }
        setIsEditing(false);
      } catch (error) {
        console.error("Error al actualizar:", error);
      } finally {
        setLoader(false);
      }
    }
  };
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isEditing}
    >
      <Box
        sx={{
          background: "white",
          minWidth: "45vh",
          maxHeight: "55vh",
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "3.5vh 1vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="upload-image"
            onChange={handleImageChange}
          />
          <label htmlFor="upload-image">
            <IconButton
              component="span"
              sx={{
                backgroundColor: "white",
              }}
            >
              <EditIcon color="primary" />
            </IconButton>
          </label>
          <Avatar
            sx={{
              width: 140,
              height: 140,
              margin: "0 0 2vh 0",
              position: "relative",
            }}
          >
            <CardMedia
              component="img"
              sx={{ width: "100%", height: "100%" }}
              image={thumbnail}
              alt="Miniatura"
            />
          </Avatar>
          {isEditingTitle ? (
            <TextField
              value={title}
              onChange={handleTitleChange}
              onBlur={() => setIsEditingTitle(false)}
              autoFocus
              variant="standard"
              sx={{ marginBottom: "4vh" }}
            />
          ) : (
            <Typography
              sx={{
                color: "GrayText",
                marginBottom: "4vh",
                cursor: "pointer",
                borderBottom: 2,
                borderBottomColor: "#D0749B",
              }}
              variant="h6"
              onClick={() => setIsEditingTitle(true)}
            >
              {title}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "80%",
          }}
        >
          <Button
            variant="contained"
            onClick={handleConfirmEdit}
            color="primary"
          >
            Confirmar
          </Button>
          <Button variant="outlined" onClick={onCancelEdit} color="primary">
            Cancelar
          </Button>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Backdrop>
  );
};

export default EditLiveModal;
