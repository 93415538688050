import React, { useEffect, useState } from "react";

import "./styles.css";
import { useSocketStore } from "../../store/zustand/socketStore";
import { TOKENSTORAGE } from "../../models/constants";
import ChannelsContainer from "../../components/ChannelsContainer/ChannelsContainer";
import { Button, Stack } from "@mui/material";

const Channels = () => {
  const connectChannelSocket = useSocketStore(
    (state) => state.connectChannelSocket
  );
  const disconnectChannelSocket = useSocketStore(
    (state) => state.disconnectChannelSocket
  );
  const token = localStorage.getItem(TOKENSTORAGE);

  // Estado para el canal seleccionado
  const [channelName, setChannelName] = useState("COMUNIDAD");

  useEffect(() => {
    if (!token) return;
    connectChannelSocket(token); // Conecta al socket del canal al entrar

    return () => {
      disconnectChannelSocket(); // Desconecta al salir
    };
  }, [connectChannelSocket, disconnectChannelSocket, token]);

  // Función para manejar el cambio de canal
  const handleChannelChange = (newChannelName) => {
    setChannelName(newChannelName);
  };

  // Función para determinar si un botón está seleccionado
  const isSelected = (currentChannel) => channelName === currentChannel;

  return (
    <>
      <Stack spacing={2} direction="row" sx={{ marginBottom: 2 }}>
        <Button
          variant={isSelected("COMUNIDAD") ? "contained" : "outlined"}
          color={isSelected("COMUNIDAD") ? "primary" : "inherit"}
          onClick={() => handleChannelChange("COMUNIDAD")}
        >
          Comunidad
        </Button>
        <Button
          variant={isSelected("RECETAS") ? "contained" : "outlined"}
          color={isSelected("RECETAS") ? "primary" : "inherit"}
          onClick={() => handleChannelChange("RECETAS")}
        >
          Recetas
        </Button>
        <Button
          variant={isSelected("EJERCICIOS") ? "contained" : "outlined"}
          color={isSelected("EJERCICIOS") ? "primary" : "inherit"}
          onClick={() => handleChannelChange("EJERCICIOS")}
        >
          Ejercicios
        </Button>
      </Stack>
      <ChannelsContainer channelName={channelName} />
    </>
  );
};

export default Channels;
