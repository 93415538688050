import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CardContent, Card, Divider } from '@mui/material';


const formatDate = (dateString) => {
    const date = new Date(dateString);
    console.log("date ========>", date)
    const day = String(date.getDate()).padStart(2, '0'); // Asegura que sea de 2 dígitos
    console.log("=======>", day)
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes es 0 indexado
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const UserWeightTable = ({ data }) => {
    return (
        <Card>

            <CardContent>
                <Typography variant="h5" color={"#767676"}>Registro de peso</Typography>
                <Divider sx={{ mb: 1 }} />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Pero Actual</TableCell>
                                <TableCell align="left">Peso Objetivo</TableCell>
                                <TableCell align="center">Fecha de Registro</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{row?.current_weight}Kg</TableCell>
                                    <TableCell align="left">{row?.objective_weight}Kg</TableCell>
                                    <TableCell align="center">{row?.date?.split('-')?.reverse()?.join('/')}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>

    );
};

export default UserWeightTable;
