import axios from "axios";
import { APIURL } from "../models/api-base";
import {
  apiUrl_payment_cancel_tranfer,
  apiUrl_payment_confirm_tranfer,
  apiUrl_payment_tranfer,
  apiUrl_user,
  apiUrl_user_id,
  apiUrl_user_singup,
  apiUrl_user_update,
  apiUrl_workshop,
  apiUrl_workshop_lives,
  apiUrl_workshop_users,
  all_payments,
  apiUrl_payment_user,
  apiUrl_lives,
  apiUrl_update_thumbnail_live,
  apiUrl_user_update_status,
} from "../models/api-urls";
import fetchService from "./APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  // Pagos <===================

  //Al Payments
  allPayments = async ({
    amount,
    page,
    paymentMethod,
    paymentStatus,
    search,
  }) => {
    const response = await this.get(
      `${all_payments}${amount}/${page}/${paymentMethod}/${paymentStatus}?search=${search}`
    );
    return response;
  };

  userPayments = async (id) => {
    const response = await this.get(apiUrl_payment_user + id);
    return response;
  };

  /**
   * Confimo Pago de transferencia
   * @param {number} idPayment
   * @returns
   */
  confirmPaymentToTransfer = async (idPayment) => {
    const response = await this.put(apiUrl_payment_confirm_tranfer + idPayment);
    return response;
  };

  /**
   * Rechazo pago de transferencia
   * @param {number} idPayment
   * @returns
   */
  cancelPaymentToTransfer = async (idPayment) => {
    const response = await this.put(apiUrl_payment_cancel_tranfer + idPayment);
    return response;
  };

  /**
   * Traer los pagos por transferencias
   * @param {string} key pending,approved,rejected
   * @returns { array | boolean }
   */
  getAllPaymentsTransfer = async (key) => {
    let response = false;
    if (
      key !== "all" &&
      key !== "pending" &&
      key !== "approved" &&
      key !== "rejected"
    ) {
      console.error("Parametro no existe");
    } else {
      response = await this.get(apiUrl_payment_tranfer + key);
    }
    return response;
  };

  // Usuarios <===================

  /**
   * Recibo cantidad de usuarios a mostrar y la pagina a la que pertencen
   * @param {number} page
   * @param {number} rowsPerPage
   * @returns
   */
  getAllUsers = async () => {
    const endpoint = `${apiUrl_user}`;
    const response = await this.get(endpoint);
    return response;
  };

  getUserById = async (id) => {
    const response = await this.get(`${apiUrl_user_id}/${id}`);
    return response.user;
  };

  /**
   * Edito un Usuario
   * @param {string} id
   * @param {object} user
   * @returns
   */
  updateUser = async (id, user) => {
    const response = await this.put(`${apiUrl_user_update}/${id}`, user);
    return response;
  };

  /**
   * Creo un nuevo Usuario
   * @param {object} user
   * @returns
   */
  createUser = async (user) => {
    const response = await this.post(apiUrl_user_singup, user);
    return response;
  };

  /**
   * Paso usuario a Admin
   * @param {string} id
   * @param {object} user
   * @returns
   */
  updateStatusUser = async (id, admin) => {
    console.log("135 =====>", admin, id);
    const response = await this.put(`${apiUrl_user_update_status}/${id}`, {
      admin,
    });
    return response;
  };

  // Talleres <===================

  getUsersWorkshop = async (workshopId) => {
    const response = await this.get(`${apiUrl_workshop_users}${workshopId}`);
    return response;
  };

  getAllWorkshops = async () => {
    const response = await this.get(apiUrl_workshop);
    return response;
  };

  getAllLives = async () => {
    const response = await this.get(apiUrl_lives);
    return response;
  };

  getWorkshopById = async (id) => {
    const response = await this.get(`${apiUrl_workshop}/${id}`);
    return response;
  };

  getLivesWorkshopById = async (id) => {
    const response = await this.get(`${apiUrl_workshop_lives}/${id}`);
    return response.data;
  };

  /**
   * Creo un nuevo taller
   * @param {object} workshop
   * @returns
   */
  createWorkshop = async (workshop) => {
    const response = await this.post(apiUrl_workshop, workshop);
    return response;
  };

  /**
   * Edito un taller
   * @param {object} workshop
   * @returns
   */
  updateWorkshop = async (id, workshop) => {
    const response = await this.put(`${apiUrl_workshop}/${id}`, workshop);
    console.log(`${apiUrl_workshop}/${id}`, workshop);
    return response;
  };

  updateStatusWorkshop = async (key, id) => {
    if (key !== "pending" && key !== "approved" && key !== "rejected") {
      console.error("Parametro no existe");
      return false;
    } else {
      const response = await this.put(`${apiUrl_workshop}/${id}/${key}`);
      return response;
    }
  };

  deleteWorkshopById = async (id) => {
    const response = await this.delete(`${apiUrl_workshop}/${id}`);
    return response;
  };

  deleteLive = async (id) => {
    const response = await this.delete(`${apiUrl_workshop_lives}/${id}`);
    return response;
  };

  archiveLive = async (id) => {
    const response = await this.put(`${APIURL}${apiUrl_lives}/archive/${id}`, {
      archive: true,
    });
    return response;
  };

  unarchiveLive = async (id) => {
    const response = await this.put(`${APIURL}${apiUrl_lives}/archive/${id}`, {
      archive: false,
    });
    return response;
  };
  getArchivedLives = async () => {
    const response = await this.get(`${APIURL}${apiUrl_lives}/archives`);
    return response;
  };

  updateLiveName = async (id, item) => {
    console.log(`${apiUrl_lives}/${id}`, item);
    const response = await this.put(
      `${APIURL}${apiUrl_lives}/update/${id}`,
      item
    );
    return response;
  };

  updateLiveThumbnail = async (id, item) => {
    console.log(`${apiUrl_update_thumbnail_live}/${id}`, item);
    const response = await this.put(
      `${apiUrl_update_thumbnail_live}/${id}`,
      item
    );
    return response;
  };
}

const ApiService = new Service();

export default ApiService;
