import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  CardMedia,
  Snackbar,
  Alert,
} from "@mui/material";
import { CartContext } from "../../store/context/CartContext";
import ApiService from "../../modules/APIService.interceptors";
import formatDate from "../../common/Format/FormatDate";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import ConfirmationModal from "../../components/ConfirmationModal/confirmationModal";
import EditLiveModal from "../../components/EditLiveModal/EditLiveModal";
import Lives from "../Lives/Lives";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import PsychologyIcon from "@mui/icons-material/Psychology";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import HealingIcon from "@mui/icons-material/Healing";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import StarIcon from "@mui/icons-material/Star";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import Avatar from "@mui/material/Avatar";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import HandshakeIcon from "@mui/icons-material/Handshake";
import NutricionPortada from "../../assets/nutricionFondo.png";
import DermatologiaPortada from "../../assets/dermatologia.png";
import MotivacionalParejasPortada from "../../assets/motivacionalParejas.png";
import EntrenamientoPortada from "../../assets/entrenamiento.png";
import EndocrinologiaPortada from "../../assets/endocrinologia.png";
import PsicologiaPortada from "../../assets/psicologia.png";
import GinecologiaPortada from "../../assets/ginecologia.png";
import MedicinaFuncionalPortada from "../../assets/medicinaFuncional.png";
import CardiologiaPortada from "../../assets/cardiologia.png";
import PsiquiatriaPortada from "../../assets/pisiquiatria.png";
import { useAuthStore } from "../../store/zustand/authStore";
import NotAllowed from "../NotAllowed/NotAllowed";

const Categories = () => {
  const user = useAuthStore((state) => state.user);
  const { setTitleSeccion } = useContext(CartContext);
  const [newLiveModal, setNewLiveModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [lives, setLives] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [liveToDelete, setLiveToDelete] = useState(null);
  const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] =
    useState(false);
  const [liveToArchive, setLiveToArchive] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [liveToEdit, setLiveToEdit] = useState(null);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");

  const getAllLives = async () => {
    setLoader(true);
    try {
      const response = await ApiService.getAllLives();
      if (!response.error) {
        setLives(response.data);
      } else {
        console.error("error");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const getWorkshopStatus = async () => {
    try {
      const response = await ApiService.getWorkshopStatus();
      checkToastForWorkshopStatus(response);
    } catch (error) {
      console.error("ERROR TRAYENDO LOS WORKSHOP STATUS", error);
    }
  };

  const showToast = (message, severity) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
  };

  const handleToastClose = () => {
    setToastOpen(false);
  };

  const checkToastForWorkshopStatus = (data) => {
    if (!data) return;
    const today = new Date().toISOString().split("T")[0]; // Obtener la fecha de hoy en formato YYYY-MM-DD

    for (const key of ["ERROR", "PROGRESSING"]) {
      if (data[key] && data[key].aditionalInfo.length > 0) {
        data[key].aditionalInfo.forEach((info) => {
          key === "ERROR"
            ? showToast(`Hubo un error al subir el vivo "${info}".`, "error")
            : showToast(`El vivo "${info}" está en proceso de subida.`, "info");
        });
      }
    }

    if (data.COMPLETE && data.COMPLETE.aditionalInfo.length > 0) {
      data.COMPLETE.aditionalInfo.forEach((info, index) => {
        const uploadDate = data.COMPLETE.createdAt[index].split("T")[0]; // Obtener solo la fecha (YYYY-MM-DD)
        if (uploadDate === today) {
          showToast(`El vivo "${info}" se subió correctamente.`, "success");
        }
      });
    }
  };

  useEffect(() => {
    getWorkshopStatus();
  }, []);

  const deleteLive = async () => {
    setLoader(true);
    try {
      const response = await ApiService.deleteLive(liveToDelete.id);
      if (!response.error) {
        setShowConfirmationModal(false);
        setLiveToDelete(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const archiveLive = async () => {
    setLoader(true);
    try {
      const response = await ApiService.archiveLive(liveToArchive.id);
      if (!response.error) {
        setShowArchiveConfirmationModal(false);
        setLiveToArchive(null);

        // Remover el elemento archivado del estado `lives`
        setLives((prevLives) =>
          prevLives.filter((live) => live.id !== liveToArchive.id)
        );

        // Si una categoría está seleccionada, remover el elemento del estado `selectedCategory`
        if (selectedCategory) {
          setSelectedCategory((prevCategory) => ({
            ...prevCategory,
            lives: prevCategory.lives.filter(
              (live) => live.id !== liveToArchive.id
            ),
          }));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setTitleSeccion("Categorias");
    getAllLives();
  }, []);

  const categorizeLives = (lives, categoryName) => {
    return lives
      ?.filter((live) => live.category === categoryName)
      ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Ordenar por fecha descendente
  };

  const livesCategories = [
    {
      name: "Nutrición",
      color: "#F69195",
      portada: NutricionPortada,
      lives: categorizeLives(lives, "Nutrición"),
      icon: LocalDiningIcon,
    },
    {
      name: "Dermatología",
      color: "#DAA1BB",
      portada: DermatologiaPortada,
      lives: categorizeLives(lives, "Dermatología"),
      icon: HealingIcon,
    },
    {
      name: "Motivacional Parejas",
      color: "#F3B98D",
      portada: MotivacionalParejasPortada,
      lives: categorizeLives(lives, "Motivacional Parejas"),
      icon: FavoriteIcon,
    },
    {
      name: "Entrenamiento",
      color: "#B9DBCD",
      portada: EntrenamientoPortada,
      lives: categorizeLives(lives, "Entrenamiento"),
      icon: FitnessCenterIcon,
    },
    {
      name: "Endocrinología",
      color: "#DAA1BB",
      portada: EndocrinologiaPortada,
      lives: categorizeLives(lives, "Endocrinología"),
      icon: MedicalInformationIcon,
    },
    {
      name: "Psicología",
      color: "#B0A1A3",
      portada: PsicologiaPortada,
      lives: categorizeLives(lives, "Psicología"),
      icon: PsychologyIcon,
    },
    {
      name: "Ginecología",
      color: "#E6AB93",
      portada: GinecologiaPortada,
      lives: categorizeLives(lives, "Ginecología"),
      icon: AdminPanelSettingsIcon,
    },
    {
      name: "Medicina Funcional",
      color: "#D4C7C9",
      portada: MedicinaFuncionalPortada,
      lives: categorizeLives(lives, "Medicina Funcional"),
      icon: HealthAndSafetyIcon,
    },
    {
      name: "Cardiología",
      color: "#B9DBCD",
      portada: CardiologiaPortada,
      lives: categorizeLives(lives, "Cardiología"),
      icon: MonitorHeartIcon,
    },
    {
      name: "Psiquiatría",
      color: "#D09267",
      portada: PsiquiatriaPortada,
      lives: categorizeLives(lives, "Psiquiatría"),
      icon: PsychologyAltIcon,
    },
    {
      name: "Especial de Nutrición",
      color: "#F69195",
      portada: NutricionPortada,
      lives: categorizeLives(lives, "Especial de Nutrición"),
      icon: StarIcon,
    },
    {
      name: "Motivacionales",
      color: "#F3B98D",
      portada: MotivacionalParejasPortada,
      lives: categorizeLives(lives, "Motivacionales"),
      icon: VolunteerActivismIcon,
    },
    {
      name: "Vivos cocina",
      color: "#E6AB93",
      portada: GinecologiaPortada,
      lives: categorizeLives(lives, "Vivos cocina"),
      icon: SoupKitchenIcon,
    },
    {
      name: "Neurología",
      color: "#B9DBCD",
      portada: EntrenamientoPortada,
      lives: categorizeLives(lives, "Neurología"),
      icon: PersonSearchIcon,
    },
    {
      name: "Duplex Sirena",
      color: "#F3B98D",
      portada: MotivacionalParejasPortada,
      lives: categorizeLives(lives, "Duplex sirena"),
      icon: HandshakeIcon,
    },
    {
      name: "Invitados Especiales",
      color: "#F69195",
      portada: NutricionPortada,
      lives: categorizeLives(lives, "Invitados Especiales"),
      icon: Diversity1Icon,
    },
  ];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleButtonClick = () => {
    setNewLiveModal(true);
  };

  const handleClose = () => {
    setNewLiveModal(false);
  };

  const handleCloseModalCategory = () => {
    setSelectedCategory(null);
  };

  const onCancelEdit = () => {
    setLiveToEdit(null);
    setIsEditing(false);
  };

  return (
    <>
      <NotAllowed user={user} page={"LIVES"} />
      {user?.admin_users?.LIVES.READ[0] && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1%",
            gap: "4%",
          }}
        >
          {/* Categorías */}
          {livesCategories?.map((item, index) => {
            return (
              <CategoryCard
                key={index}
                name={item.name}
                color={item.color}
                portada={item.portada}
                lives={item.lives}
                Icon={item.icon}
                onClick={() => handleCategoryClick(item)}
              />
            );
          })}
        </Box>
      )}

      {/* Backdrop de Carga SOLO para el área de contenido */}
      {loader && (
        <Backdrop
          sx={{
            position: "absolute", // 🔹 Se ancla SOLO dentro del contenedor
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Efecto semi-transparente
            zIndex: 10, // 🔹 Menor que el Navbar/Sidebar para que no los cubra
          }}
          open={loader}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}

      {/* Backdrop para mostrar la categoría seleccionada */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={selectedCategory !== null}
      >
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: 5,
            minWidth: "65vh",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {selectedCategory && (
            <>
              {/* Encabezado fijo */}
              <Box
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "3% 4% 2% 4%",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <Typography variant="h5" color="GrayText">
                  {selectedCategory?.name}
                </Typography>
                <IconButton onClick={handleCloseModalCategory}>
                  <CloseIcon color="primary" />
                </IconButton>
              </Box>

              {/* Contenido con Scroll */}
              <Box
                sx={{
                  backgroundImage: `url(${selectedCategory?.portada})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  minHeight: 200,
                  height: "auto",
                  padding: "0 3%",
                  borderTop: 3,
                  borderTopColor: selectedCategory.color,
                }}
              >
                {selectedCategory?.lives?.length === 0 ||
                selectedCategory?.lives === undefined ? (
                  <Typography
                    variant="h5"
                    sx={{
                      color: "GrayText",
                      textAlign: "center",
                      paddingTop: "13%",
                    }}
                  >
                    No hay vivos para esta categoría
                  </Typography>
                ) : (
                  <List dense>
                    {selectedCategory?.lives?.map((item) => (
                      <ListItem
                        key={item.id}
                        sx={{ margin: "3% 0" }}
                        secondaryAction={
                          user?.admin_users?.LIVES.WRITE[0] && ( // Verificar permisos solo para los íconos
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 3,
                              }}
                            >
                              {/* Botón Editar */}
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                onClick={() => {
                                  setLiveToEdit(item);
                                  setIsEditing(true);
                                }}
                              >
                                <EditIcon />
                              </IconButton>

                              {/* Botón Archivar */}
                              <IconButton
                                edge="end"
                                aria-label="archive"
                                onClick={() => {
                                  setShowArchiveConfirmationModal(true);
                                  setLiveToArchive(item);
                                }}
                              >
                                <ArchiveIcon />
                              </IconButton>
                            </Box>
                          )
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <CardMedia
                              component="img"
                              height="auto"
                              image={item.thumbnail}
                              alt="Miniatura"
                            />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.title}
                          sx={{ color: "black" }}
                          secondary={formatDate(item.createdAt)}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            </>
          )}
        </Box>
      </Backdrop>
      {/* Botón para Subir Vivo */}
      {user?.admin_users?.LIVES.WRITE[0] && (
        <Box
          sx={{
            position: "fixed",
            textAlign: "center",
            right: "2%",
            bottom: "2%",
            zIndex: 10,
          }}
        >
          <Button
            variant="contained"
            onClick={handleButtonClick}
            color="primary"
            style={{ color: "white" }}
          >
            Subir Vivo
          </Button>
        </Box>
      )}

      {/* Modal de Subir Vivo */}
      <Backdrop open={newLiveModal} sx={{ color: "#fff", zIndex: 100 }}>
        <Lives handleClose={handleClose} />
      </Backdrop>
      {/* Modal de Confirmación para Eliminar */}
      <ConfirmationModal
        text={`¿Eliminar el vivo "${liveToDelete?.title}"?`}
        textConfirmation={"Eliminar"}
        textCancel={"Cancelar"}
        open={showConfirmationModal}
        showConfirmationButton={true}
        showCancelButton={true}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={deleteLive}
      />
      {/* Modal de Confirmación para Archivar */}
      <ConfirmationModal
        text={`¿Archivar el vivo "${liveToArchive?.title}"?`}
        textConfirmation={"Archivar"}
        textCancel={"Cancelar"}
        open={showArchiveConfirmationModal}
        showConfirmationButton={true}
        showCancelButton={true}
        onClose={() => setShowArchiveConfirmationModal(false)}
        onConfirm={archiveLive}
      />
      {/* Modal de Edición */}
      {isEditing && (
        <EditLiveModal
          live={liveToEdit}
          onCancelEdit={onCancelEdit}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      )}
      <Snackbar
        open={toastOpen}
        autoHideDuration={5000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleToastClose}
          severity={toastSeverity}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Categories;
