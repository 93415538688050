import React, { Fragment, useEffect, useState } from "react";
import { Table, TableContainer, Paper, CircularProgress } from "@mui/material";
import { UserTableFooter } from "./UserTableFooter";
import ApiService from "../../../modules/APIService.interceptors";
import { UserTableBody } from "./UserTableBody";
import { TableHead } from "../../TableHead/TableHead";

const UserTable = ({ searchText }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(false);

  const headers = [
    "Nombre Apellido",
    "Email",
    "Instagram",
    "Teléfono",
    "DNI",
    "Dirección",
    "Activa",
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllUsers = async () => {
    try {
      setLoader(true);
      const response = await ApiService.getAllUsers();
      if (!response.error) {
        setUsers(response.data);
      } else {
        console.error("error");
      }
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  // Filtrar usuarios por nombre y apellido
  const filteredUsers = users.filter((user) =>
    `${user.name} ${user.lastname}`
      ?.toLowerCase()
      ?.includes(searchText?.toLowerCase())
  );

  const visibleUsers = filteredUsers?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <TableContainer component={Paper}>
      {loader ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Table>
          {windowWidth < 900 ? (
            <div style={{ width: "700px", overflow: "auto" }}>
              <TableHead headers={headers} />
              <UserTableBody data={visibleUsers} />
            </div>
          ) : (
            <Fragment>
              <TableHead headers={headers} />
              <UserTableBody data={visibleUsers} />
            </Fragment>
          )}
          <UserTableFooter
            rowsPerPage={rowsPerPage}
            page={page}
            totalRows={filteredUsers?.length}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      )}
    </TableContainer>
  );
};

export default UserTable;
