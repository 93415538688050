import { ArrowBack } from "@mui/icons-material";
import { Box, Card, Icon, Typography } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
const MetricsExample = () => {
  const porcentaje = 14;
  const numero = 24;
  return (
    <Card
      sx={{
        width: 310,
        p: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography variant="caption" color={"grey"}>
            TEXT
          </Typography>
          <Typography sx={{ mt: 1 }} variant="h4">
            ${numero}k
          </Typography>
        </Box>
        <MonetizationOnIcon color="primary" sx={{ fontSize: "52px" }} />
      </Box>
      <Box sx={{ display: "flex", alignItems: "end", mt: 1 }}>
        <Box sx={{ display: "flex", mr: 2 }}>
          <ArrowUpwardIcon color="success" />
          <Typography variant="body1" color="green">
            {porcentaje}%
          </Typography>
        </Box>
        <Typography variant="caption" color="grey">
          Since last month
        </Typography>
      </Box>
    </Card>
  );
};

export default MetricsExample;
