import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import UserService from "../../services/user.service";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function UserModal({ userState, userId, setUserState }) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleError = () => setError(false);
  const handleUserRestrictions = async () => {
    try {
      const res = await UserService.handleUserPremium(userState, userId);
      if (!res.error) {
        setUserState(!userState);
        setError(false);
      } else {
        setError(true);
      }
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      handleClose();
    }
  };

  return (
    <div>
      {error ? (
        <Alert onClose={handleError} color={"error"}>
          {" "}
          Error al intentar
          {!userState ? " restringir usuaria" : " habilitar usuaria"}
        </Alert>
      ) : (
        <></>
      )}
      <Button onClick={handleOpen} variant="contained" >
        {!userState ? `Restringir Usuaria` : `Habilitar Usuaria`}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* Close button */}
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            ¡Advertencia!
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: "normal" }}
            textAlign="center"
          >
            {!userState
              ? `Estás a punto de restringir el acceso de esta usuaria a la aplicación.`
              : `Estás a punto de dar acceso a esta usuaria a la aplicación.`}
          </Typography>
          <Typography sx={{ mt: 0, fontWeight: "normal" }} textAlign="center">
            {!userState
              ? `Esta acción puede limitar sus funcionalidades de uso. ¿Estás segura de que deseas continuar?`
              : `Esta acción le devuelve el acceso a todas funcionalidades de uso. ¿Estás segura de que deseas continuar?`}
          </Typography>

          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={3}
          >
            <Button variant="contained" onClick={handleUserRestrictions}>
              Aceptar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
