import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

const CategoryCard = ({ name, color, portada, lives, Icon, onClick }) => {
  return (
    <Card
      sx={{
        minWidth: 365,
        minHeight: 350,
        marginBottom: "3%",
        borderRadius: 8,
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.1)",
        },
      }}
    >
      <CardActionArea onClick={onClick}>
        <Typography
          sx={{
            position: "absolute",
            right: "4%",
            top: "3%",
            backgroundColor: color,
            borderRadius: 8,
            padding: "0.5% 3%",
            color: "white",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
        >
          Vivos: {lives ? lives.length : 0}
        </Typography>
        <div>
          <CardMedia
            component="img"
            height="285"
            image={portada}
            alt="Portada"
          />
          <Icon
            sx={{
              position: "absolute",
              top: "44%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: 120, // Adjust the size of the icon as needed
              color: color, // Adjust the color of the icon as needed
              opacity: 1, // Adjust the opacity of the icon as needed
            }}
          />
        </div>
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            textAlign={"center"}
            color={"GrayText"}
          >
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CategoryCard;
