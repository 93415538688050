import React, { useState, useEffect } from "react";
import { Button, Container, Box, Backdrop } from "@mui/material";
import RecipeForm from "../../components/RecipeForm/RecipeForm";
import RecipesCard from "../../components/RecipesCard/RecipesCard";
import RecipesService from "../../services/recipes.service";
import { apiUrl_recipes } from "../../models/api-urls";
import { APIURL } from "../../models/api-base";

const Recipes = () => {
  const [showForm, setShowForm] = useState(false);
  const [formValues, setFormValues] = useState({
    title: "",
    subtitle: "",
    thumbnail: "",
    link: "",
    description: "",
    autor: "",
    image: "",
  });
  // const [recipes, setRecipes] = useState([
  //   {
  //     id: 1,
  //     title: "Delicious Pasta",
  //     subtitle: "Creamy Alfredo Sauce",
  //     image:
  //       "https://www.bupasalud.com/sites/default/files/styles/640_x_400/public/articulos/2023-06/fotos/bupa_254451278.jpg?itok=fDX5nsgE",
  //     link: "https://example.com/delicious-pasta",
  //     description: "Enjoy this creamy pasta with a rich Alfredo sauce!",
  //     autor: "Chef Mario",
  //     deleted: false,
  //     thumbnail: "",
  //   },
  //   {
  //     id: 2,
  //     title: "Vegan Salad",
  //     subtitle: "Fresh Summer Mix",
  //     image:
  //       "https://www.bupasalud.com/sites/default/files/inline-images/bupa_598072389.jpg",
  //     link: "https://example.com/vegan-salad",
  //     description: "A refreshing salad packed with summer vegetables.",
  //     autor: "Chef Sophie",
  //     deleted: true,
  //     thumbnail: "",
  //   },
  //   {
  //     id: 3,
  //     title: "Delicious Pasta",
  //     subtitle: "Creamy Alfredo Sauce",
  //     image:
  //       "https://www.bupasalud.com/sites/default/files/styles/640_x_400/public/articulos/2023-06/fotos/bupa_254451278.jpg?itok=fDX5nsgE",
  //     link: "https://example.com/delicious-pasta",
  //     description: "Enjoy this creamy pasta with a rich Alfredo sauce!",
  //     autor: "Chef Mario",
  //     deleted: false,
  //     thumbnail: "",
  //   },
  //   {
  //     id: 4,
  //     title: "Vegan Salad",
  //     subtitle: "Fresh Summer Mix",
  //     image:
  //       "https://www.bupasalud.com/sites/default/files/inline-images/bupa_598072389.jpg",
  //     link: "https://example.com/vegan-salad",
  //     description: "A refreshing salad packed with summer vegetables.",
  //     autor: "Chef Sophie",
  //     deleted: true,
  //     thumbnail: "",
  //   },
  //   {
  //     id: 5,
  //     title: "Delicious Pasta",
  //     subtitle: "Creamy Alfredo Sauce",
  //     image:
  //       "https://www.bupasalud.com/sites/default/files/styles/640_x_400/public/articulos/2023-06/fotos/bupa_254451278.jpg?itok=fDX5nsgE",
  //     link: "https://example.com/delicious-pasta",
  //     description: "Enjoy this creamy pasta with a rich Alfredo sauce!",
  //     autor: "Chef Mario",
  //     deleted: false,
  //     thumbnail: "",
  //   },
  //   {
  //     id: 6,
  //     title: "Vegan Salad",
  //     subtitle: "Fresh Summer Mix",
  //     image:
  //       "https://www.bupasalud.com/sites/default/files/inline-images/bupa_598072389.jpg",
  //     link: "https://example.com/vegan-salad",
  //     description: "A refreshing salad packed with summer vegetables.",
  //     autor: "Chef Sophie",
  //     deleted: true,
  //     thumbnail: "",
  //   },
  //   {
  //     id: 7,
  //     title: "Delicious Pasta",
  //     subtitle: "Creamy Alfredo Sauce",
  //     image:
  //       "https://www.bupasalud.com/sites/default/files/styles/640_x_400/public/articulos/2023-06/fotos/bupa_254451278.jpg?itok=fDX5nsgE",
  //     link: "https://example.com/delicious-pasta",
  //     description: "Enjoy this creamy pasta with a rich Alfredo sauce!",
  //     autor: "Chef Mario",
  //     deleted: false,
  //     thumbnail: "",
  //   },
  //   {
  //     id: 8,
  //     title: "Vegan Salad",
  //     subtitle: "Fresh Summer Mix",
  //     image:
  //       "https://www.bupasalud.com/sites/default/files/inline-images/bupa_598072389.jpg",
  //     link: "https://example.com/vegan-salad",
  //     description: "A refreshing salad packed with summer vegetables.",
  //     autor: "Chef Sophie",
  //     deleted: true,
  //     thumbnail: "",
  //   },
  // ]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [image, setImage] = useState({});
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    const getRecipes = async () => {
      const response = await RecipesService.getRecipes();
      if (!response.error) {
        setRecipes(response.data);
      }
    };
    getRecipes();
  }, []);

  const createRecipe = async () => {
    const recipe = new FormData();
    recipe.append("image", image);
    recipe.append("title", formValues.title);
    recipe.append("subtitle", formValues.subtitle);
    recipe.append("thumbnail", "");
    recipe.append("link", formValues.link);
    recipe.append("autor", formValues.autor);
    recipe.append("description", formValues.description);

    try {
      const response = await fetch(APIURL + apiUrl_recipes, {
        method: "POST",
        body: recipe,
      });

      const responseData = await response.json();

      if (responseData.error) {
        // Si la respuesta no es exitosa, lanzar un error
        throw new Error("Error en la solicitud: " + responseData.message);
      } else {
        // Si la respuesta es exitosa, puedes hacer algo con los datos de respuesta si es necesario
        console.log("Respuesta del servidor:", responseData);
      }
    } catch (error) {
      console.error("Error al agregar live:", error);
    }
  };
  const updatedRecipe = async () => {
    // sirenas/uploads/admin/recipes/1717508810985.jpg
    const recipe = new FormData();
    recipe.append("image", image);
    recipe.append("title", formValues.title);
    recipe.append("subtitle", formValues.subtitle);
    recipe.append("link", formValues.link);
    recipe.append("autor", formValues.autor);
    recipe.append("description", formValues.description);

    try {
      const response = await fetch(APIURL + apiUrl_recipes + "/" + editId, {
        method: "PUT",
        body: recipe,
      });

      const responseData = await response.json();

      if (responseData.error) {
        // Si la respuesta no es exitosa, lanzar un error
        throw new Error("Error en la solicitud: " + responseData.message);
      } else {
        // Si la respuesta es exitosa, puedes hacer algo con los datos de respuesta si es necesario
        console.log("Respuesta del servidor:", responseData);
      }
    } catch (error) {
      console.error("Error al agregar live:", error);
    }
  };
  const deleteRecipe = async (id) => {
    try {
      const response = await fetch(APIURL + apiUrl_recipes + "/" + id, {
        method: "DELETE",
      });

      const responseData = await response.json();

      if (responseData.error) {
        // Si la respuesta no es exitosa, lanzar un error
        throw new Error("Error en la solicitud: " + responseData.message);
      } else {
        // Si la respuesta es exitosa, puedes hacer algo con los datos de respuesta si es necesario
        console.log("Respuesta del servidor:", responseData);
      }
    } catch (error) {
      console.error("Error al agregar live:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (editMode && editId !== null) {
      console.log("edit Mode");
      // Update existing recipe
      updatedRecipe();
      const updatedRecipes = recipes?.map((recipe) =>
        recipe.id === editId ? { ...formValues, id: editId } : recipe
      );
      setRecipes(updatedRecipes);
    } else {
      createRecipe();
      // Add new recipe to recipes array
      const newRecipe = { ...formValues, id: recipes.length + 1 };
      setRecipes([...recipes, newRecipe]);
    }
    handleButtonClick(); // Close form after submission (adjust as per your application flow)
  };

  //Boton
  const handleButtonClick = () => {
    editMode == true ? setShowForm(false) : setShowForm(!showForm);
    setFormValues({
      title: "",
      subtitle: "",
      thumbnail: "",
      link: "",
      description: "",
      autor: "",
      image: "",
    });
    setEditMode(false); // Close edit mode if open
    setEditId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDelete = (id) => {
    const updatedRecipes = recipes.filter((recipe) => recipe.id !== id);
    setRecipes(updatedRecipes);
    deleteRecipe(id);
  };

  const handleEdit = (id) => {
    const recipeToEdit = recipes.find((recipe) => recipe.id === id);
    if (recipeToEdit) {
      setFormValues(recipeToEdit);
      setEditMode(true);
      setEditId(id);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const formData = new FormData();
      formData.append("image", file);
      setFormValues({
        ...formValues,
        image: formData,
      });
    }
  };

  const handleCloseForm = () => {
    setShowForm(false)
  }


  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <Container>
        <Box sx={{ position: 'absolute', right: '2%', bottom: '2%'}}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
          >
             Nueva Receta
          </Button>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: 10000}}
          open={showForm}
        >
          <RecipeForm
            onClose={handleCloseForm}
            handleInputChange={handleInputChange}
            submit={handleSubmit}
            formValues={formValues}
            handleFileChange={handleFileChange}
          />
          </Backdrop>
        <RecipesCard
          recipes={recipes}
          handleDelete={handleDelete}
          editId={editId}
          editMode={editMode}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          formValues={formValues}
          handleEdit={handleEdit}
          onClose={() => setEditMode(false)}
          handleFileChange={handleFileChange}
        />
      </Container>
    </Box>
  );
};

export default Recipes;
