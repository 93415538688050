import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import ProfileService from "../../services/profile.service";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ModalAddInvite = ({
  open,
  handleClose,
  addGuest,
  setProfessionalsIDs,
  professionalsIDs
}) => {
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [profesionals, setProfesionals] = useState([]);

  const handleAdd = () => {
    // Verificar si alguno de los selectedProfiles ya está en professionalsIDs
    const existingProfiles = selectedProfiles.some(profileId =>
      professionalsIDs.includes(profileId)
    );
  
    // Si existen, solo cerrar el modal
    if (existingProfiles) {
      handleClose();
    } else {
      // Si no existen, continuar con la lógica de agregar
      const selectedProfessionals = profesionals.filter((profesional) =>
        selectedProfiles.includes(profesional.id)
      );
      setProfessionalsIDs([...professionalsIDs, ...selectedProfiles]);
      addGuest(selectedProfessionals);
      setSelectedProfiles([]);
      handleClose();
    }
  };

  useEffect(() => {
    const getProfesionals = async () => {
      try {
        const response = await ProfileService.getProfiles();
        console.info(response);
        if (!response.error) {
          setProfesionals(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getProfesionals();
  }, []);

 const handleChange = (event) => {
  const {
    target: { value },
  } = event;
  const newSelectedProfiles = typeof value === "string" ? value.split(",") : value;
  console.log("Selected Profiles:", newSelectedProfiles);
  setSelectedProfiles(newSelectedProfiles);
};

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Agregar profesional</DialogTitle>
      <DialogContent>
      <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-checkbox-label">Profesionales</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedProfiles}
            onChange={handleChange}
            input={<OutlinedInput label="Profesionales" />}
            renderValue={(selected) =>
              profesionals
                .filter((profesional) => selected.includes(profesional.id))
                .map((profesional) => profesional.name)
                .join(", ")
            }
          >
            {profesionals.map((profesional) => (
              <MenuItem key={profesional.id} value={profesional.id}>
                <Checkbox checked={selectedProfiles.indexOf(profesional.id) > -1} />
                <ListItemText primary={`${profesional.name} ${profesional.lastname}`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancelar
        </Button>
        <Button onClick={handleAdd} color="primary">
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddInvite;
