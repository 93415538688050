import React from 'react';
import { Modal, Box, List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh', // Limitar la altura del modal al 80% de la altura de la pantalla
    overflowY: 'auto', // Habilitar el desplazamiento vertical si es necesario
};


const CommentsModal = ({ open, setOpen, comments }) => {
    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <List sx={{ width: '100%', height: "100%", bgcolor: 'background.paper', overflowY: 'auto' }} className="scrollable-list" >
                    {comments?.LiveMessages?.map((comment) => (
                        <React.Fragment key={comment.id}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt={comment?.User?.name} src={comment?.User?.avatar} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Link to={`/usuarios/${comment?.User?.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {`${comment?.User?.name} ${comment?.User?.lastname}`}
                                    </Link>}
                                    secondary={
                                        <>
                                            <Typography
                                                component="span"
                                                sx={{
                                                    color: 'text.primary',
                                                    wordWrap: 'break-word',
                                                    whiteSpace: 'normal',
                                                }}
                                            >
                                                {comment?.message}
                                            </Typography>

                                            <Typography>{formatDate(comment?.sendDate)}</Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                            {comments?.LiveMessages?.length > 1 && <Divider variant="inset" component="li" />}
                        </React.Fragment>
                    ))}

                </List>
            </Box>
        </Modal>
    );
};

export default CommentsModal;
