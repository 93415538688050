import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Tooltip
} from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { CartContext } from "../../store/context/CartContext";
import { useParams } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModalAddInvite from "../ModalAddInvite/ModalAddInvite";
import DeleteIcon from "@mui/icons-material/Delete";

const CardWorkshopInvite = ({
  guests,
  setGuests,
  creted,
  setProfessionalsIDs,
  professionalsIDs,
}) => {
  const [open, setOpen] = useState(false);
  const { APIURL } = useContext(CartContext);
  const { workshopId } = useParams();

  useEffect(() => {
    const getGuests = async () => {
      try {
        const URL = `${APIURL}/api/workshops/users/${workshopId}`;
        const PARAMS = {};
        const response = await fetch(URL, PARAMS);

        if (response.ok) {
          const data = await response.json();
          setGuests(data);
        } else {
          console.log("error");
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (!creted) getGuests();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addGuest = (newGuest) => {
    const updatedGuests = [...guests, ...newGuest];
    setGuests(updatedGuests);
  };

  const deleteInvite = (id) => {
    // Filtra los invitados que no tienen el ID proporcionado
    const newGuests = guests.filter((guest) => guest.id !== id);

    // Filtra los IDs profesionales que no tienen el ID proporcionado
    const newProfessionalsIDs = professionalsIDs.filter((profID) => profID !== id);

    // Actualiza el estado con las nuevas listas
    setProfessionalsIDs(newProfessionalsIDs);
    setGuests(newGuests);
  };




  return (
    <>
      <ModalAddInvite
        open={open}
        handleClose={handleClose}
        addGuest={addGuest}
        setProfessionalsIDs={setProfessionalsIDs}
        professionalsIDs={professionalsIDs}
      />
      <Card sx={{ width: '100%', mt: 3 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="h6" sx={{ mr: 1 }}>
              Lista de Invitados
            </Typography>
            <Tooltip aria-label="Agregar" onClick={handleClickOpen} title="Agregar Invitado">
              <IconButton>
                <AddCircleIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider sx={{ mb: 1 }} />
          {/* <Search /> */}
          {guests?.length == 0 && <Typography>No hay invitados</Typography>}

          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              maxHeight: 150,
              minHeight: 150,
              overflow: "auto",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
              mt: 2,
            }}
          >
            {guests?.map((guest, index) => (
              <Fragment key={index}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={guest?.thumbnail} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${guest?.name} ${guest?.lastname}`}
                    secondary={guest?.occupation}
                  />
                  <IconButton
                    color="primary"
                    aria-label="delete"
                    onClick={() => deleteInvite(guest?.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
                <Divider component="li" />
              </Fragment>
            ))}
          </List>
        </CardContent>
      </Card>
    </>
  );
};

export default CardWorkshopInvite;
