import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import Search from "../../common/Search/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaymentsService from "../../services/payment.service";

const CardWorkshopUsers = (height, width) => {
  const [users, setUsers] = useState([]);
  const { workshopId } = useParams();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await PaymentsService.getPaymentByWorkshopId(workshopId);
        if (!response.error) {
          setUsers(response.data);
          setFilteredUsers(response.data); // Inicialmente muestra todos los usuarios
        } else {
          setUsers([]);
          setFilteredUsers([]);
          console.error("error");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
      }
    };
    getUsers();
  }, [workshopId]);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchText(value);

    if (value.trim() === "") {
      // Si no hay texto de búsqueda, mostrar todos los usuarios
      setFilteredUsers(users);
    } else {
      // Filtrar usuarios según el texto de búsqueda
      const lowercasedFilter = value.toLowerCase();
      const filtered = users.filter(user =>
        user["user_fk.name"].toLowerCase().includes(lowercasedFilter) ||
        user["user_fk.lastname"].toLowerCase().includes(lowercasedFilter)
      );
      setFilteredUsers(filtered);
    }
  };


  return (
    <Card sx={{ width: width, height: height }}>
      <CardContent>
        <Typography>Lista de Sirenas</Typography>
        <Divider />
        <br />

        <Search setSearchText={setSearchText} searchText={searchText} handleSearchChange={handleSearchChange} />

        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            height: 230,
            overflow: "auto",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
            mt: 2,
          }}
        >
          {filteredUsers?.map((user, index) => (
            <Fragment key={index}>
              <ListItem>
                <Grid container alignItems="center">
                  <Grid item xs={1}>
                    <ListItemAvatar>
                      <Avatar src="https://sirenas-media-bucket.s3.amazonaws.com/sirenas/assets/default/user/avatarProfile.jpg" />
                    </ListItemAvatar>
                  </Grid>
                  <Grid item xs={3}>
                    <ListItemText
                      primary={`${user["user_fk.name"]}  ${user["user_fk.lastname"]}`}
                      secondary={user["user_fk.dni"]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ListItemText primary={user["user_fk.email"]} />
                  </Grid>
                  <Grid item xs={2}>
                    <ListItemText primary="Argentina" />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton color="default" aria-label="add an alarm" onClick={() =>
                      navigate(`/usuarios/${user["user_fk.id"]}`)
                    }
                      sx={{ flexDirection: "row", justifyContent: "center", alignContent: "center" }}>
                      <Typography mr={1}>Ver perfil</Typography>
                      <VisibilityIcon
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider component="li" />
            </Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default CardWorkshopUsers;
