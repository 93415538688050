import { Navigate, Route, Routes as RoutesDom } from "react-router-dom";
import NotFound from "../../pages/NotFound/NotFound";
import Home from "../../pages/Home/Home";
import Users from "../../pages/Users/Users";
import ProfileUser from "../../pages/ProfileUser/ProfileUser";
import Workshops from "../../pages/Workshops/Workshops";
import Taller from "../../pages/Workshop/Workshop";
import CreateWorkshop from "../../pages/CreateWorkshop/CreateWorkshop";
import CreateUser from "../../pages/CreateUser/CreateUser";
import Statistics from "../../pages/Statistics/Statistics";
import BroadcastLive from "../../pages/BroadcastLive/BroadcastLive";
import Calendario from "../../pages/Calendar/Calendar";
import Recipes from "../../pages/Recipes/Recipes";
import Profesionals from "../../pages/Profesionals/Profesionals";
import Settings from "../../pages/Settings/Settings";
import Categories from "../../pages/Categories/Categories";
import Archives from "../../pages/Archives/Archives";
import Comments from "../../pages/Comments/Comments";
import Channels from "../../pages/Channels/Channels";
import SignIn from "../../pages/SignIn/SignIn";
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";
import Admin from "../../pages/Admin/Admin";
import Permissions from "../../pages/Permissions/Permissions";
import CreateProduct from "../../pages/CreateProduct/CreateProduct";
import Payments from "../../pages/Payments/Payments";
import ModeratorsPage from "../../pages/Moderators/Moderators";

const Routes = () => {
  return (
    <RoutesDom>
      <Route
        path="/login"
        element={
          <PublicRoute>
            <SignIn />
          </PublicRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/:id"
        element={
          <ProtectedRoute>
            <Permissions />
          </ProtectedRoute>
        }
      />
      <Route
        path="/usuarios"
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      />

      <Route
        path="/usuarios/:userId"
        element={
          <ProtectedRoute>
            <ProfileUser />
          </ProtectedRoute>
        }
      />
      <Route
        path="/usuario"
        element={
          <ProtectedRoute>
            <CreateUser />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pagos"
        element={
          <ProtectedRoute>
            <Payments />
          </ProtectedRoute>
        }
      />
      <Route
        path="/talleres"
        element={
          <ProtectedRoute>
            <Workshops />
          </ProtectedRoute>
        }
      />
      <Route
        path="/talleres/:workshopId"
        element={
          <ProtectedRoute>
            <Taller />
          </ProtectedRoute>
        }
      />
      <Route
        path="/taller"
        element={
          <ProtectedRoute>
            <CreateWorkshop />
          </ProtectedRoute>
        }
      />



      <Route
        path="/moderators"
        element={
          <ProtectedRoute>
            <ModeratorsPage />
          </ProtectedRoute>
        }
      />


      <Route
        path="/products"
        element={
          <ProtectedRoute>
            <CreateProduct />
          </ProtectedRoute>
        }
      />
      <Route
        path="/calendario"
        element={
          <ProtectedRoute>
            <Calendario />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recetas"
        element={
          <ProtectedRoute>
            <Recipes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/broadcast"
        element={
          <ProtectedRoute>
            <BroadcastLive />
          </ProtectedRoute>
        }
      />
      <Route
        path="/estadisticas"
        element={
          <ProtectedRoute>
            <Statistics />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profesionales"
        element={
          <ProtectedRoute>
            <Profesionals />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/channels"
        element={
          <ProtectedRoute>
            <Channels />
          </ProtectedRoute>
        }
      />
      <Route
        path="/categories"
        element={
          <ProtectedRoute>
            <Categories />
          </ProtectedRoute>
        }
      />
      <Route
        path="/archives"
        element={
          <ProtectedRoute>
            <Archives />
          </ProtectedRoute>
        }
      />

      <Route
        path="/comments"
        element={
          <ProtectedRoute>
            <Comments />
          </ProtectedRoute>
        }
      />

      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </RoutesDom>
  );
};

export default Routes;
