import { io } from "socket.io-client";
import { API_URL_SOCKET } from "../models/api-base";

export const createSocketConnection = (token, namespace) => {
  const options = {
    secure: true,
    reconnection: true,
    rejectUnauthorized: false,
    query: {
      token: token,
      lastMessageTimeStamp: new Date(Date.now()).getTime(),
    },
    transports: ["websocket", "polling"],
  };
  return io(API_URL_SOCKET + namespace, options);
};
