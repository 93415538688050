import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../store/context/CartContext";
import { Box, Card, Typography, Button } from "@mui/material";
import WorkshopIcon from "@mui/icons-material/Work";
import PeopleIcon from "@mui/icons-material/People";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import CommentIcon from '@mui/icons-material/Comment';
import BroadcastLive from "../BroadcastLive/BroadcastLive.jsx";
import Ellipse from "../../assets/Ellipse 7 (1).png";
import vectorCentral from "../../assets/Vector141.png";
import logo from "../../assets/logo.png";
import Vector7 from "../../assets/Vector7.png";
import { styles } from './Home.ts';

const Home = () => {
  const { setTitleSeccion, getUser, user } = useContext(CartContext);

  useEffect(() => {
    getUser();
    setTitleSeccion("Inicio");
  }, [setTitleSeccion]);

  const isSmallScreen = window.innerWidth < 1024;

  return (
    <>
      {!isSmallScreen ? (
        <Box style={styles.container}>
          <img src={logo} alt="Logo" style={styles.image} />
          {user.superAdmin ? (
            <div style={styles.cardContainer}>
              <Card style={styles.card} sx={{
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}>
                <Button component={Link} to="/talleres" style={styles.cardButton}>
                  <WorkshopIcon style={styles.icon} />
                  <Typography variant="h5">Talleres</Typography>
                  <Typography variant="subtitle1" textAlign={'center'}>
                    Gestiona y administra nuestros talleres
                  </Typography>
                </Button>
              </Card>

              <Card style={styles.card} sx={{
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}>
                <Button component={Link} to="/usuarios" style={styles.cardButton}>
                  <PeopleIcon style={styles.icon} />
                  <Typography variant="h5">Sirenas</Typography>
                  <Typography variant="subtitle1" textAlign={'center'}>
                    Gestiona las Sirenas de la plataforma
                  </Typography>
                </Button>
              </Card>

              <Card style={styles.card} sx={{
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}>
                <Button component={Link} to="/calendario" style={styles.cardButton}>
                  <CalendarMonthIcon style={styles.icon} />
                  <Typography variant="h5">Agenda</Typography>
                  <Typography variant="subtitle1" textAlign={'center'}>
                    Gestiona y administra nuestra agenda
                  </Typography>
                </Button>
              </Card>
              <Card style={styles.card} sx={{
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}>
                <Button component={Link} to="/profesionales" style={styles.cardButton}>
                  <LocalHospitalIcon style={styles.icon} />
                  <Typography variant="h5">Profesionales</Typography>
                  <Typography variant="subtitle1" textAlign={'center'}>
                    Ver todos los profesionales
                  </Typography>
                </Button>
              </Card>
            </div>
          ) : (
            <Card style={styles.comentCard} sx={{
              transition: "transform 0.2s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}>
              <Button component={Link} to="/broadcast" style={styles.cardButton}>
                <CommentIcon style={styles.icon} />
                <Typography variant="h5">Live</Typography>
                <Typography variant="subtitle1" textAlign={'center'}>
                  Ve aquí los comentarios del live
                </Typography>
              </Button>
            </Card>
          )}
          <img src={Ellipse} alt="Ellipse" style={styles.ellipseTop} />
          <img src={vectorCentral} alt="Vector Central" style={styles.centralVector} />
          <img src={Vector7} alt="Vector 7" style={styles.topVector} />
        </Box>
      ) : (
        user.superAdmin ? (
          <div style={styles.cardContainerSmall}>
            <Card style={styles.card}>
              <Button component={Link} to="/talleres" style={styles.cardButton}>
                <WorkshopIcon style={styles.icon} />
                <Typography variant="h5">Talleres</Typography>
                <Typography variant="subtitle1" textAlign={'center'}>
                  Gestiona y administra nuestros talleres
                </Typography>
              </Button>
            </Card>
            <Card style={styles.card}>
              <Button component={Link} to="/usuarios" style={styles.cardButton}>
                <PeopleIcon style={styles.icon} />
                <Typography variant="h5">Sirenas</Typography>
                <Typography variant="subtitle1" textAlign={'center'}>
                  Gestiona las Sirenas de la plataforma
                </Typography>
              </Button>
            </Card>
            <Card style={styles.card}>
              <Button component={Link} to="/calendario" style={styles.cardButton}>
                <CalendarMonthIcon style={styles.icon} />
                <Typography variant="h5">Agenda</Typography>
                <Typography variant="subtitle1" textAlign={'center'}>
                  Gestiona y administra nuestra agenda
                </Typography>
              </Button>
            </Card>
            <Card style={styles.card}>
              <Button component={Link} to="/profesionales" style={styles.cardButton}>
                <LocalHospitalIcon style={styles.icon} />
                <Typography variant="h5">Profesionales</Typography>
                <Typography variant="subtitle1" textAlign={'center'}>
                  Ver todos los profesionales
                </Typography>
              </Button>
            </Card>
          </div>
        ) : (
          <BroadcastLive />

        )
      )}
    </>
  );
};

export default Home;
