import React, { useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";
import { Input } from "@mui/material";
import { Check } from "@mui/icons-material";
import WorkshopService from "../../services/workshop.service";
import { apiUrl_workshop } from "../../models/api-urls";
import { APIURL } from "../../models/api-base";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
}));

export default function FileUploadButton({ numWorkshop, workshopId }) {
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectVideo, setSelectVideo] = useState(null);
  const [dateLive, setDateLive] = useState(new Date());
  const [numberLive, setNumberLive] = useState(0);
  const [title, setTitle] = useState("");

  const addLive = async () => {
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("video", selectVideo);
    formData.append("date", dateLive);
    formData.append("numberWorkshop", numWorkshop);
    formData.append("numberLive", numberLive);
    formData.append("workshopId", workshopId);
    formData.append("title", title);

    try {
      // const response = await fetch(
      //   APIURL + apiUrl_workshop + "/lives/getlives"
      // );
      console.log("desde workshop->", formData);
      const response = await fetch(
        APIURL + apiUrl_workshop + "/audiolive/addlive",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        // Si la respuesta no es exitosa, lanzar un error
        throw new Error("Error en la solicitud: " + response.statusText);
      }

      // Si la respuesta es exitosa, puedes hacer algo con los datos de respuesta si es necesario
      const responseData = await response.json();
      console.log("Respuesta del servidor:", responseData);
    } catch (error) {
      console.error("Error al agregar live:", error);
    }
  };

  const handleTest = async () => {
    try {
      const response = await fetch(
        APIURL + apiUrl_workshop + "/lives/getlives",
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Error en la solicitud: " + response.statusText);
      }
      // Si la respuesta es exitosa, puedes hacer algo con los datos de respuesta si es necesario
      const responseData = await response.json();
      console.log("Respuesta del servidor:", responseData);
    } catch (error) {
      console.error("Error al agregar live:", error);
    }
  };

  const handleTitleLiveChange = (event) => {
    setTitle(event.target.value);
  };

  const handleNumberLiveChange = (event) => {
    setNumberLive(event.target.value);
  };
  const handleDateChange = (event) => {
    setDateLive(event.target.value);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleVideoChange = (event) => {
    setSelectVideo(event.target.files[0]);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSelectedFile(null);
    setSelectVideo(null);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        startIcon={<CloudUploadIcon />}
      >
        Subir Live
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: "flex", flex: "column" }}
      >
        <ModalContent
          style={{
            backgroundColor: "white",
            padding: "2%",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Box style={{}}>
            <h2 id="modal-modal-title" style={{ textAlign: "center" }}>
              Video Live
            </h2>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box display={"flex"} justifyContent={"center"}>
              <Input
                type="text"
                color="primary"
                rows={2}
                size="large"
                variant="outlined"
                placeholder="Título"
                onChange={handleTitleLiveChange}
                sx={{ width: "100%" }}
              />
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Input
                type="number"
                color="primary"
                rows={2}
                size="large"
                variant="outlined"
                placeholder="Numero de Vivo"
                onChange={handleNumberLiveChange}
                sx={{ width: "100%" }}
              />
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Input
                type="date"
                color="primary"
                rows={2}
                size="large"
                variant="outlined"
                // placeholder="Numero de Vivo"
                onChange={handleDateChange}
                sx={{ width: "100%" }}
              />
            </Box>

            <Box display={"flex"} justifyContent={"center"} marginTop={2}>
              <Box m={1}>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={selectedFile ? <Check /> : <CloudUploadIcon />}
                >
                  Portada
                  <VisuallyHiddenInput
                    name="file"
                    type="file"
                    id="photo-upload"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Button>
              </Box>

              <Box m={1}>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={selectVideo ? <Check /> : <CloudUploadIcon />}
                >
                  Video Live
                  <VisuallyHiddenInput
                    name="file"
                    type="file"
                    id="video-upload"
                    accept="video/*"
                    onChange={handleVideoChange}
                  />
                </Button>
              </Box>
            </Box>

            <br />

            <Box display={"flex"} justifyContent={"center"}>
              <Button type="submit" variant="contained" onClick={addLive}>
                Publicar
              </Button>
            </Box>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
