import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import CardProfileImage from "../../components/CardProfileImage/CardProfileImage";
import CardProfileForm from "../../components/CardProfileForm/CardProfileForm";
import CardProfileTier from "../../components/CardProfileTier/CardProfileTier";
import CardPaymentsUser from "../../components/CardPaymentsUser/CardPaymentsUser";
import ApiService from "../../modules/APIService.interceptors";
import { CartContext } from "../../store/context/CartContext";
import "./profileUser.css";
import UserService from "../../services/user.service";
import UserWeightTable from "../../components/UserWeightTable/UserWeightTable";
import UserModal from "../../common/UserModal/UserModal";

const ProfileUser = () => {
  const { userId } = useParams();
  const [user, setUser] = useState({});
  const { setTitleSeccion } = useContext(CartContext);
  const [userPayments, setUserPayments] = useState([]);
  const [weight, setWeight] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [state, setState] = useState();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await UserService.getUserById(userId);
        if (response) {
          setUser(response.user);
          setIsAdmin(response.user.admin)
          setWeight(response)
          setState(response.user.premium)
        } else {
          console.log("Usuario no encontrado");
        }
      } catch (error) {
        console.log("Error al seleccionar usuario");
      }
    };

    console.log({user})


    const getUserPayments = async () => {
      try {
        const response = await ApiService.userPayments(userId);
        if (response) {
          setUserPayments(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getUserPayments();
    getUserData();
    setTitleSeccion("Perfil de Usuario");
  }, [userId]);

  const editUser = async (admin) => {
    try {
      const response = await ApiService.updateStatusUser(userId, admin);
      if (!response.error) {
        console.log("Actualizado");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = async (event) => {
    const newStatus = event.target.checked;
    setIsAdmin(newStatus);
    await editUser(newStatus); // Llamamos a la función editUser pasándole el nuevo estado
  };

  const handleChange = async (event) => {
    const newState = event.target.checked;

    try {
      const res = await UserService.handleUserPremium(newState, userId);
      if (!res.error) {
        setState(newState);
      } else {
        setState(!newState);
      }
    } catch (e) {
      console.log(e);
    }
  };


  return (
    <>
      <Box className="user__box">
        <Box className="user__box__container">
          <Box className="user__box__container__body">


            <Box display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <CardProfileImage user={user} />

              <Box marginY={3}>
                <FormControlLabel
                  control={
                    <Switch checked={state} onChange={handleChange} />
                  }
                  label={state ? "Cuenta Premium Activada" : "Activar Premium"}
                />
              </Box>

              {/* <br />
              {user?.team && <CardProfileTier team={user.team} />}
              <br /> */}

              {/* <UserModal userState={userState} userId={userId} setUserState={setUserState} /> */}
              {/* <CardWorkshopUser /> */}


            </Box>


            <Box>
              <CardProfileForm user={user} setUser={setUser} admin={isAdmin} setAdmin={setIsAdmin} handleToggle={handleToggle} />
              <br />
              <UserWeightTable data={weight?.weight} />
              <br />
              <CardPaymentsUser userPaymemts={userPayments} />
              {/* <CardStatsProfile /> */}
            </Box>
          </Box>
        </Box>
      </Box>


    </>
  );
};

export default ProfileUser;
