import React from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  LinearProgress,
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ProfesionalForm = ({
  onClose,
  handleInputChange,
  submit,
  formValues,
  handleFileChange,
  image
}) => {
  // console.log("Progresooooo",uploadProgress)
  return (
    <Container maxWidth="sm">
      <Box sx={{ background: 'white', borderRadius: 5, padding: '5% 5%' }}>
        <Box display={"flex"} alignItems={'flex-start'} justifyContent={'space-between'}>
          <Typography variant="h4" gutterBottom color={"#767676"}>
            Nuevo Profesional
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
        <form onSubmit={submit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nombre"
                name="name"
                fullWidth
                value={formValues.name}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Apellido"
                name="lastname"
                fullWidth
                value={formValues.lastname}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Profesion"
                name="occupation"
                fullWidth
                value={formValues.occupation}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              {/* Replace TextField with input type="file" */}
              <input
                accept="image/*"
                id="image-upload"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <label htmlFor="image-upload">
                <Button
                  variant="contained"
                  component="span"
                  color="secondary"
                  style={{ color: "white" }}
                >
                  {formValues.image ? "Cambiar Portada" : "Subir Portada"}
                </Button>
              </label>

            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Instagram"
                name="insta"
                fullWidth
                value={formValues.insta}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descipcion"
                name="description"
                fullWidth
                multiline
                rows={4}
                value={formValues.description}
                onChange={handleInputChange}
              />
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              style={{ color: "white", marginLeft: '3%', marginTop: '3%' }}
            >
              Agregar
            </Button>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default ProfesionalForm;

