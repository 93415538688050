import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  Box,
  Grid,
  Divider,
  Container,
  Alert,
  Snackbar,
} from "@mui/material";
import AdminService from "../../services/admin.service";

// Enum de permisos disponibles
const UserAdminServicesEnum = {
  SADMIN: "SADMIN",
  USERS: "USERS",
  PAYMENTS: "PAYMENTS",
  WORKSHOPS: "WORKSHOPS",
  PRODUCT: "PRODUCTS",
  EVENTS: "EVENTS",
  QUESTS: "QUESTS",
  PROFESSIONALS: "PROFESSIONALS",
  LIVES: "LIVES",
  MOD: "MOD",
  BANK: "BANK",
};

const Permissions = () => {
  const { id } = useParams();
  const [admin, setAdmin] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [oldPermissions, setOldPermissions] = useState([]);
  const [initialPermissions, setInitialPermissions] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success"); // 'success' | 'error' | 'warning' | 'info'

  const handleClick = (msg, type) => {
    setMessage(msg);
    setSeverity(type);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };


  useEffect(() => {
    const fetchAdmin = async () => {
      const adminData = await AdminService.getById(id);
      const user = adminData[0];

      const allPermissions = Object.entries(UserAdminServicesEnum).map(
        ([key, name]) => {
          const currentPermission = user.admin_users?.[name] || {
            READ: [],
            WRITE: [],
          };

          // 🔹 Filtrar `null` antes de evaluar longitud
          const filteredRead = (currentPermission.READ || []).filter(
            (item) => item !== null
          );
          const filteredWrite = (currentPermission.WRITE || []).filter(
            (item) => item !== null
          );

          return {
            name,
            read: filteredRead.length > 0, // Ahora solo es `true` si hay valores válidos
            write: filteredWrite.length > 0, // Ahora solo es `true` si hay valores válidos
            currentReadIds: filteredRead.map((item) => item.admin_service_id),
            currentWriteIds: filteredWrite.map((item) => item.admin_service_id),
          };
        }
      );

      setAdmin(user);
      setPermissions(allPermissions);
      setOldPermissions(JSON.parse(JSON.stringify(allPermissions))); // Copia profunda
      setInitialPermissions(JSON.parse(JSON.stringify(allPermissions)));
    };

    fetchAdmin();
  }, [id]);

  const handlePermissionChange = (index, type) => {
    const updatedPermissions = [...permissions];
    updatedPermissions[index][type] = !updatedPermissions[index][type];
    setPermissions(updatedPermissions);
  };

  const handleSave = async () => {
    const toDelete = { READ: [], WRITE: [] };
    const toCreate = [];

    permissions.forEach((perm, index) => {
      const oldPerm = oldPermissions[index];

      console.log(
        perm.name,
        " --- oldPerm.read",
        oldPerm.read,
        " --- perm.read",
        perm.read
      );
      console.log(
        perm.name,
        " --- oldPerm.write",
        oldPerm.write,
        " --- perm.write",
        perm.write
      );

      // 🔹 VERIFICACIÓN DE PERMISOS DE LECTURA
      if (!oldPerm.read && perm.read) {
        console.log(`✅ Se dio permiso de LECTURA a ${perm.name}`);
        toCreate.push({
          service: {
            name: perm.name,
            id:
              perm.currentReadIds.length > 0
                ? perm.currentReadIds[0]
                : index + 1,
            method: "READ",
          },
        });
      } else if (oldPerm.read && !perm.read) {
        console.log(`❌ Se revocó permiso de LECTURA a ${perm.name}`);
        if (perm.currentReadIds.length > 0) {
          toDelete.READ.push(...perm.currentReadIds);
        } else {
          toDelete.READ.push(index + 1);
        }
      }

      // 🔹 VERIFICACIÓN DE PERMISOS DE ESCRITURA
      if (!oldPerm.write && perm.write) {
        console.log(`✅ Se dio permiso de ESCRITURA a ${perm.name}`);
        toCreate.push({
          service: {
            name: perm.name,
            id:
              perm.currentWriteIds.length > 0
                ? perm.currentWriteIds[0]
                : index + 1,
            method: "WRITE",
          },
        });
      } else if (oldPerm.write && !perm.write) {
        console.log(`❌ Se revocó permiso de ESCRITURA a ${perm.name}`);
        if (perm.currentWriteIds.length > 0) {
          toDelete.WRITE.push(...perm.currentWriteIds);
        } else {
          toDelete.WRITE.push(index + 1);
        }
      }
    });

    const payload = { toDelete, toCreate };

    try {
      console.log("🚀 Permisos dados:", JSON.stringify(toCreate, null, 2));
      console.log("❌ Permisos revocados:", JSON.stringify(toDelete, null, 2));
      await AdminService.update(id, payload);
      handleClick("✅ Permisos actualizados correctamente", "success");
    } catch (error) {
      console.error("❌ Error al actualizar permisos:", error);
      handleClick("❌ Hubo un error al actualizar los permisos", "error");
    }
  };

  if (!admin) return <Typography align="center">Cargando...</Typography>;

  const permissionLabels = {
    SADMIN: "ADMINISTRADORAS",
    USERS: "USUARIAS",
    PAYMENTS: "PAGOS",
    WORKSHOPS: "TALLERES",
    PRODUCTS: "PRODUCTOS",
    EVENTS: "EVENTOS",
    QUESTS: "CONSIGNAS",
    PROFESSIONALS: "PROFESIONALES",
    LIVES: "VIVOS",
    MOD: "MODERADORES",
    BANK: "CBU",
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>


      <Paper elevation={3} sx={{ padding: 4 }}>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            {admin.name} {admin.lastname}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {admin.email}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" sx={{ mb: 2 }}>
          Gestión de Permisos
        </Typography>

        <Grid container spacing={4}>
          {permissions?.map((permission, index) => (
            <Grid item xs={12} sm={6} key={permission.name}>
              <Paper
                elevation={1}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  borderRadius: 2,
                  backgroundColor: "#f9f9f9",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  {permissionLabels[permission?.name] || permission?.name}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permission?.read}
                      onChange={() => handlePermissionChange(index, "read")}
                      color="primary"
                    />
                  }
                  label="Lectura"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permission?.write}
                      onChange={() => handlePermissionChange(index, "write")}
                      color="secondary"
                    />
                  }
                  label="Escritura"
                />
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              paddingX: 4,
              borderRadius: 8,
              fontWeight: "bold",
            }}
            onClick={handleSave}
          >
            Guardar Cambios
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Permissions;

//Administradoras, usuarias, Pagos, Talleres, Eventos, Consignas, Profesionales, Vivos, Moderador, Cuenta Bancaria
