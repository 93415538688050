import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
const SearchDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%", // Se asegura de que el contenedor ocupe todo el ancho
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%", // Se asegura de que el input ocupe todo el ancho
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%", // Asegura que el input siempre ocupe todo el ancho disponible
    fontSize: "1.2rem",
    "&::placeholder": {
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: "rgba(32, 29, 29, 0.7)",
    },
  },
}));

const Search = ({
  searchText,
  handleSearchChange,
  headersFilters,
  filter,
  setFilter,
}) => {
  const handleChange = (event) => {
    setFilter(event?.target?.value);
  };
  return (
    <SearchDiv sx={{ display: "flex", alignItems: "center" }}>
      <SearchIconWrapper>
        <SearchIcon color="disabled" />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={`Buscar por ${filter?.toLocaleLowerCase()}...`}
        inputProps={{ "aria-label": "search" }}
        value={searchText}
        onChange={handleSearchChange}
      />
      <div>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={filter}
            onChange={handleChange}
          >
            {headersFilters?.map((header) => (
              <MenuItem key={header.value} value={header.value}>
                {header?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </SearchDiv>
  );
};

export default Search;
