import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ApiService from "../../modules/APIService.interceptors";
import formatDate from "../Format/FormatDate";

export default function LiveVideoCard({ live }) {
  const [openModal, setOpenModal] = React.useState(false);
  const handleDelete = () => {
    setOpenModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const res = await ApiService.deleteLive(live.id);
      if (!res.error) {
        console.log("Elemento eliminado");
      } else {
        console.log("No se pudo eliminar elemento eliminado");
      }
      setOpenModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Card sx={{ width: 420, marginY: 3, boxShadow: 5, borderRadius: 0 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            style={{ padding: "3px 25px", paddingTop: "8px" }}
          >
            {live.title}
            <Typography variant="body2" color="text.secondary">
              Fecha: {formatDate(live.streamingDate)}
            </Typography>
          </Typography>
          <Typography color="white" style={styleNumberLive}>
            {live.id}
          </Typography>
        </div>
        <CardMedia
          sx={{ height: 200 }}
          image={live?.thumbnail}
          title="miniatura del video"
        />
        <CardActions>
          {/* <Button size="small">Editar</Button> */}
          <Button size="small" onClick={handleDelete}>
            Eliminar
          </Button>
        </CardActions>
      </Card>
      <ConfirmationModal
        open={openModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        title="Confirmación de eliminación"
        message="¿Está seguro de que desea eliminar este elemento?"
      />
    </>
  );
}

function ConfirmationModal({ open, onClose, onConfirm, title, message }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          textAlign: "center",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {message}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Button onClick={onClose} variant="outlined" sx={{ mr: 2 }}>
            Cancelar
          </Button>
          <Button onClick={() => onConfirm()} variant="contained" color="error">
            Confirmar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

const styleNumberLive = {
  backgroundColor: "#D0749B",
  width: "50px",
  height: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "25px",
  borderRadius: "0 0 0 20px",
};
