import { Card, Typography } from "@mui/material";

const CardWorkshopTitle = ({ numWorkshop, numMermaids, width }) => {
  return (
    <Card
      sx={{
        p: "1rem 2rem 1rem 2rem",
        display: "flex",
        alignItems: "center",
        width: { width },
        justifyContent: "space-between",
        backgroundColor: "white",
      }}
    >
      <Typography variant="h4" color="primary">
        Taller N°: {numWorkshop}
      </Typography>
      {/* <Typography variant="h4" color='white' >Sirenas: {numMermaids}</Typography> */}
    </Card>
  );
};

export default CardWorkshopTitle;
