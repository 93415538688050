import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
  Select,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import { format } from "date-fns";
import ApiService from "../../modules/APIService.interceptors"; // Import ApiService

const ProductList = ({ cupons, Currency, DiscountType, reloadData }) => {
  const [open, setOpen] = useState(false);
  const [selectedCupon, setSelectedCupon] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleOpen = (cupon) => {
    setSelectedCupon(cupon);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCupon(null);
  };

  const handleChange = (e) => {
    setSelectedCupon({
      ...selectedCupon,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelete = () => {
    setConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await ApiService.deleteCuponById(selectedCupon.id); // Call deleteCuponById
      setConfirmOpen(false);
      handleClose();
      setSnackbarMessage("Cupón eliminado exitosamente");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error eliminando cupon => ", error);
      setSnackbarMessage("Error eliminando cupón");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      reloadData();
    }
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const handleApplyChanges = async () => {
    try {
      await ApiService.updateCupon(selectedCupon.id, selectedCupon); // Call updateCupon
      handleClose();
      setSnackbarMessage("Cambios aplicados exitosamente");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error actualizando cupon => ", error);
      setSnackbarMessage("Error aplicando cambios");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      reloadData();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {cupons.length > 0 ? (
          cupons
            .sort((a, b) => b.id - a.id) // Sort coupons by ID in descending order
            .map((cupon) => (
              <Card
                key={cupon.id}
                sx={{
                  width: 420,
                  height: 250,
                  borderRadius: 4,
                  boxShadow: 3,
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 0.4,
                  paddingRight: 1,
                  cursor: "pointer", // Change cursor to pointer on hover
                  transition: "transform 0.2s, box-shadow 0.2s", // Add transition for animation
                  "&:hover": {
                    transform: "scale(1.02)", // Slightly scale up on hover
                    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)", // Increase shadow on hover
                  },
                }}
                onClick={() => handleOpen(cupon)} // Make the card clickable
              >
                <CardContent
                  sx={{ display: "flex", flexDirection: "column", gap: 0.3 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "satrt",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      fontWeight="bold"
                      gutterBottom
                    >
                      {cupon.code}
                    </Typography>
                    <Typography
                      component="div"
                      fontWeight="bold"
                      variant="h6"
                      color={"GrayText"}
                    >
                      {cupon.currency}
                    </Typography>
                  </Box>
                  <Typography variant="body3" color="textSecondary">
                    Descuento:{" "}
                    {cupon.discountType === "percentage"
                      ? `${cupon.discountValue}% de descuento`
                      : `$${cupon.discountValue} de descuento`}
                  </Typography>
                  <Typography variant="body3" color="textSecondary">
                    Máximo de usos: {cupon.maxUses}
                  </Typography>
                  <Typography variant="body3" color="textSecondary">
                    Creado el: {format(new Date(cupon.createdAt), "dd/MM/yyyy")}
                  </Typography>
                </CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "auto",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        background: "#D0749B",
                        color: "#FFF",
                        padding: "6px 12px",
                        borderRadius: "12px",
                        fontSize: "0.8rem",
                      }}
                    >
                      Válido hasta:{" "}
                      {format(new Date(cupon.expirationDate), "dd/MM/yyyy")}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleOpen(cupon)}
                    >
                      Ver detalles
                    </Button>
                  </CardActions>
                </Box>
              </Card>
            ))
        ) : (
          <Typography variant="body2" color="GrayText">
            No hay cupones disponibles.
          </Typography>
        )}
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            borderRadius: 8,
            boxShadow: 24,
            p: 4,
          }}
        >
          {selectedCupon && (
            <div>
              <TextField
                label="Código"
                name="code"
                value={selectedCupon.code}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Moneda</InputLabel>
                <Select
                  label="Moneda"
                  name="currency"
                  value={selectedCupon.currency}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                >
                  {Currency.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tipo de Descuento</InputLabel>
                <Select
                  label="Tipo de Descuento"
                  name="discountType"
                  value={selectedCupon.discountType}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                >
                  {DiscountType.map((discountType) => (
                    <MenuItem key={discountType} value={discountType}>
                      {discountType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Valor del Descuento"
                name="discountValue"
                value={selectedCupon.discountValue}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Fecha de Expiración"
                name="expirationDate"
                type="date"
                value={selectedCupon.expirationDate.split("T")[0]}
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Máximo de Usos"
                name="maxUses"
                value={selectedCupon.maxUses}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Button
                  onClick={handleDelete}
                  sx={{ color: "rgb(231, 68, 56)" }}
                >
                  Eliminar
                </Button>
                <Button onClick={handleApplyChanges}>Aplicar cambios</Button>
              </Box>
            </div>
          )}
        </Box>
      </Modal>
      <Dialog open={confirmOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar este cupón?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Button onClick={handleCancelDelete} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        position="bottom center"
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProductList;
