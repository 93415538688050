import axios from "axios";
import { APIURL } from "../models/api-base";
import {
  apiUrl_payment_cancel_tranfer,
  apiUrl_payment_confirm_tranfer,
  apiUrl_payment_tranfer,
  apiUrl_user,
  apiUrl_user_id,
  apiUrl_user_singup,
  apiUrl_user_update,
  apiUrl_workshop,
  apiUrl_workshop_lives,
  apiUrl_workshop_users,
  all_payments,
  apiUrl_payment_user,
  apiUrl_lives,
  apiUrl_update_thumbnail_live,
  apiUrl_user_update_status,
  apiUrl_product,
  apiUrl_cupons,
  apiUrl_workshop_status,
} from "../models/api-urls";
import fetchService from "./APIService";
import { id } from "date-fns/locale";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  // Pagos <===================

  //Al Payments
  allPayments = async (amount, page, method, status, filter, search) => {
    try {
      const response = await this.get(
        `${all_payments}/${amount}/${page}/${method}/${status}/${filter}/${search}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  userPayments = async (id) => {
    try {
      const response = await this.get(apiUrl_payment_user + id);
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Confimo Pago de transferencia
   * @param {number} idPayment
   * @returns
   */
  confirmPaymentToTransfer = async (idPayment) => {
    try {
      const response = await this.put(
        apiUrl_payment_confirm_tranfer + idPayment
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Rechazo pago de transferencia
   * @param {number} idPayment
   * @returns
   */
  cancelPaymentToTransfer = async (idPayment) => {
    try {
      const response = await this.put(
        apiUrl_payment_cancel_tranfer + idPayment
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Traer los pagos por transferencias
   * @param {string} key pending,approved,rejected
   * @returns { array | boolean }
   */
  getAllPaymentsTransfer = async (key) => {
    try {
      let response = false;
      if (
        key !== "all" &&
        key !== "pending" &&
        key !== "approved" &&
        key !== "rejected"
      ) {
        console.error("Parametro no existe");
      } else {
        response = await this.get(apiUrl_payment_tranfer + key);
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  // Usuarios <===================

  /**
   * Recibo cantidad de usuarios a mostrar y la pagina a la que pertencen
   * @param {number} page
   * @param {number} rowsPerPage
   * @returns
   */

  getUsers = async (amount, page, filter, search) => {
    try {
      const endpoint = `${apiUrl_user}/${amount}/${page}/${filter}/${search}`;
      const response = await this.get(endpoint);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getUserById = async (id) => {
    try {
      const response = await this.get(`${apiUrl_user_id}/${id}`);
      return response.user;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Edito un Usuario
   * @param {string} id
   * @param {object} user
   * @returns
   */
  updateUser = async (id, user) => {
    try {
      const response = await this.put(`${apiUrl_user_update}/${id}`, user);
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Creo un nuevo Usuario
   * @param {object} user
   * @returns
   */
  createUser = async (user) => {
    try {
      const response = await this.post(apiUrl_user_singup, user);
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Paso usuario a Admin
   * @param {string} id
   * @param {object} user
   * @returns
   */
  updateStatusUser = async (id, admin) => {
    try {
      const response = await this.put(`${apiUrl_user_update_status}/${id}`, {
        admin,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  // Talleres <===================

  getUsersWorkshop = async (workshopId) => {
    try {
      const response = await this.get(`${apiUrl_workshop_users}${workshopId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getAllWorkshops = async () => {
    try {
      const response = await this.get(apiUrl_workshop);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getAllLives = async () => {
    try {
      const response = await this.get(apiUrl_lives);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getWorkshopById = async (id) => {
    try {
      const response = await this.get(`${apiUrl_workshop}/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getLivesWorkshopById = async (id) => {
    try {
      const response = await this.get(`${apiUrl_workshop_lives}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Creo un nuevo taller
   * @param {object} workshop
   * @returns
   */
  createWorkshop = async (workshop) => {
    try {
      const response = await this.post(apiUrl_workshop, workshop);
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Edito un taller
   * @param {object} workshop
   * @returns
   */
  updateWorkshop = async (id, workshop) => {
    try {
      const response = await this.put(`${apiUrl_workshop}/${id}`, workshop);
      console.log(`${apiUrl_workshop}/${id}`, workshop);
      return response;
    } catch (error) {
      throw error;
    }
  };

  updateStatusWorkshop = async (key, id) => {
    try {
      if (key !== "pending" && key !== "approved" && key !== "rejected") {
        console.error("Parametro no existe");
        return false;
      } else {
        const response = await this.put(`${apiUrl_workshop}/${id}/${key}`);
        return response;
      }
    } catch (error) {
      throw error;
    }
  };

  deleteWorkshopById = async (id) => {
    try {
      const response = await this.delete(`${apiUrl_workshop}/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  deleteLive = async (id) => {
    try {
      const response = await this.delete(`${apiUrl_workshop_lives}/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  archiveLive = async (id) => {
    try {
      const response = await this.put(
        `${APIURL}${apiUrl_lives}/archive/${id}`,
        {
          archive: true,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  unarchiveLive = async (id) => {
    try {
      const response = await this.put(
        `${APIURL}${apiUrl_lives}/archive/${id}`,
        {
          archive: false,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  getArchivedLives = async () => {
    try {
      const response = await this.get(`${APIURL}${apiUrl_lives}/archives`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  updateLiveName = async (id, item) => {
    try {
      console.log(`${apiUrl_lives}/${id}`, item);
      const response = await this.put(
        `${APIURL}${apiUrl_lives}/update/${id}`,
        item
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  updateLiveThumbnail = async (id, item) => {
    try {
      console.log(`${apiUrl_update_thumbnail_live}/${id}`, item);
      const response = await this.put(
        `${apiUrl_update_thumbnail_live}/${id}`,
        item
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  createProduct = async (item) => {
    try {
      console.log(apiUrl_product, item);
      const response = await this.post(apiUrl_product, item);
      return response;
    } catch (error) {
      throw error;
    }
  };

  deleteProductById = async (id) => {
    try {
      const response = await this.delete(`${apiUrl_product}/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  updateProduct = async (id, product) => {
    try {
      const response = await this.put(`${apiUrl_product}/${id}`, product);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getProducts = async () => {
    try {
      console.log(apiUrl_product);
      const response = await this.get(apiUrl_product);
      return response;
    } catch (error) {
      throw error;
    }
  };

  createCupon = async (item) => {
    try {
      console.log(apiUrl_cupons, item);
      const response = await this.post(apiUrl_cupons, item);
      return response;
    } catch (error) {
      throw error;
    }
  };

  deleteCuponById = async (id) => {
    try {
      const response = await this.delete(`${apiUrl_cupons}/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  updateCupon = async (id, cupon) => {
    try {
      const response = await this.put(`${apiUrl_cupons}/${id}`, cupon);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getCupons = async () => {
    try {
      console.log(apiUrl_cupons);
      const response = await this.get(apiUrl_cupons);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getWorkshopStatus = async () => {
    try {
      console.log(apiUrl_workshop_status);
      const response = await this.get(apiUrl_workshop_status);
      return response;
    } catch (error) {
      console.log("ERROR EN WORKSHOP STATUS =>", error);
      throw error;
    }
  };
}

const ApiService = new Service();

export default ApiService;
