import { APIURL } from "../models/api-base";
import { apiUrl_workshop } from "../models/api-urls";
import fetchService from "../modules/APIService";

class Service extends fetchService {
  constructor() {
    super(APIURL);
  }

  getAllWorkshops = async () => {
    const response = await this.get(apiUrl_workshop);
    return response;
  };

  getWorkshopById = async (id) => {
    const response = await this.get(`${apiUrl_workshop}/${id}`);
    return response;
  };

  /**
   * Creo un nuevo taller
   * @param {object} workshop
   * @returns
   */
  createWorkshop = async (workshop) => {
    const response = await this.post(apiUrl_workshop, workshop);
    return response;
  };

  /**
   * Edito un taller
   * @param {object} workshop
   * @returns
   */
  updateWorkshop = async (id, workshop) => {
    const response = await this.put(`${apiUrl_workshop}/${id}`, workshop);
    // console.log(`${apiUrl_workshop}/${id}`, workshop);
    return response;
  };

  updateStatusWorkshop = async (key, id) => {
    if (key !== "pending" && key !== "approved" && key !== "rejected") {
      console.error("Parametro no existe");
      return false;
    } else {
      const response = await this.put(`${apiUrl_workshop}/${id}/${key}`);
      return response;
    }
  };

  DeleteWorkshopById = async (id) => {
    const response = await this.delete(`${apiUrl_workshop}/${id}`);
    console.log(`${apiUrl_workshop}/${id}`);
    return response;
  };
  addLive = async (formData) => {
    const response = await fetch(
      APIURL + apiUrl_workshop + "/audiolive/addlive",
      {
        method: "POST",
        body: formData,
      }
    );
    return response;
  };
}

const WorkshopService = new Service();

export default WorkshopService;
