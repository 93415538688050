import { API_URL_SOCKET } from "../models/api-base";
import {
    apiURL_create_moderators,
    apiURL_delete_moderator,
    apiURL_get_chat_live,
    apiURL_get_chat_live_by_date
} from "../models/api-urls";

import fetchService from "../modules/APIService";

class Service extends fetchService {
    constructor() {
        super(API_URL_SOCKET);
    }

    getAllComments = async () => {
        try {
            const response = await this.get(apiURL_get_chat_live);
            console.log({ response })
            return response.data;
        } catch (error) {
            console.log(error)
        }

    };

    getDateComments = async (date) => {
        try {
            const response = await this.get(`${apiURL_get_chat_live_by_date}/${date}`);
            return response;
        } catch (error) {
            console.log(error)
        }
    }


    addModerator = async (arrOfChannelsProps) => {
        try {
            const response = await this.post(apiURL_create_moderators, arrOfChannelsProps);
            return response;
        } catch (error) {
            console.log(error)
        }
    }

    deleteModerator = async (arrOfChannelsProps) => {
        try {
            const response = await this.put(apiURL_delete_moderator, arrOfChannelsProps);
            return response;
        } catch (error) {
            console.log(error)
        }
    }


}

const ChatService = new Service();

export default ChatService;
