import { Card, CardContent, TextField, Box } from "@mui/material";
import { useEffect, useState } from "react";

const CardWorkshopDetails = ({
  instructor: initialInstructor,
  observacion: initialObservacion,
  isEditing,
  mode,
  setInstructor,
  setObservacion,
  height,
  usdPrice,
  argPrice,
  setArgPrice,
  setUsdPrice,
}) => {
  const [instructor, setInstructorIntermediate] = useState(initialInstructor);
  const [observacion, setObservacionIntermediate] =
    useState(initialObservacion);
  const [arg, setArgPriceIntermediate] = useState(argPrice);
  const [usd, setUsdPriceIntermediate] = useState(usdPrice);

  useEffect(() => {
    if (mode === "finish") {
      // Si el modo es 'finish', no actualizamos los datos
      return;
    }
    // Cuando los datos están disponibles, actualizamos el estado
    setInstructorIntermediate(initialInstructor);
    setObservacionIntermediate(initialObservacion);
    setArgPriceIntermediate(argPrice);
    setUsdPriceIntermediate(usdPrice);
  }, [mode, initialInstructor, initialObservacion, argPrice, usdPrice]);

  const handleChangeObservacion = (event) => {
    setObservacionIntermediate(event.target.value);
    // Comunica el cambio al componente padre (Taller)
    setObservacion(event.target.value);
  };

  const handleChangeInstructor = (event) => {
    setInstructorIntermediate(event.target.value);
    // Comunica el cambio al componente padre (Taller)
    setInstructor(event.target.value);
  };

  const formatPrice = (price) => {
    if (!price) return "";
    const number = parseFloat(price.replace(/,/g, ""));
    if (isNaN(number)) return "";
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const handleChangeArgPrice = (event) => {
    const value = event.target.value;
    setArgPriceIntermediate(value);
    setArgPrice(value);
  };

  const handleChangeUsdPrice = (event) => {
    const value = event.target.value;
    setUsdPriceIntermediate(value);
    setUsdPrice(value);
  };

  const handleBlurArgPrice = () => {
    setArgPrice(formatPrice(argPrice));
  };

  const handleBlurUsdPrice = () => {
    setUsdPrice(formatPrice(usdPrice));
  };

  return (
    <Card sx={{ my: 3, width: "100%", height: height }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "space-between",
          justifyContent: "space-around",
          height: "100%",
          rowGap: "20px",
        }}
      >
        <TextField
          fullWidth
          name={"Instructor"}
          label={"Instructor"}
          value={instructor || ""}
          disabled={!isEditing}
          onChange={handleChangeInstructor}
          variant="outlined"
        />
        <TextField
          fullWidth
          multiline
          rows={3}
          name={"observacion"}
          label={"Observación"}
          disabled={!isEditing}
          value={observacion || ""}
          onChange={handleChangeObservacion}
          variant="outlined"
        />
        {/* <Box sx={{ display: "flex", gap: 1 }}>
          <TextField
            name={"Precio ARG"}
            label={"Precio ARG"}
            disabled={!isEditing}
            value={arg || ""}
            onChange={handleChangeArgPrice}
            variant="outlined"
            onBlur={handleBlurArgPrice}
          />
          <TextField
            name={"Precio USD"}
            label={"Precio USD"}
            disabled={!isEditing}
            value={usd || ""}
            onChange={handleChangeUsdPrice}
            variant="outlined"
            onBlur={handleBlurUsdPrice}
          />
        </Box> */}
      </CardContent>
    </Card>
  );
};

export default CardWorkshopDetails;
