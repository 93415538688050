import { BorderAllRounded, Opacity } from "@mui/icons-material";

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '20px',
    width: '100%',
    maxWidth: '1200px',
    margin: '20px',
    padding: '10px',
  },
  cardContainerSmall: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    maxWidth: '600px',
    margin: '20px',
    padding: '10px',
  },
  card: {
    background: "linear-gradient(to right, #D0749B, #F69195)",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: '16px',
    width: '100%',
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
    
  },
  cardButton: {
    textAlign: 'center',
    color: 'white',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    opacity: 0.7,
  },
  icon: {
    fontSize: '3rem',
    marginBottom: '10px',
  },
  comentCard: {
    background: "linear-gradient(to right, #D0749B, #F69195)",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: '16px',
    width: '50%',
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  litleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    backgroundColor: 'red',
    alignContent: "center",
    margin: "0 auto"

  },
  ellipseTop: {
    width: '50%',
    maxWidth: '500px',
    height: 'auto',
    objectFit: 'contain',
    position: "absolute",
    bottom: 0,
    left: 0,
    margin: "0 auto",
    zIndex: -1
  },
  centralVector: {
    width: '70%',
    maxWidth: '700px',
    height: 'auto',
    objectFit: 'contain',
    position: "absolute",
    right:0,
    left: 0,
    margin: "0 auto",
    zIndex: -1
  },
  topVector: {
    width: '70%',
    maxWidth: '700px',
    height: 'auto',
    objectFit: 'contain',
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: -1,
    margin: "0 auto",
  }
};
