import { APIURL } from "../models/api-base";
import {
    apiURL_live_start_notification,
} from "../models/api-urls";
import fetchService from "../modules/APIService";

class Service extends fetchService {
    constructor() {
        super(APIURL);
    }

    /**
     * Envio Notificacion push 
     * @returns
     */
    sendStartLiveNotification = async () => {
        const response = await this.post(apiURL_live_start_notification);
        return response;
    };

}

const LiveService = new Service();

export default LiveService;
