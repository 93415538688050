import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import ProductService from "../../../services/product.services";
import FormPack from "./FormPack";
import StarBackground from "../../../components/StarBackground";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

const PackCards = ({ workshops, user }) => {
  const [open, setOpen] = useState(false);
  const [packIndex, setPackIndex] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("error");

  const handleOpen = (index) => {
    setPackIndex(index);
    setOpen(true);
  };
  const handleClose = () => {
    setPackIndex(null);
    setOpen(false);
  };

  const handleToastClose = () => {
    setToastOpen(false);
  };

  const showToast = (message, severity) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
  };

  const getMembershipType = (workshopLength) => {
    if (workshopLength === 3) return "Trimestral";
    if (workshopLength === 6) return "Semestral";
    if (workshopLength === 1) return "Mensual";
    return "Anual";
  };

  const getMembershipColor = (type) => {
    switch (type) {
      case "Mensual":
        return "#0369A1"; // Example color
      case "Trimestral":
        return "#15803D"; // Example color
      case "Semestral":
        return "#C2410C"; // Example color
      case "Anual":
        return "#BD2828"; // Example color
      default:
        return "#D0749B"; // Default color
    }
  };

  const getMembershipGradient = (type) => {
    switch (type) {
      case "Mensual":
        return "linear-gradient(45deg, #0369A1,rgb(88, 159, 197))"; // Example gradient
      case "Trimestral":
        return "linear-gradient(45deg, #15803D,rgb(61, 219, 122))"; // Example gradient
      case "Semestral":
        return "linear-gradient(45deg, #C2410C,rgb(253, 145, 103))"; // Example gradient
      case "Anual":
        return "linear-gradient(45deg, #BD2828,rgb(252, 106, 106))"; // Example gradient
      default:
        return "linear-gradient(45deg, #D0749B, #D08DA1)"; // Default gradient
    }
  };

  const getStarCount = (type) => {
    switch (type) {
      case "Mensual":
        return 1;
      case "Trimestral":
        return 2;
      case "Semestral":
        return 3;
      case "Anual":
        return 4;
      default:
        return 0;
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
    return d.toLocaleDateString();
  };

  const getPacks = async () => {
    setLoading(true);
    try {
      const resPacks = await ProductService.getPack();
      setProducts(resPacks);
    } catch (error) {
      console.error("Error fetching packs", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPacks();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 6,
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop: 5,
        marginBottom: 6,
      }}
    >
      <Backdrop
        sx={{
          color: "#D0749B",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading &&
        products.map((product, index) => {
          const isEnabled = product.product.type === "Workshop";
          const membershipType = product.membership
            ? getMembershipType(product?.workshops?.length)
            : null;
          const membershipGradient = membershipType
            ? getMembershipGradient(membershipType)
            : "linear-gradient(45deg, #D0749B, #D08DA1)";
          const membershipColor = membershipType
            ? getMembershipColor(membershipType)
            : "#D0749B";
          const starCount = getStarCount(membershipType);

          return (
            <Card
              key={product.id}
              onClick={() => user?.admin_users?.PRODUCTS?.WRITE?.[0] ? handleOpen(index) : null}
              sx={{
                width: 380,
                height: 600,
                padding: 1.5,
                paddingBottom: 0,
                cursor: "pointer",
                marginBottom: 6,
                borderRadius: 8,
                transition: "transform 0.3s, box-shadow 0.3s",
                position: "relative",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  transform: "scale(1.03)",
                  boxShadow: `0 0px 22px ${isEnabled ? membershipColor : "gray"
                    }`,
                },
                overflow: "visible",
                border: `2px solid ${membershipGradient}`,
              }}
            >
              {product.membership && (
                <Box
                  sx={{
                    position: "absolute",
                    top: -16,
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: isEnabled ? membershipGradient : "gray",
                    color: "white",
                    borderRadius: 2,
                    padding: "4px 8px",
                    zIndex: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    color={"white"}
                    textAlign={"center"}
                  >
                    {membershipType}
                  </Typography>
                </Box>
              )}
              <CardContent
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                {isEnabled && (
                  <StarBackground count={starCount} color={membershipColor} />
                )}
                <Typography
                  variant="h4"
                  component="div"
                  color={"#3e3f40"}
                  gutterBottom
                  textAlign={"center"}
                >
                  {product.product.name}
                </Typography>
                <Typography variant="body2" textAlign={"center"}>
                  {product.product.description}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 3,
                  }}
                >
                  <Box display={"flex"} justifyContent={"space-evenly"}>
                    <Typography variant="h5" color={"GrayText"}>
                      ${product.product.ARG.price} ARS
                    </Typography>
                    <Typography variant="h5" color={"GrayText"}>
                      /
                    </Typography>
                    <Typography variant="h5" color={"GrayText"}>
                      ${product.product.DOLARS.price} USD
                    </Typography>
                  </Box>
                  {product.membership && (
                    <Typography
                      variant="h6"
                      textAlign={"center"}
                      marginTop={2}
                      color={"white"}
                      borderRadius={2}
                      padding={"4px 0"}
                      sx={{
                        background: isEnabled ? membershipGradient : "gray",
                      }}
                    >
                      Valido hasta: {formatDate(product.membership.end)}
                    </Typography>
                  )}
                  {!isEnabled && (
                    <>
                      <Typography
                        variant="h5"
                        textAlign={"center"}
                        color={"error"}
                        marginTop={10}
                      >
                        Paquete deshabilitado
                      </Typography>
                      <Box
                        sx={{
                          position: "absolute",
                          top: "55%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          fontSize: "10rem", // Tamaño grande
                          color: "rgba(255, 0, 0, 0.08)", // Rojo con opacidad
                          zIndex: 0, // Colocar detrás del texto
                        }}
                      >
                        <DoDisturbIcon fontSize="inherit" />
                      </Box>
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "auto",
                    flexDirection: "column",
                    gap: 1,
                    background: isEnabled ? membershipGradient : "gray",
                    padding: 2,
                    borderBottomLeftRadius: 22,
                    borderBottomRightRadius: 22,
                    width: 380,
                    height: 130,
                    marginLeft: -3.5,
                    marginBottom: -3.3,
                  }}
                >
                  <Typography
                    variant="body2"
                    textAlign={"center"}
                    color={"white"}
                  >
                    Última actualización:{" "}
                    {new Date(product.product.updatedAt).toLocaleDateString()}
                  </Typography>
                  {user?.admin_users?.PRODUCTS?.WRITE?.[0] && <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "white",
                      color: isEnabled ? membershipColor : "gray",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                      },
                      width: "80%",
                    }}
                    onClick={() => handleOpen(index)}
                  >
                    Ver detalles
                  </Button>}
                </Box>
              </CardContent>
            </Card>
          );
        })}
      <FormPack
        open={open}
        handleClose={handleClose}
        product={packIndex !== null ? products[packIndex] : null}
        workshops={workshops}
        showToast={showToast} // Pass showToast to FormPack
        getPacks={getPacks}
        loading={loading}
        setLoading={setLoading}
      />
      <Snackbar
        open={toastOpen}
        autoHideDuration={2000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleToastClose}
          severity={toastSeverity}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PackCards;
