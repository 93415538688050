import PaymentsService from "./payment.service";
import UserService from "./user.service";
import WorkshopService from "./workshop.service";
import CalendarService from "./calendar.service";
import ChannelService from "./channel.service";
import ChatService from "./chat.service"
export {
  PaymentsService,
  UserService,
  WorkshopService,
  CalendarService,
  ChannelService,
  ChatService
};


