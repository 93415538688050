// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: auto;
    max-width: 100%;
    padding: 1rem;
  }
  
  @media (min-width: 768px) {
    .container {
      flex-direction: row;
      justify-content: space-around;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Channels/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;IACZ,eAAe;IACf,aAAa;EACf;;EAEA;IACE;MACE,mBAAmB;MACnB,6BAA6B;IAC/B;EACF","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n    margin: auto;\n    max-width: 100%;\n    padding: 1rem;\n  }\n  \n  @media (min-width: 768px) {\n    .container {\n      flex-direction: row;\n      justify-content: space-around;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
